import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { SegmentService } from "ngx-segment-analytics";
import { catchError, exhaustMap, map, of, tap } from "rxjs";
import { EmailSendModalService } from "src/app/modals/email-sent-modal/email-send.modal.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { UtilService } from "src/app/services/util.service";
import {
  setErrorMessage,
  setLoadingSpinner,
} from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { getGradesStart, SetOtpEmailID } from "../../otp/state/otp.action";
import { LoginRequest } from "../models/login-request.model";
import {
  loginFailed,
  loginSuccess,
  LOGIN_SUBMIT,
  LOGIN_SUCCESS,
} from "./login.action";

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthorizationService,
    private localStorageService: LocalStorageService,
    private util: UtilService,
    private emailSendModal: EmailSendModalService,
    private segment: SegmentService,
    private localStorage: LocalStorageService
  ) {}
  loginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LOGIN_SUBMIT),
      exhaustMap((id: LoginRequest) => {
        let req: LoginRequest = {
          email_id: id.email_id,
          password: id.password,
          user_type: id.user_type,
          device_token: id.device_token,
          language_id: id.language_id,
          device_info: id.device_info,
        };
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.authService.login(req).pipe(
          map((post: any) => {
            this.localStorage.setValue(
              "lang",
              post?.response?.language_id == 1 ? "EN" : "FR"
            );
            try {
              this.segment.track("Start Session", post?.response);
            } catch (e) {
              console.log("Error in tracking segment");
            }
            setTimeout(() => {
              if (
                !post?.response?.grade_year_id &&
                post.response?.isEmailVerified == 1
              ) {
                let lang = post?.response?.language_id;
                this.store.dispatch(getGradesStart({ language_id: lang }));
              }
            }, 600);
            return loginSuccess(post.response);
          }),
          catchError((errResp: Error) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(
              loginFailed(errResp),
              setErrorMessage({ message: errResp?.message })
            );
          })
        );
      })
    );
  });

  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LOGIN_SUCCESS),
        tap(async (action: any) => {
          if (action.isEmailVerified == 1) {
            let token = await this.util.encrypt(action.token);
            if (action.phone_number)
              this.localStorageService.setValue(
                "studeoWeb",
                action.phone_number
              );
            else this.localStorageService.setValue("studeoWeb", token);
            this.localStorageService.setValue("studeoToken", token);
            this.localStorageService.showMessage(
              "Connexion réussie",
              "Login Successful"
            );
            return this.router.navigate(["/home/dashboard/home"]);
          } else {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(
              SetOtpEmailID({ phone_number: action.phone_number })
            );
            let token = await this.util.encrypt(action.token);
            this.localStorageService.setValue("studeoToken", token);

            // return this.localStorageService.checkLanguage()? this.emailSendModal.open("Nous avons envoyé un code de vérification à votre téléphone", action.phone_number,"Entrer le code"):this.emailSendModal.open("We have sent a verification code on your phone", action.phone_number,"Enter OTP");
            return this.router.navigate(["/auth/verify-phone-number"]);
          }
        })
      );
    },
    { dispatch: false }
  );
}
