import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppState } from "../store/app.state";
import { Store } from "@ngrx/store";
import { setLoadingSpinner } from "../shared/store/shared.actions";
import { map, throwError } from "rxjs";
import { LocalStorageService } from "./local.storage.service";
import { UtilService } from "./util.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SolverApiService {
  endpoint = environment.solver_chat_endpoint;
  tokenToSend: any;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      isMobile: "false",
    }),
  };
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private util: UtilService,
    private localStorageService: LocalStorageService
  ) {}

  private extractData(res: Response): any {
    let body = res;

    return body || {};
  }

  async get(url: any): Promise<any> {
    let res = await this.http
      .get<any>(this.endpoint + url, await this.getHeaders2())
      .toPromise();
    return res;
  }
  async post(url: any, body: any): Promise<any> {
    this.store.dispatch(setLoadingSpinner({ status: true }));
    let res: any = await this.http
      .post<any>(this.endpoint + url, body, await this.getHeaders())
      .pipe(map(this.extractData))
      .toPromise();
    this.store.dispatch(setLoadingSpinner({ status: false }));
    return res;
    if (res.status == 404) {
      this.store.dispatch(setLoadingSpinner({ status: false }));
      return res;
    } else {
      throw new Error(res);
    }
  }
  async patch(url: any, body: any): Promise<any> {
    let res = await this.http
      .patch<any>(this.endpoint + url, body, await this.getHeaders())
      .pipe(map(this.extractData))
      .toPromise();

    if (res.success) {
      return res;
    } else {
      throw new Error(res.message);
    }
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error.error);
  }
  async delete(url: any): Promise<any> {
    let res = await this.http
      .delete<any>(this.endpoint + url, await this.getHeaders())
      .toPromise();
    return res;
  }

  async getHeaders() {
    let a: any = this.localStorageService.getValue("studeoToken");
    let token = await this.util.decrypt(a);
    // let token = this.localStorageService.getValue("studeoToken");

    if (token) {
      this.tokenToSend = token;
      this.httpOptions = {
        headers: new HttpHeaders({
          isMobile: "false",
          "Content-Type": "application/json",
          Authorization: token,
        }),
      };
    }
    return this.httpOptions;
  }

  async getHeaders2() {
    let a: any = this.localStorageService.getValue("studeoToken");
    let token = await this.util.decrypt(a);
    // let token = this.localStorageService.getValue("studeoToken");

    if (token) {
      this.tokenToSend = token;
      this.httpOptions = {
        headers: new HttpHeaders({
          isMobile: "false",
          "Content-Type": "application/event-stream",
          Authorization: token,
        }),
      };
    }
    return this.httpOptions;
  }
}
