import { addFaqCommentResponse } from "../model/response/add-faq-comment.response";
import { AddFaqQuestionResponse } from "../model/response/add-faq-question.response";
import { BookmarkVideoResponse } from "../model/response/bookmark-video.response";
import { FaqCommentListResponse } from "../model/response/faq-comment-list.response";
import { flashcardListResponse } from "../model/response/flashcard-list.response";
import { ForumResponse } from "../model/response/forum.response";
import { getFlashcardResponse } from "../model/response/getFlashcard.response";
import { getResourceResponse } from "../model/response/getResource.response";
import { getSummaryResponse } from "../model/response/getSummary.response";
import { GetTopicTestQuestionResponse } from "../model/response/getTopic-test-questions.response";
import { isBookmarkedResponse } from "../model/response/isBookMarked.response";
import { LikeUnlikeFaqResponse } from "../model/response/like-unlike-faq-response";
import { markFlashcardResponse } from "../model/response/mark-flashcard.response";
import { ListPinnedCommentsResponse } from "../model/response/pinned-list.response";
import { SelectTopicListForRevision } from "../model/response/select-topic-list-for-revision.response";
import { StartRevisionResponse } from "../model/response/start-revision.response";
import { TopicAnswerSubmitResponse } from "../model/response/submit-topic-answer.response";
import { TestResultResponse } from "../model/response/test-result.response";
import { TopicResultSummeryResponse } from "../model/response/topic-result-summery.response";
import { TopicTestResponse } from "../model/response/topic-test.response";
import { updateTopicDurationResponse } from "../model/response/updateUserTopicDuration.response";
import { TopicInformation } from "../model/topic-information.model";


export interface TopicListDetailsState {
    topicListDetails: any
    topicContentListDetails:any
    topicTest: TopicTestResponse | null
    getTopicTestQuestion: GetTopicTestQuestionResponse | null,
    selectTopicForRevision: SelectTopicListForRevision | null
    startRevision: StartRevisionResponse | null
    getFlashcard: getFlashcardResponse | null
    bookmarkVideo: BookmarkVideoResponse | null
    topicAnswerSubmit: TopicAnswerSubmitResponse | null
    getResourceList: getResourceResponse | null
    getSummaryList: getSummaryResponse | null
    markFlashcard: markFlashcardResponse | null
    flashcardList: flashcardListResponse | null
    testResult: TestResultResponse | null
    topicResultSummery: TopicResultSummeryResponse | null
    updateTopicDuration: updateTopicDurationResponse | null
    isBookmarked: isBookmarkedResponse | null
    forumList: ForumResponse | null
    faqCommentList: FaqCommentListResponse | null
    likeunlikefaq: LikeUnlikeFaqResponse | null
    addfaqcomment: addFaqCommentResponse | null
    addfaqquestion: AddFaqQuestionResponse | null
    listpinnedcomments: ListPinnedCommentsResponse | null
    topicPreviousAnswerSubmit: TopicAnswerSubmitResponse | null
    selectedTopicInformation: TopicInformation | null


}

export const initialState: TopicListDetailsState = {
    topicContentListDetails:null,
    topicListDetails: null,
    topicTest: null,
    getTopicTestQuestion: null,
    selectTopicForRevision: null,
    startRevision: null,
    getFlashcard: null,
    bookmarkVideo: null,
    topicAnswerSubmit: null,
    getResourceList: null,
    getSummaryList: null,
    markFlashcard: null,
    flashcardList: null,
    testResult: null,
    topicResultSummery: null,
    updateTopicDuration: null,
    isBookmarked: null,
    forumList: null,
    faqCommentList: null,
    likeunlikefaq: null,
    addfaqcomment: null,
    addfaqquestion: null,
    listpinnedcomments: null,
    topicPreviousAnswerSubmit:null,
    selectedTopicInformation:null
}
