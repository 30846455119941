import { createAction, props } from "@ngrx/store"
import { ApiResponse } from "src/app/models/api.response.model"
import { CommentOnQuestionRequest } from "../models/request/comment-on-question.request"
import { QuestionLikeUnlikeRequest } from "../models/request/question-like-unlike.request"
import { questionListRequest } from "../models/request/question-list.request"
import { QuestionRepliesRequest } from "../models/request/question-replies.request"
import { SubmitQuestionRequest } from "../models/request/submit-question.request"
import { UpdateQuestionViewsRequset } from "../models/request/update-question-views.request"
import { CommentOnQuestionResponse } from "../models/response/comment-on-question.response"
import { QuestionLikeUnlikeResponse } from "../models/response/question-like-unlike.response"
import { questionListResponse } from "../models/response/question-list.response"
import { QuestionRepliesResponse } from "../models/response/question-replies.response"
import { SubmitQuestionResponse } from "../models/response/submit-question.response"
import { UpdateQuestionViewsResponse } from "../models/response/update-question-views.response"


export const QUESTION_LIST_SUBMIT = '[question list page] question list submit'
export const QUESTION_LIST_SUCCESS = '[question list page] question list success'
export const QUESTION_LIST_FLAG_UPDATE  = '[question list page] question list flag update'
export const QUESTION_LIST_FAILED = '[question list page] question list failed'

export const COMMENT_ON_QUESTION_SUBMIT = '[comment on question page] comment on question submit'
export const COMMENT_ON_QUESTION_SUCCESS = '[comment on question page] comment on question success'
export const COMMENT_ON_QUESTION_FAILED = '[comment on question page] comment on question failed'

export const QUESTION_LIKE_UNLIKE_SUBMIT = '[question like unlike  page] question like unlike  submit'
export const QUESTION_LIKE_UNLIKE_SUCCESS = '[question like unlike  page] question like unlike  success'
export const QUESTION_LIKE_UNLIKE_FAILED = '[question like unlike  page] question like unlike  failed'

export const QUESTION_REPLIES_SUBMIT = '[question replies page] question replies submit'
export const QUESTION_REPLIES_UPDATE = '[question replies page] question replies update flag';
export const QUESTION_REPLIES_SUCCESS = '[question replies page] question replies success'
export const QUESTION_REPLIES_FAILED = '[question replies page] question replies failed'

export const SUBMIT_QUESTION_SUBMIT = '[submit question page] submit question submit'
export const SUBMIT_QUESTION_SUCCESS = '[submit question page] submit question success'
export const SUBMIT_QUESTION_FAILED = '[submit question page] submit question failed'
export const SUBMIT_QUESTION_LIST_NULL = '[ submit question list page ]'

export const UPDATE_QUESTION_VIEW_SUBMIT = '[update question  page] update question  submit'
export const UPDATE_QUESTION_VIEW_SUCCESS = '[update question  page] update question  success'
export const UPDATE_QUESTION_VIEW_FAILED = '[update question  page] update question  failed'

export const QuestionListSubmit = createAction(
    QUESTION_LIST_SUBMIT,
    props<questionListRequest>()
);

export const QuestionListUpdateFlag = createAction(
    QUESTION_LIST_FLAG_UPDATE,
    props<questionListResponse>()
);

export const QuestionListSuccess = createAction(
    QUESTION_LIST_SUCCESS,
    props<questionListResponse>()
);

export const QuestionListFailed = createAction(
    QUESTION_LIST_FAILED,
    props<ApiResponse<null>>()
);

export const CommentOnQuestionSubmit = createAction(
    COMMENT_ON_QUESTION_SUBMIT,
    props<CommentOnQuestionRequest>()
);

export const CommentOnQuestionSuccess = createAction(
    COMMENT_ON_QUESTION_SUCCESS,
    props<CommentOnQuestionResponse>()
);

export const CommentOnQuestionFailed = createAction(
    COMMENT_ON_QUESTION_FAILED,
    props<ApiResponse<null>>()
);

export const QuestionLikeUnlikeSubmit = createAction(
    QUESTION_LIKE_UNLIKE_SUBMIT,
    props<QuestionLikeUnlikeRequest>()
);

export const QuestionLikeUnlikeSuccess = createAction(
    QUESTION_LIKE_UNLIKE_SUCCESS,
    props<QuestionLikeUnlikeResponse>()
);

export const QuestionLikeUnlikeFailed = createAction(
    QUESTION_LIKE_UNLIKE_FAILED,
    props<ApiResponse<null>>()
);


export const QuestionRepliesSubmit = createAction(
    QUESTION_REPLIES_SUBMIT,
    props<QuestionRepliesRequest>()
);

export const QuestionRepliesUpdate = createAction(
    QUESTION_REPLIES_UPDATE,
    props<QuestionRepliesResponse>()
);

export const QuestionRepliesSuccess = createAction(
    QUESTION_REPLIES_SUCCESS,
    props<QuestionRepliesResponse>()
);

export const QuestionRepliesFailed = createAction(
    QUESTION_REPLIES_FAILED,
    props<ApiResponse<null>>()
);

export const SubmitQuestionSubmit = createAction(
    SUBMIT_QUESTION_SUBMIT,
    props<SubmitQuestionRequest>()
);
export const SubmitQuestionNull = createAction(
    SUBMIT_QUESTION_LIST_NULL
)
export const SubmitQuestionSuccess = createAction(
    SUBMIT_QUESTION_SUCCESS,
    props<SubmitQuestionResponse>()
);

export const SubmitQuestionFailed = createAction(
    SUBMIT_QUESTION_FAILED,
    props<ApiResponse<null>>()
);

export const UpdateQuestionViewsSubmit = createAction(
    UPDATE_QUESTION_VIEW_SUBMIT,
    props<UpdateQuestionViewsRequset>()
);

export const UpdateQuestionViewsSuccess = createAction(
    UPDATE_QUESTION_VIEW_SUCCESS,
    props<UpdateQuestionViewsResponse>()
);

export const UpdateQuestionViewsFailed = createAction(
    UPDATE_QUESTION_VIEW_FAILED,
    props<ApiResponse<null>>()
);