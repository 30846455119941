import { createAction, props } from "@ngrx/store";
import { EditprofileRequest } from "src/app/components/dashboard/profile/models/request/edit-profile.request";
import { ApiResponse } from "src/app/models/api.response.model";
import { NotificationCountResponse } from "../models/notification-count-modal-response";
import { NotificationListResponse } from "../models/notification-list-modal-response";
import { UserProfileResponse } from "../models/user-profile-modal-response";
import { NotificationListRequest } from "../models/notification-list.request";

export const USER_PROFILE_SUCCESS_MESSAGE = '[profile page] set success message';
export const USER_PROFILE_SUBMIT_MESSAGE = '[profile page] set submit message';
export const USER_PROFILE_FAILED_MESSAGE = '[profile page] set failed message';
export const USER_PROFILE_UPDATED = '[profile page] profile page updated';

export const NOTIFICATION_LIST_SUBMIT = '[notification page] set submit message';
export const NOTIFICATION_LIST_SUCCESS = '[notification page] set success message';
export const NOTIFICATION_LIST_FAILED = '[notification page] set failed message';

export const NOTIFICATION_COUNT_SUBMIT = '[notification count page] set submit message';
export const NOTIFICATION_COUNT_SUCCESS = '[notification count page] set success message';
export const NOTIFICATION_COUNT_FAILED = '[notification count page] set failed message';

export const userProfileSubmit = createAction(
  USER_PROFILE_SUBMIT_MESSAGE,

);

export const userProfileSuccess = createAction(
  USER_PROFILE_SUCCESS_MESSAGE,
  props<UserProfileResponse>()
);

export const userProfileFailed = createAction(
  USER_PROFILE_FAILED_MESSAGE,
  props<ApiResponse<null>>()
);

export const notificationListSubmit = createAction(
  NOTIFICATION_LIST_SUBMIT , 
  props<NotificationListRequest>()
);

export const notificationListSuccess = createAction(
  NOTIFICATION_LIST_SUCCESS,
  props<NotificationListResponse>()
);
export const notificationListFailed = createAction(
  NOTIFICATION_LIST_FAILED,
  props<ApiResponse<null>>()
);

export const notificationCountSubmit = createAction(
  NOTIFICATION_COUNT_SUBMIT
);
export const notificationCountSuccess = createAction(
  NOTIFICATION_COUNT_SUCCESS,
  props<NotificationCountResponse>()
);
export const notificationCountFailed = createAction(
  NOTIFICATION_COUNT_FAILED,
  props<ApiResponse<null>>()
);

export const updateProfile = createAction(
  USER_PROFILE_UPDATED,
  props<EditprofileRequest>()
);

