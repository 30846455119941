import { createAction, props } from "@ngrx/store"
import { ApiResponse } from "src/app/models/api.response.model"
import { ContentQuestionsRequest } from "../models/request/content-questions.request"
import { contentQuestionFilter } from "../models/response/content-question-filter.response"
import { ContentQuestionsResponse } from "../models/response/content-questions.response"
import { TypeOfQuestionResponse } from "../models/response/type-of-question.response"


export const CONTENT_QUESTIONS_SUBMIT = '[content questions page] content questions submit'
export const CONTENT_QUESTIONS_SUCCESS = '[content questions page] content questions success'
export const CONTENT_QUESTIONS_FAILED = '[content questions page] content questions failed'

export const TYPE_OF_QUESTION_SUBMIT = '[type of questions page] type of questions submit'
export const TYPE_OF_QUESTION_SUCCESS = '[type of questions page] type of questions success'
export const TYPE_OF_QUESTION_FAILED = '[type of questions page] type of questions failed'

export const TOPIC_LIST_CONTENT_QUESTION_FILTER_SUBMIT = '[topic list content question filter list page] topic list content question filter list submit'
export const TOPIC_LIST_CONTENT_QUESTION_FILTER_SUCCESS = '[topic list content question filter list page] topic list content question filter list success'
export const TOPIC_LIST_CONTENT_QUESTION_FILTER_FAILED = '[topic list content question filter list page] topic list content question filter list failed'


export const ContentQuestionsSubmit = createAction(
    CONTENT_QUESTIONS_SUBMIT,
    props<ContentQuestionsRequest>()
);

export const ContentQuestionsSuccess = createAction(
    CONTENT_QUESTIONS_SUCCESS,
    props<ContentQuestionsResponse>()
);

export const ContentQuestionsFailed = createAction(
    CONTENT_QUESTIONS_FAILED,
    props<ApiResponse<null>>()
);

export const TypeOfQuestionsSubmit = createAction(
    TYPE_OF_QUESTION_SUBMIT,
);

export const TypeOfQuestionsSuccess = createAction(
    TYPE_OF_QUESTION_SUCCESS,
    props<TypeOfQuestionResponse>()
);

export const TypeOfQuestionsFailed = createAction(
    TYPE_OF_QUESTION_FAILED,
    props<ApiResponse<null>>()
);
export const TopicListContenQuestionFilterSubmit = createAction(
    TOPIC_LIST_CONTENT_QUESTION_FILTER_SUBMIT,
);

export const TopicListContenQuestionFilterSuccess = createAction(
    TOPIC_LIST_CONTENT_QUESTION_FILTER_SUCCESS,
    props<contentQuestionFilter>()
);

export const TopicListContenQuestionFilterFailed = createAction(
    TOPIC_LIST_CONTENT_QUESTION_FILTER_FAILED,
    props<ApiResponse<null>>()
);

