import { createFeatureSelector, createSelector } from "@ngrx/store"
import { SettingState } from "./setting.state"

export const SETTING_STATE_NAME = 'setting' 

const getSharedState = createFeatureSelector<SettingState>(SETTING_STATE_NAME);


export const getAboutus = createSelector(getSharedState, (state) => {
    return state.aboutUs
});
export const getTermsCondition = createSelector(getSharedState, (state) => {
    return state.termsCondition
});
export const getPrivacyPolicy = createSelector(getSharedState, (state) => {
    return state.privacyPolicy
});
export const getContactUs = createSelector(getSharedState, (state) => {
    return state.contactUs
});