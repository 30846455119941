import { Action, createReducer, on } from "@ngrx/store";
import { notificationCountFailed, notificationCountSuccess, notificationListFailed, notificationListSuccess, updateProfile, userProfileFailed, userProfileSuccess } from "./header.action";
import { initialState, UserProfileState } from "./header.state";



const _UserProfileRuducer = createReducer(
    initialState,
    on(userProfileSuccess, (state, action) => {
        return {
            ...state,
            userProfile: action
        };
    }),
    on(userProfileFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(notificationListSuccess, (state, action) => {
        return {
            ...state,
            notificationList: action
        };
    }),
    on(notificationListFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(notificationCountSuccess, (state: any, action) => {
        return {
            ...state,
            notificationCount: action
        };
    }),
    on(notificationCountFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(updateProfile, (state, action) => {
        if (state.userProfile) {
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    ...action

                }
            };
        }
        return state

    }),

);

export function UserProfileRuducer(state: UserProfileState | undefined, action: Action) {
    return _UserProfileRuducer(state, action);
}

