export interface LoginState {
    errorMessage: string;
    isAuthorized: boolean;
    authToken: string
}

export const initialState: LoginState = {
    isAuthorized: false,
    errorMessage: '',
    authToken: ''
};
