import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { UpdateUnlockSectionRequest } from "../components/topic-list/topic-list-home/modals/request/update-unlock-section.request";
import { UnlockSectionResponse } from "../components/topic-list/topic-list-home/modals/response/unlock-section.response";
import { SubjectListResponse } from "../components/topic-list/topic-list-home/modals/subject-list.response";
import { TopicListResponse } from "../components/topic-list/topic-list-home/modals/topic-list-response.model";
import { TopicListRequest } from "../components/topic-list/topic-list-home/modals/topic-list.request";
import { addFaqCommentRequest } from "../components/topic-list/topic-list-view/model/request/add-faq-comment.request";
import { AddFaqQuestion } from "../components/topic-list/topic-list-view/model/request/add-faq-question.request";
import { BookmarkVideoRequset } from "../components/topic-list/topic-list-view/model/request/bookmark-video.request";
import { FaqCommentListRequest } from "../components/topic-list/topic-list-view/model/request/faq-comment-list.request";
import { flashcardListRequest } from "../components/topic-list/topic-list-view/model/request/flashcard-list.request";
import { ForumRequest } from "../components/topic-list/topic-list-view/model/request/forum.request";
import { getFlashcardRequest } from "../components/topic-list/topic-list-view/model/request/getFlashcard.request";
import { getResourceRequest } from "../components/topic-list/topic-list-view/model/request/getResources.request";
import { getSummaryRequest } from "../components/topic-list/topic-list-view/model/request/getSummary.request";
import { GetTopicTestQuestionRequest } from "../components/topic-list/topic-list-view/model/request/getTopic-test-questions.request";
import { isBookmarkRequest } from "../components/topic-list/topic-list-view/model/request/isBookMarked.request";
import { LikeUnlikeFaqRequest } from "../components/topic-list/topic-list-view/model/request/like-unlike-faq.request";
import { markFlashcardRequest } from "../components/topic-list/topic-list-view/model/request/mark-flashcard.request";
import { StartRevisionRequest } from "../components/topic-list/topic-list-view/model/request/start-revision.request";
import { TopicAnswerSubmitRequest } from "../components/topic-list/topic-list-view/model/request/submit-topic-answer.request";
import { TestResultRequest } from "../components/topic-list/topic-list-view/model/request/test-result.request";
import { TopicContentListDetailsResponse } from "../components/topic-list/topic-list-view/model/request/topic-content-list.response";
import { TopicListDetailsRequest } from "../components/topic-list/topic-list-view/model/request/topic-list-details.request";
import { TopicTestRequset } from "../components/topic-list/topic-list-view/model/request/topic-test.request";
import { updateTopicDurationRequest } from "../components/topic-list/topic-list-view/model/request/updateUserTopicDuration.request";
import { addFaqCommentResponse } from "../components/topic-list/topic-list-view/model/response/add-faq-comment.response";
import { AddFaqQuestionResponse } from "../components/topic-list/topic-list-view/model/response/add-faq-question.response";
import { BookmarkVideoResponse } from "../components/topic-list/topic-list-view/model/response/bookmark-video.response";
import { FaqCommentListResponse } from "../components/topic-list/topic-list-view/model/response/faq-comment-list.response";
import { flashcardListResponse } from "../components/topic-list/topic-list-view/model/response/flashcard-list.response";
import { ForumResponse } from "../components/topic-list/topic-list-view/model/response/forum.response";
import { getFlashcardResponse } from "../components/topic-list/topic-list-view/model/response/getFlashcard.response";
import { getResourceResponse } from "../components/topic-list/topic-list-view/model/response/getResource.response";
import { getSummaryResponse } from "../components/topic-list/topic-list-view/model/response/getSummary.response";
import { GetTopicTestQuestionResponse } from "../components/topic-list/topic-list-view/model/response/getTopic-test-questions.response";
import { isBookmarkedResponse } from "../components/topic-list/topic-list-view/model/response/isBookMarked.response";
import { LikeUnlikeFaqResponse } from "../components/topic-list/topic-list-view/model/response/like-unlike-faq-response";
import { markFlashcardResponse } from "../components/topic-list/topic-list-view/model/response/mark-flashcard.response";
import { ListPinnedCommentsResponse } from "../components/topic-list/topic-list-view/model/response/pinned-list.response";
import { SelectTopicListForRevision } from "../components/topic-list/topic-list-view/model/response/select-topic-list-for-revision.response";
import { StartRevisionResponse } from "../components/topic-list/topic-list-view/model/response/start-revision.response";
import { TopicAnswerSubmitResponse } from "../components/topic-list/topic-list-view/model/response/submit-topic-answer.response";
import { TestResultResponse } from "../components/topic-list/topic-list-view/model/response/test-result.response";
import { TopicListDetailsResponse } from "../components/topic-list/topic-list-view/model/response/topic-list-details.response";
import { TopicResultSummeryResponse } from "../components/topic-list/topic-list-view/model/response/topic-result-summery.response";
import { TopicTestResponse } from "../components/topic-list/topic-list-view/model/response/topic-test.response";
import { updateTopicDurationResponse } from "../components/topic-list/topic-list-view/model/response/updateUserTopicDuration.response";
import { SelectSubjectRequest } from "../directives/select-subject/modals/select-subject.request";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable({
    providedIn: 'root',
})
export class TopicListService {
    timeoutInterval: any;
    constructor(private store: Store<AppState>, private apiService: ApiService) { }

    topicList(TopicListRequest: TopicListRequest): Observable<ApiResponse<TopicListResponse>> {
        return from(this.apiService.post(
            'user/subject',
            TopicListRequest
        ))
    }
    topicContentList(TopicListDetailsRequest: TopicListDetailsRequest): Observable<ApiResponse<TopicContentListDetailsResponse>> {
        return from(this.apiService.post(
            'user/topic-contents',
            TopicListDetailsRequest
        ))
    }

    subjectList(): Observable<ApiResponse<SubjectListResponse>> {
        return from(this.apiService.get(
            'user/subject-list',
        ))
    }

    updateSubjectList(updateTopicList: SelectSubjectRequest): Observable<ApiResponse<any>> {
        return from(this.apiService.patch(
            'user/recent-subject-id',
            updateTopicList
        ))
    }

    topicListDetails(TopicListDetailsState: TopicListDetailsRequest): Observable<ApiResponse<TopicListDetailsResponse>> {
        return from(this.apiService.post(
            'user/topic-detail',
            TopicListDetailsState
        ))
    }
    topicTest(TopicListDetailsState: TopicTestRequset): Observable<ApiResponse<TopicTestResponse>> {
        return from(this.apiService.post(
            'user/get-topic-test',
            TopicListDetailsState
        ))
    }
    getTopicTestQuestion(TopicListDetailsState: GetTopicTestQuestionRequest): Observable<ApiResponse<GetTopicTestQuestionResponse>> {
        return from(this.apiService.post(
            'user/get-topic-test-question',
            TopicListDetailsState
        ))
    }
    bookmarkVideo(TopicListDetailsState: BookmarkVideoRequset): Observable<ApiResponse<BookmarkVideoResponse>> {
        return from(this.apiService.post(
            'user/add-remove-bookmark',
            TopicListDetailsState
        ))
    }

    getFlashCard(TopicListDetailsState: getFlashcardRequest): Observable<ApiResponse<getFlashcardResponse>> {
        return from(this.apiService.post(
            'user/list-flashcards-by-topic',
            TopicListDetailsState
        ))
    }
    submitTestAnswer(TopicListDetailsState: TopicAnswerSubmitRequest): Observable<ApiResponse<TopicAnswerSubmitResponse>> {
        return from(this.apiService.post(
            'user/submit-test-answer',
            TopicListDetailsState
        ))
    }
    submitPreviousTest(previousDetailsState: TopicAnswerSubmitRequest): Observable<ApiResponse<TopicAnswerSubmitResponse>> {
        return from(this.apiService.post(
            'user/submit-test-answer',
            previousDetailsState
        ))
    }


    getTopicListForRevision(): Observable<ApiResponse<SelectTopicListForRevision>> {
        return from(this.apiService.post(
            'user/select-topic-list-for-revision',
            {}
        ))
    }

    startRevision(StartRevisionState: StartRevisionRequest): Observable<ApiResponse<StartRevisionResponse>> {
        return from(this.apiService.post(
            'user/content-libraries',
            StartRevisionState
        ))
    }

    markFlashcard(TopicListDetailsState: markFlashcardRequest): Observable<ApiResponse<markFlashcardResponse>> {
        return from(this.apiService.post(
            'user/mark-flashcard-complete',
            TopicListDetailsState
        ))
    }


    flashcardList(TopicListDetailsState: flashcardListRequest): Observable<ApiResponse<flashcardListResponse>> {
        return from(this.apiService.post(
            'user/flashcards',
            TopicListDetailsState
        ))
    }
    getResource(TopicListDetailsState: getResourceRequest): Observable<ApiResponse<getResourceResponse>> {
        return from(this.apiService.post(
            'user/resources',
            TopicListDetailsState
        ))
    }
    getSummary(TopicListDetailsState: getSummaryRequest): Observable<ApiResponse<getSummaryResponse>> {
        return from(this.apiService.post(
            'user/summary',
            TopicListDetailsState
        ))
    }
    testResult(TopicListDetailsState: TestResultRequest): Observable<ApiResponse<TestResultResponse>> {
        return from(this.apiService.post(
            'user/test-result',
            TopicListDetailsState
        ))
    }
    testResultSummery(TopicListDetailsState: TestResultRequest): Observable<ApiResponse<TopicResultSummeryResponse>> {
        return from(this.apiService.post(
            'user/test-result-summery',
            TopicListDetailsState
        ))
    }

    updateTopicDuration(updateDurationState: updateTopicDurationRequest): Observable<ApiResponse<updateTopicDurationResponse>> {
        return from(this.apiService.post(
            'user/add-update-user-topic-duration',
            updateDurationState, false
        ))
    }

    isBookmarked(isBookmarkState: isBookmarkRequest): Observable<ApiResponse<isBookmarkedResponse>> {
        return from(this.apiService.post(
            'user/is-book-marked',
            isBookmarkState
        ))
    }

    forum(forumState: ForumRequest): Observable<ApiResponse<ForumResponse>> {
        return from(this.apiService.post(
            'user/all-video-comments',
            forumState
        ))
    }

    faqCommentList(faqCommentState: FaqCommentListRequest): Observable<ApiResponse<FaqCommentListResponse>> {
        return from(this.apiService.post(
            'user/all-video-comments',
            faqCommentState
        ))
    }

    likeunlikefaq(likeUnlikeState: LikeUnlikeFaqRequest): Observable<ApiResponse<LikeUnlikeFaqResponse>> {
        return from((this.apiService.post(
            'user/like-unlike-video-comment',
            likeUnlikeState
        )))
    }

    addFaqComment(addFaqCommentState: addFaqCommentRequest): Observable<ApiResponse<addFaqCommentResponse>> {
        return from((this.apiService.post(
            'user/comment-on-video',
            addFaqCommentState
        )))
    }

    addFaqQuestion(addFaqQuestionState: AddFaqQuestion): Observable<ApiResponse<AddFaqQuestionResponse>> {
        return from((this.apiService.post(
            'user/comment-on-video',
            addFaqQuestionState
        )))
    }

    listPinnedComments(): Observable<ApiResponse<ListPinnedCommentsResponse>> {
        return from((this.apiService.post(
            'admin/list-pinned-comments', {}
        )))
    }
    unlockedSection(): Observable<ApiResponse<UnlockSectionResponse>> {
        return from((this.apiService.get(
            'user/user-unlocked-sections'
        )))
    }
    updateUnlockedSection(updateUnlockedSection: UpdateUnlockSectionRequest): Observable<ApiResponse<any>> {
        return from((this.apiService.post(
            'user/update-user-unlocked-sections',
            updateUnlockedSection
        )))
    }
    skipOnboarding(): Observable<ApiResponse<any>> {
        return from((this.apiService.post(
            'user/skip-onboarding',
            {}
        )))
    }
}