import { createAction, props } from "@ngrx/store"
import { ApiResponse } from "src/app/models/api.response.model";
import { BookmarkVideoResponse } from "../models/bookmark-video.response";

export const BOOKMARK_VIDEO_LIST_SUBMIT = '[bookmark-video-list-page] bookmark video list submit'
export const BOOKMARK_VIDEO_LIST_SUCCESS = '[bookmark-video-list-page] bookmark video list success'
export const BOOKMARK_VIDEO_LIST_FAILED = '[bookmark-video-list-page] bookmark video list failed'

export const bookmarkVideoListSubmit = createAction(
    BOOKMARK_VIDEO_LIST_SUBMIT,
);

export const bookmarkVideoListSuccess = createAction(
    BOOKMARK_VIDEO_LIST_SUCCESS,
    props<BookmarkVideoResponse>()
);

export const bookmarkVideoListFailed = createAction(
    BOOKMARK_VIDEO_LIST_FAILED,
);