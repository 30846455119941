
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AppleSigninModule } from "ngx-apple-signin";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SegmentModule, SegmentService } from "ngx-segment-analytics";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuardService } from "./components/auth/auth-gurd.service";
import { ChangePasswordEffet } from "./components/authorization/change-password/state/change-pass.effect";
import { ForgetEffect } from "./components/authorization/forgot-password/state/forget.effect";
import { LoginEffects } from "./components/authorization/login/state/login.effect";
import { OtpEffects } from "./components/authorization/otp/state/otp.effect";
import { ResetPasswordEffet } from "./components/authorization/reset-password/state/rest-password.effect";
import { SignupEffects } from "./components/authorization/sign-up/state/signup.effect";
import { BookmarkEffect } from "./components/bookmark/state/bookmark.effect";
import { unreadService } from "./components/community/community-details/unreadCountEvent.service";
import { QuestionListEffect } from "./components/community/state/community.effect";
import { HomeDashboardEffect } from "./components/dashboard/home-dashboard/state/home-dash.effect";
import { ProfileEffect } from "./components/dashboard/profile/state/profile.effect";
import { ContentQuestionsEffect } from "./components/practice/state/content-questions.effect";
import { SettingEffect } from "./components/setting/state/setting.effect";
import { SubscritptionEffect } from "./components/subscribe/state/subscribe.effect";
import { TopicListEffect } from "./components/topic-list/topic-list-home/state/topic-list.effect";
import { TopicListDetailsEffect } from "./components/topic-list/topic-list-view/state/topic-list-details-effect";
import { ApiService } from "./services/api";
import { AuthorizationService } from "./services/authorization.service";
import { LocalStorageService } from "./services/local.storage.service";
import { UserProfileEffect } from "./shared/header/state/header.effect";
import { LoaderComponent } from "./shared/loader/loader.component";
import { SharedEffects } from "./shared/store/shared.effects";
import { appReducer } from "./store/app.state";
import { CustomSerializer } from "./store/router/custom-serializer";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule } from "@angular/fire/analytics";
import { TokenInterceptorService } from "./services/token.interceptor";
import { ProgressBarModule } from "angular-progress-bar";
import { NgChartsModule } from "ng2-charts";

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Export this function
export function playerFactory(): any {
  return import("lottie-web");
}

@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    NgChartsModule,
    BrowserModule,
    ProgressBarModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-right",
    }),
    LottieModule.forRoot({ player: playerFactory }),

    AppRoutingModule,
    CarouselModule,
    AppleSigninModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SegmentModule.forRoot({
      apiKey: environment.segment,
      debug: true,
      loadOnInitialization: true,
    }),
    EffectsModule.forRoot([
      LoginEffects,
      ForgetEffect,
      OtpEffects,
      ChangePasswordEffet,
      HomeDashboardEffect,
      SignupEffects,
      ResetPasswordEffet,
      UserProfileEffect,
      TopicListEffect,
      TopicListDetailsEffect,
      QuestionListEffect,
      BookmarkEffect,
      ContentQuestionsEffect,
      SettingEffect,
      SubscritptionEffect,
      ProfileEffect,
      SharedEffects,
    ]),
    StoreModule.forRoot(appReducer),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
  ],
  providers: [
    ApiService,
    AuthorizationService,
    LocalStorageService,
    AuthGuardService,
    SegmentService,
    unreadService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
