import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "src/app/models/api.response.model";
import { ForgetRequest } from "../models/forget-request.model";
import { ForgetResponse } from "../models/forget-response.model";

export const dummyAction = createAction('[dummy action]');
export const FORGET_SUBMIT = '[forget page] forget submit';
export const FORGET_SUCCESS = '[forget page] forget success';
export const FORGET_FAILED = '[forget page] forget failed';

export const forgetPassSubmit = createAction(
    FORGET_SUBMIT,
    props<ForgetRequest>()
);
export const forgetPassSuccess = createAction(
    FORGET_SUCCESS,
    props<ForgetResponse>()
);
export const forgetPassFailed = createAction(
    FORGET_FAILED,
    props<ApiResponse<null>>()
);



