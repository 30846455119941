import { createAction, props } from "@ngrx/store";
import { OtpParentComponent } from "src/app/enums/otp-parent-component.enum";
import { ApiResponse } from "src/app/models/api.response.model";
import { Country } from "../../phone-number-verification/models/country-list.response";
import { SignupRequest } from "../model/signup.request";
import { SignupResponse } from "../model/signup.response";

export const SIGNUP_SUBMIT = '[signup page] signup submit';
export const SIGNUP_SUCCESS = '[signup page] signup success';
export const SIGNUP_FAILED = '[signup page] signup failed';
export const GO_TO_OTP_SCREEN = '[signup page] [routing] otp screen';

export const COUNTRY_CODES_LIST_SUBMIT = '[country codes list page] country codes list submit'
export const COUNTRY_CODES_LIST_SUCCESS = '[country codes list page] country codes list success'
export const COUNTRY_CODES_LIST_FAILED = '[country codes list page] country codes list failed'

export const SET_PHONE_NUMBER = '[Phone number] Phone number submit';

export const SignupSubmit = createAction(
    SIGNUP_SUBMIT,
    props<SignupRequest>()
)

export const GoToOtpScreen = createAction(
    GO_TO_OTP_SCREEN,
    props<any>()
)


export const SignupSuccess = createAction(
    SIGNUP_SUCCESS,
    props<SignupResponse>()
)


export const SignupFailed = createAction(
    SIGNUP_FAILED,
    props<Error>()
)
export const CountryListSubmit = createAction(
    COUNTRY_CODES_LIST_SUBMIT,
);

export const CountryListSuccess = createAction(
    COUNTRY_CODES_LIST_SUCCESS,
    props<Country>()
);

export const CountryListFailed = createAction(
    COUNTRY_CODES_LIST_FAILED,
    props<ApiResponse<null>>()
);
export const SetPhoneNumber = createAction(
    SET_PHONE_NUMBER,
    props<{ phone_number: any ,dial_code:any}>()
);
export const SetPhoneNumberParentComponent = createAction(
    SET_PHONE_NUMBER,
    props<{ component: OtpParentComponent }>()
);