import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { exhaustMap, firstValueFrom, from, map, Observable } from "rxjs";
import { ApiGatewayResponse } from "src/app/models/api.gateway.response.model";
import { AppState } from "src/app/store/app.state";
import { FetchVideoHeaderRequest } from "../model/fetch-video-header.model";
import { fetchVideoHeaderSuccess, FETCH_VIDEO_HEADER_START } from "./shared.actions";

@Injectable()
export class SharedEffects {
  constructor(private action$: Actions, private store: Store<AppState>, private http: HttpClient) { }
  topicListDetailsEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(FETCH_VIDEO_HEADER_START),
      exhaustMap((id: FetchVideoHeaderRequest) => {
        let req: FetchVideoHeaderRequest = {
          playback_url: id.playback_url
        }
        return this.getVideoHeaders(req.playback_url).pipe(
          map((post) => {
            return fetchVideoHeaderSuccess(post)
          })
        )
      })
    )
  })

  getVideoHeaders(playback_url: string): Observable<ApiGatewayResponse> {
    let res = firstValueFrom(this.http.post(
      'https://77f7ou0zt9.execute-api.eu-west-2.amazonaws.com/secure-video/getstreamkey',
      { playback_url }
    ))
    return from(res) as any
  }
}