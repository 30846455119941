import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of, switchMap, tap } from "rxjs";
import { OtpParentComponent } from "src/app/enums/otp-parent-component.enum";
import { SignupType } from "src/app/enums/signup-type.enum";
import { EmailSendModalService } from "src/app/modals/email-sent-modal/email-send.modal.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { UtilService } from "src/app/services/util.service";
import { setErrorMessage, setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { getGradesStart, SetOtpParentComponent } from "../../otp/state/otp.action";
import { SignupRequest } from "../model/signup.request";
import { CountryListFailed, CountryListSuccess, COUNTRY_CODES_LIST_SUBMIT, GoToOtpScreen, SignupFailed, SignupSuccess, SIGNUP_SUBMIT } from "./signup.action";

@Injectable()
export class SignupEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private router: Router,
        private authService: AuthorizationService,
        private localStorageService: LocalStorageService,
        private emailSendModal: EmailSendModalService,private util:UtilService) { }

    signupSubmit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SIGNUP_SUBMIT),
            exhaustMap((id: SignupRequest) => {
                let req: SignupRequest = {
                    email_id: id.email_id,
                    password: id.password,
                    user_type: id.user_type,
                    device_token: id.device_token,
                    language_id: id.language_id,
                    device_info: id.device_info,
                    singup_type: id.singup_type,
                    first_name: id.first_name,
                    last_name: id.last_name,
                    onboarding_user_type:id.onboarding_user_type,
                    phone_number:id.phone_number,
                    signup_referal_code: id.signup_referal_code,
                    social_login_id: id.social_login_id,
                    family_code:id.family_code
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.authService.signup(req).pipe(
                    switchMap(async(post:any) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        // if(post?.response?.is_new_social_user && !post?.response?.onboarding_user_type){
                        //     this.router.navigate(['/auth/pre-onboarding'])
                        // }
                        if(post?.response?.is_new_social_user || !post?.response?.onboarding_user_type){
                            this.router.navigate(['/auth/pre-onboarding'])
                        }
                        else{
                            if (id.singup_type && post.response.isEmailVerified==2) {
                                let token = await this.util.encrypt(post.response.token);
                                this.localStorageService.setValue("studeoToken", token)
                                // this.localStorageService.setValue("studeoToken", post.response.token)
                                this.router.navigate(['/auth/verify-phone-number'])
                                // this.store.dispatch(SetOtpEmailID({ phone_number: id.phone_number }))
                                // this.localStorageService.checkLanguage()? this.emailSendModal.open("Nous avons envoyé un code de vérification à votre adresse e-mail", post.response.phone_number,"Entrer le code"):this.emailSendModal.open("We have sent a verification code on your email address", post.response.phone_number,"Enter OTP");
                            } else {
                                this.localStorageService.setValue("studeoWeb", 'abc')
                                let token = await this.util.encrypt(post.response.token);
                                this.localStorageService.setValue("studeoToken", token)
                                // this.localStorageService.setValue("studeoToken", post.response.token)
                                if(!post.response.grade_year_id){
                                    let lang= this.localStorageService.checkLanguage() ? 2 : 1
                                    this.store.dispatch(getGradesStart({language_id:lang}))
                                }
                                this.router.navigate(['/home/dashboard'])
                            }
                        }
                        return SignupSuccess(post.response)
                    }),
                    catchError((errResp: Error) => {
                        
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(SignupFailed(errResp), setErrorMessage({ message: errResp.message }));
                    })
                );
            })
        );
    });

    @Effect({ dispatch: false })
    GoToOtpScreen$ = this.actions$.pipe(
        ofType(GoToOtpScreen),
        tap(() => {
            this.store.dispatch(SetOtpParentComponent({ component: OtpParentComponent.SignupComponent }))
            this.router.navigate(['/auth/otp-phone'])
        }))
     getCountryList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(COUNTRY_CODES_LIST_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.authService.getCountries().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return CountryListSuccess(post.response)
                    }),
                    catchError((errResp)=>{
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of (CountryListFailed(errResp.error));
                    })
                );
            })
        )
    } )
}