//const baseWbView = "https://solver.studeo.group/";
const baseWbView = "https://solver.staging.studeo.group/";
//const baseWbView = "http://localhost:3010/";
export const environment = {
  production: false,
  segment: "",
  // endpoint: "http://localhost:2987/api/",
  endpoint: "https://dev-api.studeo.group/api/",
  //endpoint: "https://dev-api.studeo.group/api/",
  // endpoint: "https://nimbus.siya.tech:4562/api/",
  // endpoint: "https://nimbus.siya.tech:2987/api/",
  // solver_endpoint: "http://localhost:3002/ia-module/v1/",
  solver_endpoint: baseWbView,
  solver_chat_endpoint: "https://solver.api.staging.studeo.group/ia-module/v1/",
  gamificationURL: `/game-standalone?token=`,
  studAIUrl: `${baseWbView}/chat-standalone-desktop?token=`,
  homeUrl: `${baseWbView}/home-desktop?token=`,
  flashCardURL: `${baseWbView}/flashcard-standalone-web-v2/`,
  progressURL: `${baseWbView}/progress?token=`,
  penAndPaperByGradeYearURL: `${baseWbView}/pen-and-paper-standalone/grade-year/`,
  scoreCardURL: `${baseWbView}/leaderboard-standalone/`,
  penAndPaperURL: `${baseWbView}/pen-and-paper-standalone-web/`,
  topicDetailUrl: `/topic-details-web/`,
  mcqUrl: `${baseWbView}/mcq-standalone-web/`,
  // endpoint: "https://nimbus.siya.tech:7892/api/",
  // endpoint: "https://studeo.group:8443/api/",
  google_client_id:
    "265834262892-u1lu9do3erh641uuselv6f8cfcmsrd03.apps.googleusercontent.com",
  // endpoint: "https://nimbus.siya.tech:4317/api/",
  stripe:
    "pk_test_51JFxEADgCLuFah49WgZuuFm9dq9hKx5BbTumv35By2jbEF5NMqGJpmBGvoB54iqfWZrewoT6wnwnCHJXhhV3KdBs00HXXjEfMG",
  stripeServerSide:
    "sk_test_51JFxEADgCLuFah49EYDVLMhsYXbUSWdeMywqmhIHTsMGgH0SBDpgolgNMjOMKrfZ92TYoYXskdjITK2VNitAo8oU00ig6jB7iW",
  bucket: "studeodev",
  // endpoint: "http://111.118.246.244:4317/api/",
  version: "1.2.8",
  firebaseConfig: {
    apiKey: "AIzaSyD2rp2lytgc7iFWcmFD7oCwX7WEBShrJaM",
    authDomain: "studeodev-e3c3e.firebaseapp.com",
    projectId: "studeodev-e3c3e",
    storageBucket: "studeodev-e3c3e.appspot.com",
    messagingSenderId: "265834262892",
    appId: "1:265834262892:web:5155e6f5f074b38a464349",
    measurementId: "G-PKFEN6HZWF",
  },
  cryptoPass: "$OV!6s}m[@Wj%R^q{c&rd-s01lw*z]r#",
};
