import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { SubscriptionService } from "src/app/services/subscription.service";
import { setErrorMessage, setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { cancelSubscriptionListRequest } from "../models/request/cancel-subscription-list.request";
import { MasterSubscriptionListRequest } from "../models/request/master-subscription-list.request";
import { purchaseSubscriptionRequest } from "../models/request/purchase-subscription.request";
import { CANCEL_SUBSCRIPTION_SUBMIT, MASTER_SUBSCRIPTION_LIST_SUBMIT, MY_SUBSCRIPTION_LIST_SUBMIT, PURCHASE_SUBSCRIPTION_LIST_SUBMIT, cancelSubscriptionFailed, cancelSubscriptionSuccess, masterSubscriptionListFailed, masterSubscriptionListSuccess, mySubscriptionListFailed, mySubscriptionListSuccess, purchaseSubscriptionListFailed, purchaseSubscriptionListSuccess } from "./subscribe.action";

@Injectable()

export class SubscritptionEffect {

    constructor(
        private action$: Actions,
        private store: Store<AppState>,
        private subscriptionService: SubscriptionService
    ) { }

    masterSubscriptionList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(MASTER_SUBSCRIPTION_LIST_SUBMIT),
            exhaustMap((grade_id: MasterSubscriptionListRequest) => {
                let req: MasterSubscriptionListRequest = {
                    limit: grade_id.limit,
                    offset: grade_id.offset,
                    grade_id: grade_id.grade_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.subscriptionService.masterSubscriptionList(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return masterSubscriptionListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(masterSubscriptionListFailed(errResp.error));
                    })
                );
            })
        );
    })

    purchaseSubscription$ = createEffect(() => {
        return this.action$.pipe(
            ofType(PURCHASE_SUBSCRIPTION_LIST_SUBMIT),
            exhaustMap((id: purchaseSubscriptionRequest) => {
                let req: purchaseSubscriptionRequest = {
                    master_subscription_id: id.master_subscription_id,
                    price: id.price,
                    language_id: id.language_id,
                    plan_id: id.plan_id,
                    wallet: id.wallet,
                    device_uid: id.device_uid,
                    grade_id: id.grade_id,
                    allow_promotion_codes: id.allow_promotion_codes,
                    cancel_url: window.location.origin + "/home/subscribe/subscribe?show_popup=true",
                    success_url: window.location.origin + "/home/dashboard/home"
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.subscriptionService.purchaseSubscription(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return purchaseSubscriptionListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(purchaseSubscriptionListFailed(errResp.error), setErrorMessage({ message: errResp.message }));
                    })
                );
            })
        );
    })

    cancelSubscription$ = createEffect(() => {
        return this.action$.pipe(
            ofType(CANCEL_SUBSCRIPTION_SUBMIT),
            exhaustMap((id: cancelSubscriptionListRequest) => {
                let req: cancelSubscriptionListRequest = {
                    subscription_id: id.subscription_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.subscriptionService.cancelSubscription(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return cancelSubscriptionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(cancelSubscriptionFailed(errResp.error));
                    })
                );
            })
        );
    })

    mySubscription$ = createEffect(() => {
        return this.action$.pipe(
            ofType(MY_SUBSCRIPTION_LIST_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.subscriptionService.mySubscriptionList().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return mySubscriptionListSuccess(post)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(mySubscriptionListFailed(errResp.error));
                    })
                );
            })
        );
    })
}
