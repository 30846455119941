import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TopicListState } from "./topic-list.state";

export const TOPIC_LIST_NAME = "topic-list"

const getSharedState = createFeatureSelector<TopicListState>(TOPIC_LIST_NAME);
export const getTopicList = createSelector(getSharedState, (state) => {
    return state.topiclist
})
const getSubjectListState = createFeatureSelector<TopicListState>(TOPIC_LIST_NAME);
export const getSubjectList = createSelector(getSubjectListState, (state) => {
    return state.subjectList
})
const updateSubjectListState = createFeatureSelector<TopicListState>(TOPIC_LIST_NAME);
export const updateSubject = createSelector(updateSubjectListState, (state) => {
    return state.updateSubjectList
})

const getUnlockState = createFeatureSelector<TopicListState>(TOPIC_LIST_NAME);
export const getUnlockSection = createSelector(getUnlockState, (state) => {
    return state.unlockedSection
})

const getUpdateUnlockSectionState = createFeatureSelector<TopicListState>(TOPIC_LIST_NAME);
export const getUpdateUnlockSection = createSelector(getUpdateUnlockSectionState, (state) => {
    return state.updateUnlockSection
})
