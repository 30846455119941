import { createAction, props } from "@ngrx/store"
import { SelectSubjectRequest } from "src/app/directives/select-subject/modals/select-subject.request"
import { ApiResponse } from "src/app/models/api.response.model"
import { UpdateUnlockSectionRequest } from "../modals/request/update-unlock-section.request"
import { UnlockSectionResponse } from "../modals/response/unlock-section.response"
import { SubjectListResponse } from "../modals/subject-list.response"
import { TopicListResponse } from "../modals/topic-list-response.model"
import { TopicListRequest } from "../modals/topic-list.request"

export const TOPIC_LIST_SUBMIT = '[topic list page] topic list submit'
export const TOPIC_LIST_SUCCESS = '[topic list page] topic list success'
export const TOPIC_LIST_FAILED = '[topic list page] topic list failed'

export const SUBJECT_LIST_SUBMIT = '[subject list page] subject list submit'
export const SUBJECT_LIST_SUCCESS = '[subject list page] subject list success'
export const SUBJECT_LIST_FAILED = '[subject list page] subject list failed'


export const UPDATE_SUBJECT_LIST_SUBMIT = '[update subject list page] update subject list submit'
export const UPDATE_SUBJECT_LIST_SUCCESS = '[update subject list page] update subject list success'
export const UPDATE_SUBJECT_LIST_FAILED = '[update subject list page] update subject list failed'

export const UNLOCK_SECTIONS_SUBMIT = '[ unlock section page]  unlock section submit'
export const UNLOCK_SECTIONS_SUCCESS = '[ unlock section page]  unlock section success'
export const UNLOCK_SECTIONS_FAILED = '[ unlock section page]  unlock section failed'

export const UPDATE_UNLOCK_SECTIONS_SUBMIT = '[update unlock section page] update unlock section submit'
export const UPDATE_UNLOCK_SECTIONS_SUCCESS = '[update unlock section page] update unlock section success'
export const UPDATE_UNLOCK_SECTIONS_FAILED = '[update unlock section page] update unlock section failed'

export const TopicListSubmit = createAction(
    TOPIC_LIST_SUBMIT,
    props<TopicListRequest>()
)

export const TopicListSuccess = createAction(
    TOPIC_LIST_SUCCESS,
    props<TopicListResponse>()
)
export const SubjectListSubmit = createAction(
   SUBJECT_LIST_SUBMIT,
)
export const SubjectListSuccess = createAction(
    SUBJECT_LIST_SUCCESS,
    props<SubjectListResponse>()
)
export const UpdateSubjectListSubmit = createAction(
    UPDATE_SUBJECT_LIST_SUBMIT,
    props<SelectSubjectRequest>()
 )
 export const UpdateSubjectListSuccess = createAction(
    UPDATE_SUBJECT_LIST_SUCCESS,
    props<ApiResponse<null>>()
 )

export const TopicListFailed = createAction(
    UNLOCK_SECTIONS_FAILED,
    props<ApiResponse<null>>()
)

export const UnlockSectionSubmit = createAction(
    UNLOCK_SECTIONS_SUBMIT,
)

export const UnlockSectionSuccess = createAction(
    TOPIC_LIST_SUCCESS,
    props<UnlockSectionResponse>()
)

export const UnlockSectionFailed = createAction(
    UNLOCK_SECTIONS_FAILED,
    props<ApiResponse<null>>()
)

export const UpadateUnlockSectionSubmit = createAction(
    UPDATE_UNLOCK_SECTIONS_SUBMIT,
    props<UpdateUnlockSectionRequest>()
)

export const UpadateUnlockSectionSuccess = createAction(
    UPDATE_UNLOCK_SECTIONS_SUCCESS,
    props<ApiResponse<null>>()
)

export const UpadateUnlockSectionFailed = createAction(
    UPDATE_UNLOCK_SECTIONS_FAILED,
    props<ApiResponse<null>>()
)
