import { createAction, props } from "@ngrx/store";
import { OtpParentComponent } from "src/app/enums/otp-parent-component.enum";
import { ApiResponse } from "src/app/models/api.response.model";
import { CourseSelectRequest } from "../model/course-select.request";
import { CourseSelectResponse } from "../model/course-select.response";
import { GradeResponse } from "../model/grade-response.model";
import { LanguageRequest } from "../model/language-request";
import { OtpRequest } from "../model/otp.request";
import { OtpResponse } from "../model/otp.response";
import { ResendOtpEmailRequest, ResendOtpRequest } from "../model/resend-request";
import { VerifyOtpForgetPassword } from "../model/verify-otp-forget-password.model";

export const dummyAction = createAction('[dummy action]');
export const OTP_SUBMIT = '[otp page] otp submit';
export const OTP_SUCCESS = '[otp page] otp success';
export const OTP_FAILED = '[otp page] otp failed';

export const RESEND_OTP_SUBMIT = '[resend password] resend otp submit';
export const RESEND_OTP_PHONE_SUBMIT = '[resend password for Phone] resend otp submit for phone';
export const RESEND_OTP_SUCCESS = '[resend success] resend otp success';
export const RESEND_OTP_FAILED = '[resend failed] resend otp failed';

export const COURSE_SELECT_SUBMIT = '[opt page] course select submit';
export const COURSE_SELECT_SUCCESS = '[otp page] course select success';
export const COURSE_SELECT_FAILED = '[otp page] course select failed';

export const SET_OTP_PARENT_COMPONENT = '[otp page ] set otp parent component';

export const SET_EMAIL_ID = '[otp page] set email-id';

export const OTP_SUBMIT_FORGET_PASSWORD = '[otp page submit] otp submit forget password';
export const OTP_SUCCESS_FORGET_PASSWORD = '[otp page success] otp success forget password';

export const GET_GRADES_START = '[otp page] call get grades api';
export const GET_GRADES_SUCCESS = '[otp page] get grades success';

export const OtpSubmit = createAction(
    OTP_SUBMIT,
    props<OtpRequest>()
)
export const OtpSuccess = createAction(

    OTP_SUCCESS,
    props<OtpResponse>()
)
export const OtpFailed = createAction(
    OTP_FAILED,
    props<ApiResponse<null>>()
)
export const resendOtpSubmit = createAction(
    RESEND_OTP_SUBMIT,
    props<ResendOtpEmailRequest>()
);
export const resendNumberOtpSubmit = createAction(
    RESEND_OTP_PHONE_SUBMIT,
    props<ResendOtpRequest>()
);
export const resendOtpSuccess = createAction(
    RESEND_OTP_SUCCESS,
    props<ApiResponse<any>>()
);
export const resendOtpFailed = createAction(
    RESEND_OTP_FAILED,
    props<ApiResponse<null>>()
);
export const CourseSelectSuccess = createAction(
    COURSE_SELECT_SUCCESS,
    props<CourseSelectResponse>()

);
export const CourseSelectSubmit = createAction(
    COURSE_SELECT_SUBMIT,
    props<CourseSelectRequest>()
);
export const CourseSelectFailed = createAction(
    COURSE_SELECT_FAILED,
    props<ApiResponse<null>>()
);
export const SetOtpParentComponent = createAction(
    SET_OTP_PARENT_COMPONENT,
    props<{ component: OtpParentComponent }>()
);
export const SetOtpEmailID = createAction(
    SET_EMAIL_ID,
    props<{ phone_number: any }>()
);

export const optSubmitForgetPassword = createAction(
    OTP_SUBMIT_FORGET_PASSWORD,
    props<VerifyOtpForgetPassword>()
);
export const otpSuccessForgetPassword = createAction(
    OTP_SUCCESS_FORGET_PASSWORD,
    props<ApiResponse<any>>()
);

export const getGradesStart = createAction(
    GET_GRADES_START,
    props<LanguageRequest>()
);

export const getGradesSuccess = createAction(
    GET_GRADES_SUCCESS,
    props<ApiResponse<GradeResponse>>()
);




