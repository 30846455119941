import { NotificationCountResponse } from "../models/notification-count-modal-response";
import { NotificationListResponse } from "../models/notification-list-modal-response";
import { UserProfileResponse } from "../models/user-profile-modal-response";

export interface UserProfileState {
    userProfile: UserProfileResponse | null,
    notificationList: NotificationListResponse | null,
    notificationCount: NotificationCountResponse | null,

}
export const initialState: UserProfileState = {
    userProfile: null,
    notificationList: null,
    notificationCount: null
}
