<!-- Modal -->
<div class="welcome-modal">
    <div class="modal-header">
        <i class="fa fa-times btn-close" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true" (click)="submit()"></i>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 col-sm-7 col-md-6">
                <h2 class="heading font-medium">{{'welcome_to_studeo' | i18n: i18nService.currentLanguage}}</h2>

                <div class="welcome-details mb-bottom">
                    <h4 class="sub-heading font-regular">{{'boost_your_grades' | i18n: i18nService.currentLanguage}}</h4>
                    <p class="p-large font-regular"><img src="https://studeo-website-assets-dev.s3.eu-west-2.amazonaws.com/tick.png" class="img-fluid" alt="" />{{'our_top_tutors_in_video' | i18n: i18nService.currentLanguage}}
                    </p>
                    <p class="p-large font-regular"><img src="https://studeo-website-assets-dev.s3.eu-west-2.amazonaws.com/tick.png" class="img-fluid" alt="" />{{'our_flashcards' | i18n: i18nService.currentLanguage}}</p>
                    <p class="p-large font-regular"><img src="https://studeo-website-assets-dev.s3.eu-west-2.amazonaws.com/tick.png" class="img-fluid" alt="" />{{'our_mcqs' | i18n: i18nService.currentLanguage}}
                    </p>
                </div>

                <div class="welcome-details mb-bottom">
                    <h4 class="sub-heading font-regular">{{'exchange_and_interact' | i18n: i18nService.currentLanguage}}</h4>
                    <p class="p-large font-regular"><img src="https://studeo-website-assets-dev.s3.eu-west-2.amazonaws.com/tick.png" class="img-fluid" alt="" />{{'ask_question_after_each_video' | i18n: i18nService.currentLanguage}}
                    </p>
                    <p class="p-large font-regular"><img src="https://studeo-website-assets-dev.s3.eu-west-2.amazonaws.com/tick.png" class="img-fluid" alt="" /> {{'share_with_our_community' | i18n: i18nService.currentLanguage}}</p>
                </div>
            </div>
            <div class="col-12 col-sm-5 col-md-6 my-auto">
                <img src="https://studeo-website-assets-dev.s3.eu-west-2.amazonaws.com/welcome.png" class="img-fluid" alt="" />

            </div>
            <div class="text-center">
                <button class="primary-btn" (click)="submit()">{{'start_your_journey' | i18n: i18nService.currentLanguage}}</button>
            </div>
        </div>
    </div>