<div class="course-modal">
    <div class="modal-header">
        <i class="fa fa-times btn-close" *ngIf="current_grade_year" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true" (click)="close()"></i>
    </div>
    <div class="">
        <div class="modal-content">
            <div class="modal-body">
                <h2 class="heading font-medium mb-3">{{localStorage.checkLanguage() ? 'Choisis ton année' : 'Select Course'}}</h2>
                <div class="container-fluid" >
                    <div class="row">
                        <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 mx-auto">
                            <div class="grade" *ngFor="let grade of grades">
                                <ng-container *ngIf="isShowOtherGrade">
                                    <h4 class="name" *ngIf="grade.grade_id==firstTimeGradeSelection">{{grade.grade}}</h4>
                                    <div class="grade-year" *ngIf="grade.grade_id==firstTimeGradeSelection">
                                        <h5 class="year" [ngClass]="{'active':current_grade_year==year.grade_year}" *ngFor="let year of grade.grade_years" (click)="onGradeSelect(year,grade)">
                                            {{year.grade_year}}
                                        </h5>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!isShowOtherGrade">
                                    <h4 class="name">{{grade.grade}}</h4>
                                    <div class="grade-year">
                                        <h5 class="year" [ngClass]="{'active':current_grade_year==year.grade_year}" *ngFor="let year of grade.grade_years" (click)="onGradeSelect(year,grade)">
                                            {{year.grade_year}}
                                        </h5>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="heading font-medium mb-3" id="exampleModalLongTitle">Cancel Course</h2>
                <!-- <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
            </div>
            <div class="modal-body">
                To change country, selection of grade is mandatory. Do you still want to change the country?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">No</button>
                <button type="button" class="btn btn-primary" (click)="continue()" data-dismiss="modal">Yes</button>
            </div>
        </div>
    </div>
</div>