import { Action, createReducer, on } from "@ngrx/store";
import { CountryListFailed, CountryListSuccess, SetPhoneNumber, SignupFailed, SignupSuccess } from "./signup.action";
import { initialState, SignupState } from "./signup.state";

const _signupReducer = createReducer(
    initialState,
    on(SignupSuccess, (state, action) => {
        return {
            ...state,
            authToken: action.token,
            isAuthorized: true
        };
    }),
    on(SignupFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(CountryListSuccess, (state, action) => {
        return {
            ...state,
            country: action
        };
    }),
    on(CountryListFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
    on(SetPhoneNumber, (state, action) => {
        return {
            ...state,
            phone: action
        }
    })
)
  


export function SignupReducer(state: SignupState | undefined, action: Action) {
    return _signupReducer(state, action);
}
