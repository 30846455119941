import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { I18nService } from 'src/app/language/service/i18n.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Output() deleteEvent= new EventEmitter();
  @Input() title!:string;
  constructor(public activeModal: NgbActiveModal, public i18nService: I18nService) { }

  ngOnInit(): void {
  }
  

  close() {
    this.activeModal.close(true);
  }
 async deleteComment() {
  await this.deleteEvent.emit(true);
  this.activeModal.close(true);
 }
}
