import { ApiResponse } from "src/app/models/api.response.model";
import { UnlockSectionResponse } from "../modals/response/unlock-section.response";
import { SubjectListResponse } from "../modals/subject-list.response";
import { TopicListResponse } from "../modals/topic-list-response.model";

export interface TopicListState {
  topiclist: TopicListResponse | null
  subjectList: SubjectListResponse | null
  unlockedSection: UnlockSectionResponse | null
  updateUnlockSection: ApiResponse<null> | null
  updateSubjectList :ApiResponse<any> | null
}

export const initialState: TopicListState = {
  topiclist: null,
  subjectList:null,
  updateSubjectList:null,
  unlockedSection: null,
  updateUnlockSection: null
};
