export interface SharedState {
  showLoading: boolean;
  errorMessage: string;
  isAuthorized: boolean;
  authToken: string;
  successMessage: string;
  navigationLocation:string;
  videoHeaders: {
    stream_key?: string;
    stream_policy?: string
  }
}

export const initialState: SharedState = {
  showLoading: false,
  isAuthorized: false,
  errorMessage: '',
  successMessage: '',
  authToken: '',
  videoHeaders: {},
  navigationLocation: 'home'
};
