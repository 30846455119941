import { createAction, props } from "@ngrx/store";
import { LoginRequest } from "../models/login-request.model";
import { LoginResponse } from "../models/login-response.models";
export const LOGIN_SUBMIT = '[login page] login submit';
export const LOGIN_SUCCESS = '[login page] login success';
export const LOGIN_FAILED = '[login page] login failed';
export const AUTO_LOGIN = '[login page] auto login';

export const autoLogin = createAction(AUTO_LOGIN, props<{ token: string }>())
export const loginSubmit = createAction(
    LOGIN_SUBMIT,
    props<LoginRequest>()
);

export const loginSuccess = createAction(
    LOGIN_SUCCESS,
    props<LoginResponse>()
);
export const loginFailed = createAction(
    LOGIN_FAILED,
    props<Error>()
);

