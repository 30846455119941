import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { changePasswordRequest } from "../components/authorization/change-password/models/change-password.request";
import { changePasswordResponse } from "../components/authorization/change-password/models/change-password.response";
import { ForgetRequest } from "../components/authorization/forgot-password/models/forget-request.model";
import { ForgetResponse } from "../components/authorization/forgot-password/models/forget-response.model";
import { LoginRequest } from "../components/authorization/login/models/login-request.model";
import { LoginResponse } from "../components/authorization/login/models/login-response.models";
import { CourseSelectRequest } from "../components/authorization/otp/model/course-select.request";
import { CourseSelectResponse } from "../components/authorization/otp/model/course-select.response";
import { GradeResponse } from "../components/authorization/otp/model/grade-response.model";
import { LanguageRequest } from "../components/authorization/otp/model/language-request";
import { OtpRequest } from "../components/authorization/otp/model/otp.request";
import { OtpResponse } from "../components/authorization/otp/model/otp.response";
import { ResendOtpEmailRequest, ResendOtpRequest } from "../components/authorization/otp/model/resend-request";
import { VerifyOtpForgetPassword } from "../components/authorization/otp/model/verify-otp-forget-password.model";
import { Country } from "../components/authorization/phone-number-verification/models/country-list.response";
import { resetPasswordRequest } from "../components/authorization/reset-password/models/reset-password.request";
import { resetPasswordResponse } from "../components/authorization/reset-password/models/reset-password.response";
import { SignupRequest } from "../components/authorization/sign-up/model/signup.request";
import { SignupResponse } from "../components/authorization/sign-up/model/signup.response";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {

    timeoutInterval: any;
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }


    signup(SignupRequest: SignupRequest): Observable<ApiResponse<SignupResponse>> {
        return from(this.apiService.post(
            `user/auth/signup`,
            SignupRequest
        ));
    }
    login(loginRequest: LoginRequest): Observable<ApiResponse<LoginResponse>> {

        return from(this.apiService.post(
            `user/auth/signin`,
            loginRequest
        ));
    }
    forgot(ForgetState: ForgetRequest): Observable<ApiResponse<ForgetResponse>> {

        return from(this.apiService.post(
            `user/forgot-password`,
            ForgetState
        ));
    }

    otp(OtpState: OtpRequest): Observable<ApiResponse<OtpResponse>> {

        return from(this.apiService.post(
            `user/verify-otp`,
            OtpState
        ));
    }
    resendOtp(OtpState: ResendOtpRequest): Observable<ApiResponse<OtpResponse>> {

        return from(this.apiService.post(
            `user/send-otp-to-phone`,
            OtpState
        ));
    }
    resendOtpEmail(OtpState: ResendOtpEmailRequest): Observable<ApiResponse<OtpResponse>> {
        return from(this.apiService.post(
            `user/resend-otp`,
            OtpState
        ));
    }
    changePassword(ChangePasswordState: changePasswordRequest): Observable<ApiResponse<changePasswordResponse>> {

        return from(this.apiService.post(
            `user/change-password`,
            ChangePasswordState
        ));
    }
    resetPassword(ResetPasswordState: resetPasswordRequest): Observable<ApiResponse<resetPasswordResponse>> {

        return from(this.apiService.post(
            `user/change-password`,
            ResetPasswordState
        ));
    }
    courseSelect(req: CourseSelectRequest): Observable<ApiResponse<CourseSelectResponse>> {

        return from(this.apiService.post(
            `user/update-grade`,
            req

        ));
    }
    getGrades(req:LanguageRequest): Observable<ApiResponse<GradeResponse>> {
        return from(this.apiService.post(
            `user/grade-list`,
            req
        ));
    }
    verifyOtpForForgortPassword(verifyOtp: VerifyOtpForgetPassword): Observable<ApiResponse<any>> {

        return from(this.apiService.post(
            `user/verify-forgot-password-otp`,
            verifyOtp
        ));
    }
    getCountries():Observable<ApiResponse<Country>> {
        return from(this.apiService.post(
            'user/country-codes',
            {}
        ))
    }
}