import { aboutUsResponse } from "../models/response/about-response";
import { contactUs } from "../models/response/contact-us.response";
import { privacyPolicyResponse } from "../models/response/privacy-policy-response";
import { TermsConditionResponse } from "../models/response/terms-condition-response";
export interface SettingState {
    aboutUs: aboutUsResponse | null
    termsCondition: TermsConditionResponse | null
    privacyPolicy: privacyPolicyResponse | null
    contactUs: contactUs | null
}

export const initialState: SettingState = {
    aboutUs: null,
    termsCondition: null,
    privacyPolicy: null,
    contactUs: null
}

