import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { WelcomeModalService } from "src/app/modals/welcome-modal/welcome.modal.service";
import { headerService } from "src/app/services/header.service";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { notificationCountFailed, notificationCountSuccess, notificationListFailed, notificationListSuccess, NOTIFICATION_COUNT_SUBMIT, NOTIFICATION_LIST_SUBMIT, userProfileFailed, userProfileSuccess, USER_PROFILE_SUBMIT_MESSAGE } from "./header.action";
import { NotificationListRequest } from "../models/notification-list.request";

@Injectable()
export class UserProfileEffect {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private headerService: headerService,
         private welcomeModalService: WelcomeModalService,private modal: NgbModal) { }
         showPopupOnce:boolean=true;
    userProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(USER_PROFILE_SUBMIT_MESSAGE),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.headerService.userProfile().pipe(
                    map((post) => {  
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return userProfileSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(userProfileFailed(errResp.error));
                    })
                );
            })
        );
    });


    notificationList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NOTIFICATION_LIST_SUBMIT),
            exhaustMap((id : NotificationListRequest) => {
                let req : NotificationListRequest = {
                    limit : id.limit,
                    offset : id.offset
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.headerService.notificationList(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return notificationListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(notificationListFailed(errResp.error));
                    })
                );
            })
        );
    });

    notificationCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(NOTIFICATION_COUNT_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.headerService.notificationCount().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return notificationCountSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(notificationCountFailed(errResp.error));
                    })
                );
            })
        );
    });
}
