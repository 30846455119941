import { createReducer, on, Action } from "@ngrx/store";
import { editProfileFailed, pinnedReplyFailed, pinnedReplySuccess, ProfileEarningFailed, ProfileEarningSuccess, ProfileTestProgressFailed, ProfileTestProgressSuccess, ProfileTopicProgressFailed, ProfileTopicProgressSuccess } from "./profile.action";
import { initialState, ProfileState } from "./profile.state";

const _profileTopicProgressReducer = createReducer(
    initialState,
    on(ProfileTopicProgressSuccess, (state, action) => {
        return {
            ...state,
            topicProgress: action
        };
    }),
    on(ProfileTopicProgressFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(ProfileTestProgressSuccess, (state, action) => {
        return {
            ...state,
            testProgress: action
        };
    }),
    on(ProfileTestProgressFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(ProfileEarningSuccess, (state, action) => {
        return {
            ...state,
            earning: action
        };
    }),
    on(ProfileEarningFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(editProfileFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(pinnedReplySuccess, (state, action) => {
        return {
            ...state,
            pinnedComment: action
        };
    }),
    on(pinnedReplyFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    })
)

export function ProfileTopicProgressReducer(state: ProfileState | undefined, action: Action) {
    return _profileTopicProgressReducer(state, action)
}