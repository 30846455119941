import { EditprofileResponse } from "../models/response/edit-profile.response";
import { PinnedReplyResponse } from "../models/response/pinned-reply.response";
import { profileEarningResponse } from "../models/response/profile-earning-progress.response";
import { TestProgressResponse } from "../models/response/profile-test-progress.response";
import { profileTopicProgressResponse } from "../models/response/profile-topic-progress.response";

export interface ProfileState{
    topicProgress : profileTopicProgressResponse | null
    testProgress : TestProgressResponse | null
    earning : profileEarningResponse | null
    editProfile : EditprofileResponse | null
    pinnedComment : PinnedReplyResponse | null
}

export const initialState : ProfileState ={
    topicProgress : null,
    testProgress :  null,
    earning : null,
    editProfile : null,
    pinnedComment : null
}