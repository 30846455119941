import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { DashboardService } from "src/app/services/dashboard.service";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { homeDashboardFailed, homeDashboardSuccess, HOME_DASHBOARD_SUBMIT } from "./home-dash.action";

@Injectable()
export class HomeDashboardEffect {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private dashboardService: DashboardService,) { }

    dashboardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HOME_DASHBOARD_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.dashboardService.homeDashboard().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return homeDashboardSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(homeDashboardFailed(errResp.error));
                    })
                );
            })
        );
    })
}
