export const extractQueryParameters = (url: string, keysArray: string[]) => {
  const url_ = new URL(url);

  const urlParams = new URLSearchParams(url_.search);
  const result: any = {};
  let found = false;

  keysArray.forEach((key) => {
    const value = urlParams.get(key);
    if (value !== null) {
      found = true;
      result[key] = value;
    }
  });

  return found ? result : null;
};
