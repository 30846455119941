
import { Action, createReducer, on } from "@ngrx/store";
import { homeDashboardFailed, homeDashboardFlagUpdate, homeDashboardSuccess } from "./home-dash.action";
import { HomeDashboardState, initialState } from "./home-dash.state";


const _homeDashboardReducer = createReducer(
    initialState,
    on(homeDashboardSuccess, (state, action) => {
        return {
            ...state,
            dashboard: action
        };
    }),
    on(homeDashboardFlagUpdate, (state, action) => {
        return {
            ...state,
            dashboard: action
        };
    }),
    on(homeDashboardFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    })
);

export function HomeDashboardReducer(state: HomeDashboardState | undefined, action: Action) {
    return _homeDashboardReducer(state, action)
}