import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { i18nConfigService } from './i18n-config.service';
@Injectable(
  //   {providedIn: 'root'}
)
export class I18nService {
  currentLanguage: any;
  constructor(
    @Inject(i18nConfigService) public langList: any, private http: HttpClient) {
    this.initLang();
  }

  async initLang() {
    // try {
    //   this.http.get("https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location").subscribe((response: any) => {
    //     if (response.country == 'FR') {
    //       this.currentLanguage = 'FR';
    //       localStorage.setItem('lang', 'FR');
    //     }
    //     else if(!response){
    //       this.currentLanguage = 'FR';
    //       localStorage.setItem('lang', 'FR');
    //     }
    //     else {
    //       this.currentLanguage = 'EN';
    //       localStorage.setItem('lang', 'EN');
    //     }
        
    //   })
    // } catch (error) {
    //   this.currentLanguage = 'FR';
    //   localStorage.setItem('lang', 'FR');
    // }
    if(localStorage.getItem('lang')){
      this.currentLanguage=localStorage.getItem('lang')
    }
    else{
      localStorage.setItem('lang', 'FR');
      this.currentLanguage='FR'
    }
   
  }

  getString(key: any) {
    return this.langList[this.langList.map((e: any, i: any) => e.lang === this.currentLanguage ? i : null).filter((e: any) => e !== null)[0]]?.file?.default[key];
  }
  languageChange(lang: any) {
    this.currentLanguage = lang;
    localStorage.setItem('lang', lang);
    this.initLang()
  }
}                                                                                                                                                          
