import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { dummyAction } from "src/app/components/authorization/forgot-password/state/forget.action";
import { TopicListService } from "src/app/services/topic-list.service";
import { setLoadingSpinner, setSuccessMessage } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { addFaqCommentRequest } from "../model/request/add-faq-comment.request";
import { AddFaqQuestion } from "../model/request/add-faq-question.request";
import { BookmarkVideoRequset } from "../model/request/bookmark-video.request";
import { FaqCommentListRequest } from "../model/request/faq-comment-list.request";
import { flashcardListRequest } from "../model/request/flashcard-list.request";
import { ForumRequest } from "../model/request/forum.request";
import { getFlashcardRequest } from "../model/request/getFlashcard.request";
import { getResourceRequest } from "../model/request/getResources.request";
import { GetTopicTestQuestionRequest } from "../model/request/getTopic-test-questions.request";
import { isBookmarkRequest } from "../model/request/isBookMarked.request";
import { LikeUnlikeFaqRequest } from "../model/request/like-unlike-faq.request";
import { markFlashcardRequest } from "../model/request/mark-flashcard.request";
import { StartRevisionRequest } from "../model/request/start-revision.request";
import { TopicAnswerSubmitRequest } from "../model/request/submit-topic-answer.request";
import { TestResultRequest } from "../model/request/test-result.request";
import { TopicListDetailsRequest } from "../model/request/topic-list-details.request";
import { updateTopicDurationRequest } from "../model/request/updateUserTopicDuration.request";
import {
    ADD_FAQ_COMMENT_SUBMIT, ADD_FAQ_QUESTION_SUBMIT,
    BOOKMARK_VIDEO_SUBMIT,
    BookmarkVideoSuccess,
    FAQ_COMMENT_LIST_SUBMIT,
    FLASHCARD_LIST_SUBMIT,
    FLASHCARD_SUBMIT,
    FORUM_SUBMIT,
    GET_TOPIC_PREVIOUS_ANSWER_SUBMIT, GET_TOPIC_TEST_QUESTION_SUBMIT,
    GetTopicTestQuestionFailed, GetTopicTestQuestionSuccess,
    IS_BOOKMARKED_SUBMIT,
    LIKE_UNLIKE_FAQ_SUBMIT,
    LIST_PINNED_COMMENTS_SUBMIT,
    MARK_FLASHCARD_SUBMIT,
    RESOURCE_LIST_SUBMIT,
    ResourceListFailed,
    ResourceListSuccess,
    SELECT_TOPIC_LIST_FOR_REVISION_SUBMIT,
    SKIP_ONBOARDING,
    START_REVISION_SUBMIT,
    SUMMARY_LIST_SUBMIT,
    SelectTopicListForRevisionFailed, SelectTopicListForRevisionSuccess,
    StartRevisionFailed, StartRevisionSuccess,
    SummaryListFailed,
    SummaryListSuccess,
    TEST_RESULT_SUBMIT, TEST_RESULT_SUMMERY_SUBMIT,
    TOPIC_ANSWER_SUBMIT, TOPIC_CONTENT_LIST_DETAILS_SUBMIT, TOPIC_LIST_DETAILS_SUBMIT, TOPIC_TEST_SUBMIT,
    TopicContentListDetailsSuccess, TopicListDetailsSuccess,
    TopicTestAnswerResponseSuccess, TopicTestAnswerSubmitFailed, TopicTestFailed, TopicTestPreviousAnswerFailed, TopicTestPreviousAnswerSuccess, TopicTestSuccess,
    UPDATE_DURATION_SUBMIT,
    addfaqcommentFailed, addfaqcommentSuccess, addfaqquestionFailed, addfaqquestionSuccess,
    faqCommentFailed, faqCommentSuccess,
    flashCardFailed,
    flashCardSuccess,
    flashcardListFailed,
    flashcardListSuccess,
    forumFailed, forumSuccess,
    isBookmarkFailed, isBookmarkSuccess,
    likeunliefaqFailed, likeunliefaqSuccess,
    listPinnedCommentsFailed, listPinnedCommentsSuccess,
    markFlashcardFailed, markFlashcardSuccess,
    testResultFailed, testResultSuccess,
    topicResultSummerySuccess,
    updateTopicDurationFailed, updateTopicDurationSuccess
} from "./topic-list-details.action";
@Injectable()
export class TopicListDetailsEffect {
    actions$: any;
    constructor(private action$: Actions, private store: Store<AppState>, private topicListService: TopicListService) { }
    topicListDetailsEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TOPIC_LIST_DETAILS_SUBMIT),
            exhaustMap((id: TopicListDetailsRequest) => {
                let req: TopicListDetailsRequest = {
                    topic_id: id.topic_id,
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.topicListDetails(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TopicListDetailsSuccess(post.response)
                    })
                )
            })
        )
    })
    topicContentListDetailsEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TOPIC_CONTENT_LIST_DETAILS_SUBMIT),
            exhaustMap((id: TopicListDetailsRequest) => {
                let req: TopicListDetailsRequest = {
                    topic_id: id.topic_id,
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.topicContentList(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TopicContentListDetailsSuccess(post.response)
                    })
                )
            })
        )
    })
    topicTest$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TOPIC_TEST_SUBMIT),
            exhaustMap((id: TopicListDetailsRequest) => {
                let req: TopicListDetailsRequest = {
                    topic_id: id.topic_id,
                    section_number: id.section_number,
                    is_last_section: id.is_last_section
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.topicTest(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TopicTestSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(TopicTestFailed(errResp.error));
                    })
                );
            })
        );
    })
    getTopicTestQuestion$ = createEffect(() => {
        return this.action$.pipe(
            ofType(GET_TOPIC_TEST_QUESTION_SUBMIT),
            exhaustMap((id: GetTopicTestQuestionRequest) => {
                let req: GetTopicTestQuestionRequest = {
                    topic_test_id: id.topic_test_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.getTopicTestQuestion(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return GetTopicTestQuestionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(GetTopicTestQuestionFailed(errResp.error));
                    })
                );
            })
        );
    })
    selectTopiListForRevision$ = createEffect(() => {
        return this.action$.pipe(
            ofType(SELECT_TOPIC_LIST_FOR_REVISION_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.getTopicListForRevision().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return SelectTopicListForRevisionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(SelectTopicListForRevisionFailed(errResp.error));
                    })
                );
            })
        );
    })
    startRevision$ = createEffect(() => {
        return this.action$.pipe(
            ofType(START_REVISION_SUBMIT),
            exhaustMap((id: StartRevisionRequest) => {
                let req: StartRevisionRequest = {
                    topic_id: id.topic_id,
                    section_number: id.section_number,
                    is_last_section: id.is_last_section
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.startRevision(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return StartRevisionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(StartRevisionFailed(errResp.error));
                    })
                )
            })
        )
    })
    topicTestAnswerSubmit$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TOPIC_ANSWER_SUBMIT),
            exhaustMap((id: TopicAnswerSubmitRequest) => {
                let req: TopicAnswerSubmitRequest = {
                    topic_test_id: id.topic_test_id,
                    user_test_id: id.user_test_id,
                    test_ques_id: id.test_ques_id,
                    completion_time: id.completion_time,
                    user_test_answers: id.user_test_answers
                }
                return this.topicListService.submitTestAnswer(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TopicTestAnswerResponseSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(TopicTestAnswerSubmitFailed(errResp.error));
                    })
                )
            })
        )
    })
    topicTestPreviousAnswerSubmit$ = createEffect(() => {
        return this.action$.pipe(
            ofType(GET_TOPIC_PREVIOUS_ANSWER_SUBMIT),
            exhaustMap((id: TopicAnswerSubmitRequest) => {
                let req: TopicAnswerSubmitRequest = {
                    topic_test_id: id.topic_test_id,
                    user_test_id: id.user_test_id,
                    test_ques_id: id.test_ques_id,
                    completion_time: id.completion_time,
                    user_test_answers: id.user_test_answers
                }
                return this.topicListService.submitPreviousTest(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TopicTestPreviousAnswerSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(TopicTestPreviousAnswerFailed(errResp.error));
                    })
                )
            })
        )
    })
    bookmarkVideo$ = createEffect(() => {
        return this.action$.pipe(
            ofType(BOOKMARK_VIDEO_SUBMIT),
            exhaustMap((id: BookmarkVideoRequset) => {
                let req: BookmarkVideoRequset = {
                    topic_id: id.topic_id,
                    sub_topic_video_id: id.sub_topic_video_id,
                    isBookMark: id.isBookMark
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.bookmarkVideo(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        this.store.dispatch(setSuccessMessage({ message: post.message }))
                        return BookmarkVideoSuccess(post.response)
                    }),

                );
            })
        );
    })
    getFlashCard$ = createEffect(() => {
        return this.action$.pipe(
            ofType(FLASHCARD_SUBMIT),
            exhaustMap((id: getFlashcardRequest) => {
                let req: getFlashcardRequest = {
                    topic_id: id.topic_id,
                    section_number: id.section_number,
                    is_last_section: id.is_last_section
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.getFlashCard(req).pipe(

                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return flashCardSuccess(post.response)
                    }),

                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(flashCardFailed(errResp.error));
                    })
                );
            })
        );
    })
    getResourceList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(RESOURCE_LIST_SUBMIT),
            // filter((r: RouterNavigatedAction) => {
            //     return (r.payload.routerState.url.startsWith('/home/topics/topic-list/topic-view') || (r.payload.routerState.url.startsWith('/home/topics/pdf')));
            // }),
            // map((r: any) => {

            //     return r.payload.routerState['params']['id'] ? r.payload.routerState['params']['id'] : r.payload.routerState['params']['topic_id']
            // }),
            exhaustMap((id: any) => {
                let req: getResourceRequest = {
                    topic_id: id.topic_id ? id.topic_id : parseInt(id),
                    section_number: id.section_number,
                    is_last_section: id.is_last_section
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.getResource(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return ResourceListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(ResourceListFailed(errResp.error));
                    })
                );
            })
        );
    })

    getSummaryList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(SUMMARY_LIST_SUBMIT),
            // filter((r: RouterNavigatedAction) => {
            //     return (r.payload.routerState.url.startsWith('/home/topics/topic-list/topic-view') || (r.payload.routerState.url.startsWith('/home/topics/pdf')));
            // }),
            // map((r: any) => {

            //     return r.payload.routerState['params']['id'] ? r.payload.routerState['params']['id'] : r.payload.routerState['params']['topic_id']
            // }),
            exhaustMap((id: any) => {
                let req: getResourceRequest = {
                    topic_id: id.topic_id ? id.topic_id : parseInt(id),
                    section_number: id.section_number,
                    is_last_section: id.is_last_section
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.getSummary(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return SummaryListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(SummaryListFailed(errResp.error));
                    })
                );
            })
        );
    })

    markFlashcard$ = createEffect(() => {
        return this.action$.pipe(
            ofType(MARK_FLASHCARD_SUBMIT),
            exhaustMap((id: markFlashcardRequest) => {
                let req: markFlashcardRequest = {
                    flashcard_id: id.flashcard_id,
                    topic_id: id.topic_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.markFlashcard(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return markFlashcardSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(markFlashcardFailed(errResp.error));
                    })
                );
            })
        );
    })
    flashcardList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(FLASHCARD_LIST_SUBMIT),
            exhaustMap((id: flashcardListRequest) => {
                let list: flashcardListRequest = {
                    limit: id.limit,
                    offset: id.offset
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.flashcardList(list).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return flashcardListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(flashcardListFailed(errResp.error))
                    })
                );
            })
        );
    })
    testResult$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TEST_RESULT_SUBMIT),
            exhaustMap((id: TestResultRequest) => {
                let list: TestResultRequest = {
                    topic_test_id: id.topic_test_id,
                    user_test_id: id.user_test_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.testResult(list).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return testResultSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(testResultFailed(errResp.error))
                    })
                );
            })
        );
    })

    testResultSummery$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TEST_RESULT_SUMMERY_SUBMIT),
            exhaustMap((id: TestResultRequest) => {
                let list: TestResultRequest = {
                    topic_test_id: id.topic_test_id,
                    user_test_id: id.user_test_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.testResultSummery(list).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return topicResultSummerySuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(testResultFailed(errResp.error))
                    })
                );
            })
        );
    })
    updateTopicDuration$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UPDATE_DURATION_SUBMIT),
            exhaustMap((id: updateTopicDurationRequest) => {
                let req: updateTopicDurationRequest = {
                    topic_id: id.topic_id,
                    sub_topic_id: id.sub_topic_id,
                    sub_topic_video_id: id.sub_topic_video_id,
                    isCompleted: id.isCompleted,
                    watched_duration: id.watched_duration,
                    isWatched: id.isWatched,
                    flash_test_unable: id.flash_test_unable

                }
                // this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.updateTopicDuration(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return updateTopicDurationSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(updateTopicDurationFailed(errResp.error))
                    })
                );
            })
        );
    })

    isBookmarked$ = createEffect(() => {
        return this.action$.pipe(
            ofType(IS_BOOKMARKED_SUBMIT),
            exhaustMap((id: isBookmarkRequest) => {
                let req: isBookmarkRequest = {
                    sub_topic_video_id: id.sub_topic_video_id,
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.isBookmarked(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return isBookmarkSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(isBookmarkFailed(errResp.error))
                    })
                );
            })
        );
    })

    forumList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(FORUM_SUBMIT),
            exhaustMap((id: ForumRequest) => {
                let req: ForumRequest = {
                    limit: id.limit,
                    offset: id.offset,
                    sub_topic_video_id: id.sub_topic_video_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.forum(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return forumSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(forumFailed(errResp.error))
                    })
                );
            })
        );
    })
    faqCommentList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(FAQ_COMMENT_LIST_SUBMIT),
            exhaustMap((id: FaqCommentListRequest) => {
                let req: FaqCommentListRequest = {
                    limit: id.limit,
                    offset: id.offset,
                    sub_topic_video_id: id.sub_topic_video_id,
                    parent_id: id.parent_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.faqCommentList(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return faqCommentSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(faqCommentFailed(errResp.error))
                    })
                );
            })
        );
    })
    likeUnlikeFaqList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LIKE_UNLIKE_FAQ_SUBMIT),
            exhaustMap((id: LikeUnlikeFaqRequest) => {
                let req: LikeUnlikeFaqRequest = {
                    video_comment_id: id.video_comment_id,
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.likeunlikefaq(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return likeunliefaqSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(likeunliefaqFailed(errResp.error))
                    })
                );
            })
        );
    })
    addCommentOnFaq$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ADD_FAQ_COMMENT_SUBMIT),
            exhaustMap((id: addFaqCommentRequest) => {
                let req: addFaqCommentRequest = {
                    sub_topic_video_id: id.sub_topic_video_id,
                    parent_id: id.parent_id,
                    comment: id.comment
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.addFaqComment(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return addfaqcommentSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(addfaqcommentFailed(errResp.error))
                    })
                );
            })
        );
    })
    addQuestionOnFaq$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ADD_FAQ_QUESTION_SUBMIT),
            exhaustMap((id: AddFaqQuestion) => {
                let req: AddFaqQuestion = {
                    sub_topic_video_id: id.sub_topic_video_id,
                    comment: id.comment,
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.addFaqQuestion(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        this.store.dispatch(setSuccessMessage({ message: post.message }))
                        return addfaqquestionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(addfaqquestionFailed(errResp.error))
                    })
                );
            })
        );
    })


    listPinnedCommentsEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(LIST_PINNED_COMMENTS_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.topicListService.listPinnedComments().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return listPinnedCommentsSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(listPinnedCommentsFailed(errResp.error));
                    })
                );
            })
        )
    })
    skipOnboardingEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(SKIP_ONBOARDING),
            exhaustMap(() => {
                return this.topicListService.skipOnboarding().pipe(
                    map((post) => {
                        return dummyAction()
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(dummyAction())
                    })
                );
            })
        )
    })

}