import { Action, createReducer, on } from "@ngrx/store";
import { CommentOnQuestionFailed, CommentOnQuestionSuccess, QuestionLikeUnlikeFailed, QuestionLikeUnlikeSuccess, QuestionListFailed, QuestionListSuccess, QuestionListUpdateFlag, QuestionRepliesFailed, QuestionRepliesSuccess, QuestionRepliesUpdate, SubmitQuestionFailed, SubmitQuestionNull, SubmitQuestionSuccess, UpdateQuestionViewsFailed, UpdateQuestionViewsSuccess } from "./community.action";
import { initialState, QuestionListState } from "./community.state";


const _questionListReducer = createReducer(
    initialState,
    on(QuestionListSuccess, (state, action) => {
        return {
            ...state,
            questionList: action
        };
    }),
    on(QuestionListUpdateFlag, (state, action) => {
        return {
            ...state,
            questionList: action
        };
    }),

    on(QuestionListFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(CommentOnQuestionSuccess, (state, action) => {
        return {
            ...state,
            commentOnQuestion: action
        };
    }),
    on(CommentOnQuestionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(QuestionLikeUnlikeSuccess, (state, action) => {
        return {
            ...state,
            questionLikeUnlike: action
        };
    }),
    on(QuestionLikeUnlikeFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(QuestionRepliesSuccess, (state, action) => {
        return {
            ...state,
            questionReplies: action
        };
    }),
    on(QuestionRepliesUpdate, (state, action) => {
        return {
            ...state,
            questionReplies: action
        };
    }),

   

    on(QuestionRepliesFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(SubmitQuestionSuccess, (state, action) => {
        return {
            ...state,
            submitQuestion: action
        };
    }),
    on(SubmitQuestionNull, (state, action) => {
        return {
            ...state,
            submitQuestion: null,
        };
    }),
    on(SubmitQuestionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(UpdateQuestionViewsSuccess, (state, action) => {
        return {
            ...state,
            updateQuestionView: action
        };
    }),
    on(UpdateQuestionViewsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
);

export function QuestionListReducer(state: QuestionListState | undefined, action: Action) {
    return _questionListReducer(state, action)
}