import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { EditprofileRequest } from "../components/dashboard/profile/models/request/edit-profile.request";
import { EditprofileResponse } from "../components/dashboard/profile/models/response/edit-profile.response";
import { PinnedReplyResponse } from "../components/dashboard/profile/models/response/pinned-reply.response";
import { profileEarningResponse } from "../components/dashboard/profile/models/response/profile-earning-progress.response";
import { TestProgressResponse } from "../components/dashboard/profile/models/response/profile-test-progress.response";
import { profileTopicProgressResponse } from "../components/dashboard/profile/models/response/profile-topic-progress.response";
import { ApiResponse } from "../models/api.response.model";
import { ApiService } from "./api";

@Injectable({
    providedIn : "root"
})

export class ProfileService{
    constructor(private apiService : ApiService){}

    topicProgress():Observable<ApiResponse<profileTopicProgressResponse>>{
        return from(this.apiService.post(
            'user/my-topic-progress',{}
        ))
  }

  testProgress():Observable<ApiResponse<TestProgressResponse>>{
    return from(this.apiService.post(
        'user/my-test-progress',{}
    ))
}

earning():Observable<ApiResponse<profileEarningResponse>>{
    return from(this.apiService.post(
        'user/my-earnings',{}
    ))
}

editProfile(editprofileState : EditprofileRequest):Observable<ApiResponse<EditprofileResponse>>{
    return from(this.apiService.post(
        'user/edit-profile',
        editprofileState
    ))
}


pinnedReply():Observable<ApiResponse<PinnedReplyResponse>>{
    return from(this.apiService.post(
        'user/pinned-comments',{}
    ))    

}


}