import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { cancelSubscriptionListRequest } from "../components/subscribe/models/request/cancel-subscription-list.request";
import { purchaseSubscriptionRequest } from "../components/subscribe/models/request/purchase-subscription.request";
import { cancelSubscriptionResponse } from "../components/subscribe/models/response/cancel-subscription-list.response";
import { masterSubscriptionListResponse } from "../components/subscribe/models/response/master-subscription-list.response";
import { mySubscriptionListResponse } from "../components/subscribe/models/response/my-subscription-list.response";
import { purchaseSubscriptionResponse } from "../components/subscribe/models/response/purchase-subscription.response";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";
import { MasterSubscriptionListRequest } from "../components/subscribe/models/request/master-subscription-list.request";

@Injectable({
    providedIn: 'root'
})



export class SubscriptionService {
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }

    masterSubscriptionList(subscribeState : MasterSubscriptionListRequest): Observable<ApiResponse<masterSubscriptionListResponse>> {
        return from(this.apiService.post(
            'user/master-subscription-list',subscribeState
        ))
    }
    mySubscriptionList(): Observable<ApiResponse<mySubscriptionListResponse>> {
        return from(this.apiService.get(
            'user/my-subscription-list',
        ))
    }

    purchaseSubscription(subscribeState: purchaseSubscriptionRequest): Observable<ApiResponse<purchaseSubscriptionResponse>> {
        return from(this.apiService.post(
            'user/stripe-subscription-checkout',
            subscribeState
        ))
    }
    cancelSubscription(subscribeState: cancelSubscriptionListRequest): Observable<ApiResponse<cancelSubscriptionResponse>> {
        return from(this.apiService.post(
            'user/cancel-subscription',
            subscribeState
        ))
    }
}
