import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { setSuccessMessage } from "../shared/store/shared.actions";
import { AppState } from "../store/app.state";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor(private store: Store<AppState>) {}
  public setValue(key: string, value: string) {
    try {
      document.cookie = `${key}=${value};path=/;domain=studeo.app`;
    } catch (e) {
      console.log(e);
    }
    return localStorage.setItem(key, value);
  }
  public getValue(key: string) {
    return localStorage.getItem(key);
  }
  public deleteValue() {
    document.cookie = `studeoToken=;path=/;domain=studeo.app`;
    return localStorage.clear();
  }
  public showMessage(frenchText: string, englishText: string) {
    let displayMsg =
      localStorage.getItem("lang") == "FR" ? frenchText : englishText;
    this.store.dispatch(setSuccessMessage({ message: displayMsg }));
  }
  public checkLanguage() {
    return localStorage.getItem("lang") == "FR" ? true : false;
  }
}
