import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
  })

  export class CryptoService {
    constructor() { }


    decryptf(value:any,key:any){
      var conversionDecryptOutput = CryptoJS.AES.decrypt(value,key).toString(CryptoJS.enc.Utf8);
      return conversionDecryptOutput;
    }

    encryptf(value:any,key:any){
       var conversionEncryptOutput = CryptoJS.AES.encrypt(value,key).toString();
       return conversionEncryptOutput;
    }
  }


