import { HttpInterceptor, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable ,Injector} from '@angular/core';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApiService } from './api';
import { LocalStorageService } from './local.storage.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(private injector:Injector,
    private toastr :ToastrService,
    private localStorage:LocalStorageService,
    private route: Router) {
     }
  intercept(req:any,next:any){      
    let authService = this.injector.get(ApiService)      
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            }
          }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 400  || err.status ===500 ) {}
              // if (err.status === 401 || err.status ===500) { // comment because in solver it was redirect to login page.
              if (err.status === 401) {
                // this.toastr.error('Session expired please login again','Login');
                this.localStorage.deleteValue();
                this.route.navigate([''])
              }
            }
      }),
);  
}
}
