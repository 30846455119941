import { createFeatureSelector, createSelector } from "@ngrx/store"
import { ResetPasswordState } from "./rest-password.state";

export const RESET_PASSWORD_NAME = 'reset-password'


const getSharedState = createFeatureSelector<ResetPasswordState>(RESET_PASSWORD_NAME);

export const getOtpResetpassword = createSelector(getSharedState, (state) => {
    return state.otp;
});