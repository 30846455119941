import { Action, createReducer, on } from "@ngrx/store";
import {
    addfaqcommentFailed, addfaqcommentSuccess, addfaqquestionFailed, addfaqquestionSuccess, BookmarkVideoFailed, BookmarkVideoSuccess, faqCommentFailed, faqCommentSuccess, flashCardFailed, flashcardListFailed, flashcardListSuccess, flashCardSuccess, forumFailed, forumSuccess, GetTopicTestQuestionFailed, GetTopicTestQuestionSuccess, isBookmarkFailed, isBookmarkNull, isBookmarkSuccess, likeunliefaqFailed, likeunliefaqSuccess, listPinnedCommentsFailed, listPinnedCommentsSuccess, markFlashcardFailed, markFlashcardSuccess, ResourceListFailed, ResourceListSuccess, SelectTopicListForRevisionFailed,
    SelectTopicListForRevisionSuccess, setSelectedTopicInformation, setTopicDetailsNull, setTopicTestNull, StartRevisionFailed, StartRevisionSuccess, SummaryListSuccess, testResultFailed, testResultSuccess, TopicContentListDetailsFailed, TopicContentListDetailsSuccess, TopicListDetailsFailed, TopicListDetailsSuccess, topicResultSummeryFailed, topicResultSummerySuccess, TopicTestAnswerResponseSuccess, TopicTestAnswerSubmitFailed, TopicTestFailed, TopicTestPreviousAnswerFailed, TopicTestPreviousAnswerSuccess, TopicTestSuccess, updateTopicDurationFailed, updateTopicDurationSuccess
} from "./topic-list-details.action";
import { initialState, TopicListDetailsState } from "./topic-list-details.state";

const _TopicListDetailsReducer = createReducer(

    initialState,
    on(TopicListDetailsSuccess, (state, action) => {
        return {
            ...state,
            topicListDetails: action
        }
    }),

    on(TopicListDetailsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),

    on(TopicContentListDetailsSuccess, (state, action) => {
        return {
            ...state,
            topicContentListDetails: action
        }
    }),

    on(TopicContentListDetailsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),

    on(TopicTestSuccess, (state, action) => {
        return {
            ...state,
            topicTest: action
        }
    }),
    on(TopicTestFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(GetTopicTestQuestionSuccess, (state, action) => {
        return {
            ...state,
            getTopicTestQuestion: action
        }
    }),
    on(GetTopicTestQuestionFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(TopicTestAnswerResponseSuccess, (state, action) => {
        return {
            ...state,
            topicAnswerSubmit: action
        }
    }),
    on(TopicTestAnswerSubmitFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        }
    }),

    on(TopicTestPreviousAnswerSuccess, (state, action) => {
        return {
            ...state,
            topicPreviousAnswerSubmit: action
        }
    }),
    on(TopicTestPreviousAnswerFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        }
    }), 

    on(SelectTopicListForRevisionSuccess, (state, action) => {
        return {
            ...state,
            selectTopicForRevision: action
        };
    }),
    on(SelectTopicListForRevisionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(StartRevisionSuccess, (state, action) => {
        return {
            ...state,
            startRevision: action
        };
    }),
    on(StartRevisionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
    on(BookmarkVideoSuccess, (state, action) => {
        return {
            ...state,
            bookmarkVideo: action
        }
    }),
    on(BookmarkVideoFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action
        };
    }),
    on(ResourceListSuccess, (state, action) => {
        return {
            ...state,
            getResourceList: action
        }
    }),
    on(SummaryListSuccess, (state, action) => {
        return {
            ...state,
            getSummaryList: action
        }
    }),
    on(ResourceListFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action

        }
    }),
    on(flashCardSuccess, (state, action) => {
        return {
            ...state,
            getFlashcard: action
        };
    }),

    on(flashCardFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(markFlashcardSuccess, (state, action) => {
        return {
            ...state,
            markFlashcard: action
        };
    }),

    on(markFlashcardFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(flashcardListSuccess, (state, action) => {
        return {
            ...state,
            flastcardlist: action
        };
    }),

    on(flashcardListFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(testResultSuccess, (state, action) => {
        return {
            ...state,
            testResult: action
        };
    }),

    on(testResultFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(topicResultSummerySuccess, (state, action) => {
        return {
            ...state,
            topicResultSummery: action
        };
    }),

    on(topicResultSummeryFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(updateTopicDurationSuccess, (state, action) => {
        return {
            ...state,
            updateTopicDuration: action
        };
    }),

    on(updateTopicDurationFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(isBookmarkSuccess, (state, action) => {
        return {
            ...state,
            isBookmarked: action
        };
    }),

    on(isBookmarkFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(isBookmarkNull, (state, action) => {
        return {
            ...state,
            isBookMark: null,
        };
    }),

    on(forumSuccess, (state, action) => {
        return {
            ...state,
            forumList: action
        };
    }),

    on(forumFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(setTopicTestNull, (state, action) => {
        return {
            ...state,
            topicAnswerSubmit: null,
            getTopicTestQuestion: null,
        };
    }),
    on(setTopicDetailsNull, (state, action) => {
        return {
            ...state,
            topicListDetails: null,
        };
    }),

    on(faqCommentSuccess, (state, action) => {
        return {
            ...state,
            faqCommentList: action
        };
    }),
    on(faqCommentFailed, (state, action) => {
        return {
            ...state,
            faqCommentList: null,
            errorMessage: action.message
        };
    }),

    on(likeunliefaqSuccess, (state, action) => {
        return {
            ...state,
            likeunlikefaq: action
        };
    }),
    on(likeunliefaqFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),

    on(addfaqcommentSuccess, (state, action) => {
        return {
            ...state,
            addfaqcomment: action
        };
    }),
    on(addfaqcommentFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),

    on(addfaqquestionSuccess, (state, action) => {
        return {
            ...state,
            addfaqquestion: action
        };
    }),
    on(addfaqquestionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),

    on(listPinnedCommentsSuccess, (state, action) => {
        return {
            ...state,
            listpinnedcomments: action
        };
    }),
    on(listPinnedCommentsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(setSelectedTopicInformation, (state, action) => {
        return {
            ...state,
            selectedTopicInformation:action

        };
    }),
)

export function TopicListDetailsReducer(state: TopicListDetailsState | undefined, action: Action) {
    return _TopicListDetailsReducer(state, action);

}