import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { ContentQuestionsRequest } from "../components/practice/models/request/content-questions.request";
import { contentQuestionFilter } from "../components/practice/models/response/content-question-filter.response";
import { ContentQuestionsResponse } from "../components/practice/models/response/content-questions.response";
import { TypeOfQuestionResponse } from "../components/practice/models/response/type-of-question.response";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable(
    {
        providedIn: "root"
    }
)

export class PracticeService {
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }

    contentQuestions(ContentquestionState: ContentQuestionsRequest): Observable<ApiResponse<ContentQuestionsResponse>> {
        return from(this.apiService.post(
            `user/content-questions`,
            ContentquestionState
        ));
    }

    typeOfQuestion():Observable<ApiResponse<TypeOfQuestionResponse>> {
        return from(this.apiService.get(
            'user/master-tags'
        ))
    }
    topicListFilterList():Observable<ApiResponse<contentQuestionFilter>> {
        return from(this.apiService.post(
            'user/topic-list-for-content-questions-filter',
            {}
        ))
    }
    
}