import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QuestionListState } from "./community.state";

export const COMMUNITY_NAME = 'community flow'

const getCommunitySharedState = createFeatureSelector<QuestionListState>(COMMUNITY_NAME);
export const getQuestionList = createSelector(getCommunitySharedState, (state) => {
    return state.questionList
})

export const getCommentOnQuestion = createSelector(getCommunitySharedState, (state) => {
    return state.commentOnQuestion
})

export const getQuestionLikeUnlike = createSelector(getCommunitySharedState, (state) => {
    return state.questionLikeUnlike
})

export const getQuestionReplies = createSelector(getCommunitySharedState, (state) => {
    return state.questionReplies
})

export const getSubmitQuestion = createSelector(getCommunitySharedState, (state) => {
    return state.submitQuestion
})

export const getUpdateQuestionView = createSelector(getCommunitySharedState, (state) => {
    return state.updateQuestionView
})