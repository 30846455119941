import { contentQuestionFilter } from "../models/response/content-question-filter.response";
import { ContentQuestionsResponse } from "../models/response/content-questions.response";
import { TypeOfQuestionResponse } from "../models/response/type-of-question.response";
export interface ContentQuestionState {
    contentQuestions: ContentQuestionsResponse | null
    typeOfQuestion: TypeOfQuestionResponse | null
    topicListContentQuestionFilter:contentQuestionFilter | null
}

export const initialState: ContentQuestionState = {
    contentQuestions: null,
    typeOfQuestion: null,
    topicListContentQuestionFilter:null,
}