import { EventEmitter, Injectable, Output } from '@angular/core';
@Injectable(
    {providedIn: 'root'}
)
export class unreadService {
 @Output() unreadCountUpdateEvent = new EventEmitter<boolean>();
  constructor(){
  }
  unreadCountUpdate(msg: boolean) {
    this.unreadCountUpdateEvent.emit(msg);
  }
}                                                                                                                                                          
