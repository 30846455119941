import { cancelSubscriptionResponse } from "../models/response/cancel-subscription-list.response";
import { masterSubscriptionListResponse } from "../models/response/master-subscription-list.response";
import { mySubscriptionListResponse } from "../models/response/my-subscription-list.response";
import { purchaseSubscriptionResponse } from "../models/response/purchase-subscription.response";
export interface SubscriptionState {
    CancelSubscriptionList: cancelSubscriptionResponse | null
    MasterSubscriptionList: masterSubscriptionListResponse | null
    PurchaseSubscription: purchaseSubscriptionResponse | null
    MySubscriptionList: mySubscriptionListResponse | null
}
export const initialState: SubscriptionState = {
    CancelSubscriptionList: null,
    MasterSubscriptionList: null,
    PurchaseSubscription: null,
    MySubscriptionList: null,
}
