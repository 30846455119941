import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailSentModalComponent } from './email-send.modal';

@Injectable({
    providedIn: 'root'
})
export class EmailSendModalService {

    constructor(private modal: NgbModal) { }

    open(title: string, subtitle: string, button_text:string) {

        let modelRef = this.modal.open(EmailSentModalComponent, { size: 'md', centered: true, backdrop: 'static' });
        modelRef.componentInstance.title = title
        modelRef.componentInstance.subtitle = subtitle
        modelRef.componentInstance.button_text=button_text
        return modelRef
    }
}