import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserProfileState } from "./header.state";
export const USER_STATE_NAME = 'user-profile'

const getSharedState = createFeatureSelector<UserProfileState>(USER_STATE_NAME);
export const getUserProfile = createSelector(getSharedState, (state) => {
    return state.userProfile
})

export const getNotificationList = createSelector(getSharedState, (state) => {
    return state.notificationList
}
)
export const getNotificationCount = createSelector(getSharedState, (state) => {
    return state.notificationCount
})