import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { throwError } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { setLoadingSpinner } from "../shared/store/shared.actions";
import { AppState } from "../store/app.state";
import { LocalStorageService } from "./local.storage.service";
import { UtilService } from "./util.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public endpoint = environment.endpoint;
  encPassword: string = "ccC2H19lDDbQDfareastNMQdd0FloLGG";

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      isMobile: "false",
    }),
  };
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private util: UtilService,
    private store: Store<AppState>,
    private cookieService: CookieService
  ) {}

  private extractData(res: Response): any {
    let body = res;

    return body || {};
  }

  async get(url: any): Promise<any> {
    let res = await this.http
      .get<any>(this.endpoint + url, await this.getHeaders())
      .toPromise();
    return res;
  }
  async post(url: any, body: any, showLoader: boolean = true): Promise<any> {
    if (showLoader) {
      this.store.dispatch(setLoadingSpinner({ status: true }));
    }
    let res = await this.http
      .post<any>(this.endpoint + url, body, await this.getHeaders())
      .pipe(map(this.extractData))
      .toPromise();

    if (res.success) {
      if (showLoader) {
        this.store.dispatch(setLoadingSpinner({ status: false }));
      }
      return res;
    } else {
      if (showLoader) {
        this.store.dispatch(setLoadingSpinner({ status: false }));
      }
      // return new Error(res);
      throw new Error(res?.message || res);
    }
  }
  async patch(url: any, body: any): Promise<any> {
    let res = await this.http
      .patch<any>(this.endpoint + url, body, await this.getHeaders())
      .pipe(map(this.extractData))
      .toPromise();

    if (res.success) {
      return res;
    } else {
      throw new Error(res.message);
    }
  }

  handleError(error: any) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error.error);
  }
  async delete(url: any): Promise<any> {
    let res = await this.http
      .delete<any>(this.endpoint + url, await this.getHeaders())
      .toPromise();
    return res;
  }

  async getHeaders() {
    let a: any = this.localStorageService.getValue("studeoToken");
    if (!a) {
      a = this.cookieService.get("studeoToken");
      if (a === "") {
        a = null;
      } else {
        a = await this.util.encrypt(a);
      }
    }
    let token = await this.util.decrypt(a);
    // let token = this.localStorageService.getValue("studeoToken");

    if (token) {
      this.httpOptions = {
        headers: new HttpHeaders({
          isMobile: "false",
          "Content-Type": "application/json",
          Authorization: token,
        }),
      };
    }
    return this.httpOptions;
  }
  downloadFile(data: any, filename = "data") {
    let csvData = this.ConvertToCSV(data, [
      "first_name",
      "last_name",
      "email_id",
      "phone_number",
    ]);

    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No,";
    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];
        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }
}
