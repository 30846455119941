
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ChangePasswordReducer } from '../components/authorization/change-password/state/change-pass.reducer';
import { CHANGE_PASSWORD_NAME } from '../components/authorization/change-password/state/change-pass.selector';
import { ChangePasswordState } from '../components/authorization/change-password/state/change-pass.state';
import { ForgetReducer } from '../components/authorization/forgot-password/state/forget.reducer';
import { FORGET_STATE_NAME } from '../components/authorization/forgot-password/state/forget.selector';
import { ForgetState } from '../components/authorization/forgot-password/state/forget.state';
import { LoginReducer } from '../components/authorization/login/state/login.reducer';
import { LOGIN_STATE_NAME } from '../components/authorization/login/state/login.selector';
import { LoginState } from '../components/authorization/login/state/login.state';
import { OtpReducer } from '../components/authorization/otp/state/otp.reducer';
import { OTP_STATE_NAME } from '../components/authorization/otp/state/otp.selecter';
import { OtpState } from '../components/authorization/otp/state/otp.state';
import { ResetPasswordReducer } from '../components/authorization/reset-password/state/rest-password.reducer';
import { RESET_PASSWORD_NAME } from '../components/authorization/reset-password/state/rest-password.selector';
import { ResetPasswordState } from '../components/authorization/reset-password/state/rest-password.state';
import { SignupReducer } from '../components/authorization/sign-up/state/signup.reducer';
import { SIGNUP_STATE_NAME } from '../components/authorization/sign-up/state/signup.selector';
import { SignupState } from '../components/authorization/sign-up/state/signup.state';
import { BookmarkReducer } from '../components/bookmark/state/bookmark.reducer';
import { BOOKMARK_NAME } from '../components/bookmark/state/bookmark.selector';
import { BookmarkState } from '../components/bookmark/state/bookmark.state';
import { QuestionListReducer } from '../components/community/state/community.reducer';
import { COMMUNITY_NAME, } from '../components/community/state/community.selector';
import { QuestionListState } from '../components/community/state/community.state';
import { HomeDashboardReducer } from '../components/dashboard/home-dashboard/state/home-dash.reducer';
import { HOME_DASHBOARD_NAME } from '../components/dashboard/home-dashboard/state/home-dash.selector';
import { HomeDashboardState } from '../components/dashboard/home-dashboard/state/home-dash.state';
import { ProfileTopicProgressReducer } from '../components/dashboard/profile/state/profile.reducer';
import { PROFILE_NAME } from '../components/dashboard/profile/state/profile.selector';
import { ProfileState } from '../components/dashboard/profile/state/profile.state';
import { ContentQuestionsReducer } from '../components/practice/state/content-questions.reducer';
import { CONTENT_QUESTIONS_NAME } from '../components/practice/state/content-questions.selector';
import { ContentQuestionState } from '../components/practice/state/content-questions.state';
import { SettingReducer } from '../components/setting/state/setting.reducer';
import { SETTING_STATE_NAME } from '../components/setting/state/setting.selector';
import { SettingState } from '../components/setting/state/setting.state';
import { SubscriptionReducer } from '../components/subscribe/state/subscribe.reducer';
import { SUBSCRIPTION_NAME } from '../components/subscribe/state/subscribe.selector';
import { SubscriptionState } from '../components/subscribe/state/subscribe.state';
import { TopicListReducer } from '../components/topic-list/topic-list-home/state/topic-list.reducer';
import { TOPIC_LIST_NAME } from '../components/topic-list/topic-list-home/state/topic-list.selector';
import { TopicListState } from '../components/topic-list/topic-list-home/state/topic-list.state';
import { TopicListDetailsReducer } from '../components/topic-list/topic-list-view/state/topic-list-details.reducer';
import { TOPIC_DETAILS_NAME } from '../components/topic-list/topic-list-view/state/topic-list-details.selector';
import { TopicListDetailsState } from '../components/topic-list/topic-list-view/state/topic-list-details.state';
import { UserProfileRuducer } from '../shared/header/state/header.reducer';
import { USER_STATE_NAME } from '../shared/header/state/header.selector';
import { UserProfileState } from '../shared/header/state/header.state';
import { SharedReducer } from '../shared/store/shared.reducer';
import { SHARED_STATE_NAME } from '../shared/store/shared.selector';
import { SharedState } from '../shared/store/shared.state';

export interface AppState {
  router: RouterReducerState;
  [SHARED_STATE_NAME]: SharedState,
  [LOGIN_STATE_NAME]: LoginState,
  [FORGET_STATE_NAME]: ForgetState,
  [OTP_STATE_NAME]: OtpState,
  [CHANGE_PASSWORD_NAME]: ChangePasswordState,
  [RESET_PASSWORD_NAME]: ResetPasswordState,
  [SIGNUP_STATE_NAME]: SignupState,
  [HOME_DASHBOARD_NAME]: HomeDashboardState,
  [USER_STATE_NAME]: UserProfileState,
  [TOPIC_LIST_NAME]: TopicListState,
  [TOPIC_DETAILS_NAME]: TopicListDetailsState
  [COMMUNITY_NAME]: QuestionListState,
  [BOOKMARK_NAME]: BookmarkState
  [CONTENT_QUESTIONS_NAME]: ContentQuestionState
  [SETTING_STATE_NAME]: SettingState
  [SUBSCRIPTION_NAME]: SubscriptionState
  [PROFILE_NAME]: ProfileState
}

export const appReducer = {
  router: routerReducer,
  [SHARED_STATE_NAME]: SharedReducer,
  [LOGIN_STATE_NAME]: LoginReducer,
  [FORGET_STATE_NAME]: ForgetReducer,
  [OTP_STATE_NAME]: OtpReducer,
  [CHANGE_PASSWORD_NAME]: ChangePasswordReducer,
  [SIGNUP_STATE_NAME]: SignupReducer,
  [HOME_DASHBOARD_NAME]: HomeDashboardReducer,
  [RESET_PASSWORD_NAME]: ResetPasswordReducer,
  [USER_STATE_NAME]: UserProfileRuducer,
  [TOPIC_LIST_NAME]: TopicListReducer,
  [TOPIC_DETAILS_NAME]: TopicListDetailsReducer,
  [COMMUNITY_NAME]: QuestionListReducer,
  [BOOKMARK_NAME]: BookmarkReducer,
  [CONTENT_QUESTIONS_NAME]: ContentQuestionsReducer,
  [SETTING_STATE_NAME]: SettingReducer,
  [SUBSCRIPTION_NAME]: SubscriptionReducer,
  [PROFILE_NAME]: ProfileTopicProgressReducer
};
