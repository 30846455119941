import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { exhaustMap, map } from "rxjs";
import { BookmarkService } from "src/app/services/bookmark.service";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { bookmarkVideoListSuccess, BOOKMARK_VIDEO_LIST_SUBMIT } from "./bookmark.action";

@Injectable()

export class BookmarkEffect {
    constructor(
        private action$: Actions,
        private store: Store<AppState>,
        private bookmarkService: BookmarkService
    ) { }

    bookmarkVideoList$ = createEffect(() => {
        return this.action$.pipe(
            ofType(BOOKMARK_VIDEO_LIST_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.bookmarkService.bookmarkVideoList().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return bookmarkVideoListSuccess(post.response)
                    })
                )
            })
        )
    })
}