import { Action, createReducer, on } from "@ngrx/store";
import { UpdateQuestionViewsFailed } from "src/app/components/community/state/community.action";
import { SubjectListSuccess, TopicListFailed, TopicListSuccess, UnlockSectionFailed, UnlockSectionSuccess, UpadateUnlockSectionSuccess, UpdateSubjectListSuccess } from "./topic-list.action";
import { initialState, TopicListState } from "./topic-list.state";

const _TopicListReducer = createReducer(
    initialState,
    on(TopicListSuccess, (state, action) => {
        return {
            ...state,
            topiclist: action
        }
    }),

    on(TopicListFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
    on(SubjectListSuccess, (state, action) => {
        return {
            ...state,
            subjectList: action
        }
    }),
    on(UpdateSubjectListSuccess, (state, action) => {
        return {
            ...state,
            updateSubjectList: action
        }
    }),

    on(UnlockSectionSuccess, (state, action) => {
        return {
            ...state,
            unlockedSection: action
        }
    }),
    on(UnlockSectionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
    on(UpadateUnlockSectionSuccess, (state, action) => {
        return {
            ...state,
            updateUnlockSection: action,
            unlockedSection:null
        }
    }),
    on(UpdateQuestionViewsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    })
)

export function TopicListReducer(state: TopicListState | undefined, action: Action) {
    return _TopicListReducer(state, action);
}
