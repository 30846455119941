import { Action, createReducer, on } from "@ngrx/store";
import { resetPassFailed, resetPassSuccess, setOtpResetPassword } from "./rest-password.action";
import { initialState, ResetPasswordState } from "./rest-password.state";

const _resetPassReducer = createReducer(
    initialState,
    on(resetPassSuccess, (state, action) => {
        return {
            ...state,
            isAuthorized: true,
        };
    }),
    on(resetPassFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(setOtpResetPassword, (state, action) => {
        return {
            ...state,
            otp: action.otp
        };
    })

);
export function ResetPasswordReducer(state: ResetPasswordState | undefined, action: Action) {
    return _resetPassReducer(state, action)
}

