<div class="download-modal">
    <div class="modal-content">
        <div class="modal-body py-5">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-12 mx-auto">
                        <h2 class="heading font-medium">{{title}}</h2>
                        <label class="plan" for="yes" (click)="deleteComment()">
                            <input type="radio" name="plan" id="yes"/>
                            <div class="plan-content">
                                {{'yes' | i18n: i18nService.currentLanguage}}
                            </div>
                        </label>

                        <label class="plan" for="no" (click)="close()">
                            <input type="radio" name="plan" id="no" />
                            <div class="plan-content">
                                {{'cancel' | i18n: i18nService.currentLanguage}}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>