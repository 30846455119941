import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectCourseModalComponent } from './select-course.modal';

@Injectable({
    providedIn: 'root'
})
export class SelectCourseModalService {
    constructor(private modal: NgbModal) { }
    modelRef: any;
    getModelRef(){
        return this.modelRef;
    }
    open(title:any) {
        let modelRef = this.modal.open(SelectCourseModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
        this.modelRef = modelRef;
        this.modelRef.componentInstance.checkLanguageSelection=title
        return modelRef
    }
}