import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProfileState } from "./profile.state";

export const PROFILE_NAME = 'profile'
const getSharedState = createFeatureSelector<ProfileState>(PROFILE_NAME)

export const getTopicProgress = createSelector(getSharedState , (state)=>{
    return state.topicProgress
})

export const getTestProgress = createSelector(getSharedState , (state)=>{
    return state.testProgress
})

export const getEarning = createSelector(getSharedState , (state)=>{
    return state.earning
})

export const getEditProfile = createSelector(getSharedState , (state)=>{
    return state.editProfile
})

export const getPinnedReply = createSelector(getSharedState , (state)=>{
    return state.pinnedComment
})
