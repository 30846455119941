import { Action, createReducer, on } from "@ngrx/store";
import { changePassSuccess } from "./change-pass.action";
import { ChangePasswordState, initialState } from "./change-pass.state";

const _changePassReducer = createReducer(
    initialState,
    // on(changePassSuccess, (state) => {
    //     return {
    //         ...state,
    //         isAuthorized: true
    //     };
    // })

);
export function ChangePasswordReducer(state: ChangePasswordState | undefined, action: Action) {
    return _changePassReducer(state, action)
}