import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { P404Component } from "./error/p404/p404.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./layout/authorization/authorization.module").then(
        (m) => m.AuthorizationModule
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./layout/full-layout/full-layout.module").then(
        (m) => m.FullLayoutModule
      ),
  },
  {
    path: "fc",
    loadChildren: () =>
      import("./layout/fullscreen-layout/fullscreen-layout.module").then(
        (m) => m.FullScreenLayoutModule
      ),
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
