import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TopicInformation } from "../model/topic-information.model";
import { TopicListDetailsState } from "./topic-list-details.state";

export const TOPIC_DETAILS_NAME = "topic details"


const getSharedState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicDetailsList = createSelector(getSharedState, (state) => {
    return state.topicListDetails
})

const getContentSharedState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicContentDetailsList = createSelector(getContentSharedState, (state) => {
    return state.topicContentListDetails
})

const getSharedTopicTestState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicTest = createSelector(getSharedTopicTestState, (state) => {
    return state.topicTest
})

const getSharedGetTopicTestQuestionState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicTestQuestion = createSelector(getSharedGetTopicTestQuestionState, (state) => {
    return state.getTopicTestQuestion
})



const getSharedSelectTopicListForRevisionState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicListForRevision = createSelector(getSharedSelectTopicListForRevisionState, (state) => {
    return state.selectTopicForRevision
})

const getStartRevisionState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getRevisionStart = createSelector(getStartRevisionState, (state) => {
    return state.startRevision
})

const getFlashState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getFlashcard = createSelector(getFlashState, (state) => {
    return state.getFlashcard
}
)
const getSharedBookmarkVideoState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getBookmarkVideo = createSelector(getSharedBookmarkVideoState, (state) => {
    return state.bookmarkVideo
})
const getSharedTopicAnswerState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);

export const getTopicAnswerSubmit = createSelector(getSharedTopicAnswerState, (state) => {
    return state.topicAnswerSubmit
})


const getSharedResourceState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getResourceList = createSelector(getSharedResourceState, (state) => {
    return state.getResourceList
})

const getSharedSummaryState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getSummaryList = createSelector(getSharedSummaryState, (state) => {
    return state.getSummaryList
})

const getSharedmarkFlashState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const markFlashcard = createSelector(getSharedmarkFlashState, (state) => {
    return state.markFlashcard
})


const getSharedflashcardListState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const flashcardlists = createSelector(getSharedflashcardListState, (state) => {
    return state.flashcardList
})


const getSharedTestRessultState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTestResult = createSelector(getSharedTestRessultState, (state) => {
    return state.testResult
})

const getSharedTopicResultSummery = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicResultSummery = createSelector(getSharedTopicResultSummery, (state) => {
    return state.topicResultSummery
})

const getSharedupdateTopicDuration = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getSharedupdateDuration = createSelector(getSharedupdateTopicDuration, (state) => {
    return state.updateTopicDuration
})

const getSharedisBookmarked = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getSharedisBookmark = createSelector(getSharedisBookmarked, (state) => {
    return state.isBookmarked
})

const getSharedForumList = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getSharedForum = createSelector(getSharedForumList, (state) => {
    return state.forumList
})

const getSharedFaqCommentList = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getFaqCommentList = createSelector(getSharedFaqCommentList, (state) => {
    return state.faqCommentList
})

const getSharedFaqLikeUnlike = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getFaqLikeUnlike = createSelector(getSharedFaqLikeUnlike, (state) => {
    return state.likeunlikefaq
})

const getSharedAddFaqComment = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getFaqcomment = createSelector(getSharedAddFaqComment, (state) => {
    return state.addfaqcomment
})

const getSharedAddFaqQuestion = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getFaqquestion = createSelector(getSharedAddFaqQuestion, (state) => {
    return state.addfaqquestion

})


const getSharedListPinnedState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const listsPinnedcomments = createSelector(getSharedListPinnedState, (state) => {
    return state.listpinnedcomments
})

const getSharedtopicTestPreviousState = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const topicTestPreviousState = createSelector(getSharedtopicTestPreviousState, (state) => {
    return state.topicPreviousAnswerSubmit
})

const topicInformation = createFeatureSelector<TopicListDetailsState>(TOPIC_DETAILS_NAME);
export const getTopicInformation = createSelector(topicInformation, (state) => {
    return state.selectedTopicInformation
})