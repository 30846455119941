import { Action, createReducer, on } from "@ngrx/store";
import { autoLogin, loginFailed, loginSuccess } from "./login.action";
import { initialState, LoginState } from "./login.state";

const _loginReducer = createReducer(
    initialState,
    on(loginSuccess, (state, action) => {
        return {
            ...state,
            authToken: action.token,
            isAuthorized: true
        };
    }),
    on(loginFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            errorMessage: action.message
        };
    }),
    on(autoLogin, (state, action) => {
        return {
            ...state,
            authToken: action.token,
            isAuthorized: true,
        };
    })
);


export function LoginReducer(state: LoginState | undefined, action: Action) {
    return _loginReducer(state, action);
}

