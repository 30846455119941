import { Action, createReducer, on } from "@ngrx/store";
import {
  fetchVideoHeaderSuccess,
  setErrorMessage,
  setLoadingSpinner,
  setSuccessMessage,
  setNavigationLocation,
} from "./shared.actions";
import { initialState, SharedState } from "./shared.state";

const _sharedReducer = createReducer(
  initialState,
  on(setLoadingSpinner, (state, action) => {
    return {
      ...state,
      showLoading: action.status,
    };
  }),
  on(setErrorMessage, (state, action) => {
    return {
      ...state,
      errorMessage: action.message,
    };
  }),
  on(setNavigationLocation, (state, action) => {
    return {
      ...state,
      navigationLocation: action.navigationLocation,
    };
  }),
  on(setSuccessMessage, (state, action) => {
    return {
      ...state,
      successMessage: action.message,
    };
  }),
  on(fetchVideoHeaderSuccess, (state, action) => {
    return {
      ...state,
      videoHeaders: {
        stream_key: action.body.stream_key,
        stream_policy: action.body.stream_policy,
      },
    };
  })
);

export function SharedReducer(state: SharedState | undefined, action: Action) {
  return _sharedReducer(state, action);
}
