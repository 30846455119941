import { Action, createReducer, on } from "@ngrx/store";
import { getGradesSuccess, OtpFailed, OtpSuccess, SetOtpEmailID, SetOtpParentComponent } from "./otp.action";
import { initialState, OtpState } from "./otp.state";

const _otpReducer = createReducer(
    initialState,
    on(OtpSuccess, (state, action) => {
        return {
            ...state,
            authToken: action.token,
            isAuthorized: true,
            otpSuccess:action
        };
    }),
    on(OtpFailed, (state, action) => {
        return {
            ...state,
            authToken: '',
            isAuthorized: false,
            otpMessage: action.response
        };
    }),
    on(SetOtpParentComponent, (state, action) => {
        return {
            ...state,
            parentComponent: action.component
        };
    }),
    on(SetOtpEmailID, (state, action) => {      
        return {
            ...state,
            email_id: action.phone_number
        };
    }),
    on(getGradesSuccess, (state, action) => {
        return {
            ...state,
            grades: action.response.grades
        };
    }),
)

export function OtpReducer(state: OtpState | undefined, action: Action) {
    return _otpReducer(state, action);
}
