import { createAction, props } from "@ngrx/store"
import { ApiResponse } from "src/app/models/api.response.model"
import { addFaqCommentRequest } from "../model/request/add-faq-comment.request"
import { AddFaqQuestion } from "../model/request/add-faq-question.request"
import { BookmarkVideoRequset } from "../model/request/bookmark-video.request"
import { FaqCommentListRequest } from "../model/request/faq-comment-list.request"
import { flashcardListRequest } from "../model/request/flashcard-list.request"
import { ForumRequest } from "../model/request/forum.request"
import { getFlashcardRequest } from "../model/request/getFlashcard.request"
import { getResourceRequest } from "../model/request/getResources.request"
import { getSummaryRequest } from "../model/request/getSummary.request"
import { GetTopicTestQuestionRequest } from "../model/request/getTopic-test-questions.request"
import { isBookmarkRequest } from "../model/request/isBookMarked.request"
import { LikeUnlikeFaqRequest } from "../model/request/like-unlike-faq.request"
import { markFlashcardRequest } from "../model/request/mark-flashcard.request"
import { StartRevisionRequest } from "../model/request/start-revision.request"
import { TopicAnswerSubmitRequest } from "../model/request/submit-topic-answer.request"
import { TestResultRequest } from "../model/request/test-result.request"
import { TopicContentListDetailsResponse } from "../model/request/topic-content-list.response"
import { TopicListDetailsRequest } from "../model/request/topic-list-details.request"
import { TopicTestRequset } from "../model/request/topic-test.request"
import { updateTopicDurationRequest } from "../model/request/updateUserTopicDuration.request"
import { addFaqCommentResponse } from "../model/response/add-faq-comment.response"
import { AddFaqQuestionResponse } from "../model/response/add-faq-question.response"
import { BookmarkVideoResponse } from "../model/response/bookmark-video.response"
import { FaqCommentListResponse } from "../model/response/faq-comment-list.response"
import { flashcardListResponse } from "../model/response/flashcard-list.response"
import { ForumResponse } from "../model/response/forum.response"
import { getFlashcardResponse } from "../model/response/getFlashcard.response"
import { getResourceResponse } from "../model/response/getResource.response"
import { getSummaryResponse } from "../model/response/getSummary.response"
import { GetTopicTestQuestionResponse } from "../model/response/getTopic-test-questions.response"
import { isBookmarkedResponse } from "../model/response/isBookMarked.response"
import { LikeUnlikeFaqResponse } from "../model/response/like-unlike-faq-response"
import { markFlashcardResponse } from "../model/response/mark-flashcard.response"
import { ListPinnedCommentsResponse } from "../model/response/pinned-list.response"
import { SelectTopicListForRevision } from "../model/response/select-topic-list-for-revision.response"
import { StartRevisionResponse } from "../model/response/start-revision.response"
import { TopicAnswerSubmitResponse } from "../model/response/submit-topic-answer.response"
import { TestResultResponse } from "../model/response/test-result.response"
import { TopicListDetailsResponse } from "../model/response/topic-list-details.response"
import { TopicResultSummeryResponse } from "../model/response/topic-result-summery.response"
import { TopicTestResponse } from "../model/response/topic-test.response"
import { updateTopicDurationResponse } from "../model/response/updateUserTopicDuration.response"
import { TopicInformation } from "../model/topic-information.model"


export const TOPIC_LIST_DETAILS_SUBMIT = '[topic list details page] topic list details submit'
export const TOPIC_LIST_DETAILS_SUCCESS = '[topic list details page] topic list details success'
export const TOPIC_LIST_DETAILS_FAILED = '[topic list details page] topic list details failed'

export const TOPIC_CONTENT_LIST_DETAILS_SUBMIT = '[topic content list details page] topic content list details submit'
export const TOPIC_CONTENT_LIST_DETAILS_SUCCESS = '[topic content list details page] topic content list details success'
export const TOPIC_CONTENT_LIST_DETAILS_FAILED = '[topic content list details page] topic content list details failed'

export const TOPIC_TEST_SUBMIT = '[topic-test-page] topic test submit'
export const TOPIC_TEST_SUCCESS = '[topic-test-page] topic test success'
export const TOPIC_TEST_FAILED = '[topic-test-page] topic test failed'

export const GET_TOPIC_TEST_QUESTION_SUBMIT = '[get-topic-test-question-page] topic get question submit'
export const GET_TOPIC_TEST_QUESTION_SUCCESS = '[get-topic-test-question-page] topic get question success'
export const GET_TOPIC_TEST_QUESTION_FAILED = '[get-topic-test-question-page] topic get question failed'

export const GET_TOPIC_PREVIOUS_ANSWER_SUBMIT = '[get-topic-test-previous-question-page] topic get previous question submit'
export const GET_TOPIC_PREVIOUS_ANSWER_SUCCESS = '[get-topic-test-previous-question-page] topic get previous question success'
export const GET_TOPIC_PREVIOUS_ANSWER_FAILED = '[get-topic-test-previous-question-page] topic get previous question failed'

export const SELECT_TOPIC_LIST_FOR_REVISION_SUBMIT = '[select-topic-list-for-revision] select topic list for revision submit'
export const SELECT_TOPIC_LIST_FOR_REVISION_SUCCESS = '[select-topic-list-for-revision] select topic list for revision success'
export const SELECT_TOPIC_LIST_FOR_REVISION_FAILED = '[select-topic-list-for-revision] select topic list for revision failed'

export const START_REVISION_SUBMIT = '[start-revision] start revision submit'
export const START_REVISION_SUCCESS = '[start-revision] start revision success'
export const START_REVISION_FAILED = '[start-revision] start revision failed'

export const FLASHCARD_SUBMIT = '[ flash card page ] flash card submit'
export const FLASHCARD_SUCCESS = '[ flash card page ] flash card success'
export const FLASHCARD_FAILED = '[ flash card page ] flash card failed'

export const BOOKMARK_VIDEO_SUBMIT = '[bookmark-page] bookmark test submit'
export const BOOKMARK_VIDEO_SUCCESS = '[bookmark-page] bookmark test success'
export const BOOKMARK_VIDEO_FAILED = '[bookmark-page] bookmark test failed'
export const SET_IS_BROOKMARK_NULL = '[bookmark-page] bookmark test null'

export const TOPIC_ANSWER_SUBMIT = '[topic-answer-page] topic answer submit'
export const TOPIC_ANSWER_SUCCESS = '[topic-answer-page] topic answer success'
export const TOPIC_ANSWER_FAILED = '[topic-answer-page] topic answer failed'

export const RESOURCE_LIST_SUBMIT = '[resource-list-page] resource list submit'
export const RESOURCE_LIST_SUCCESS = '[resource-list-page] resource list success'
export const RESOURCE_LIST_FAILED = '[resource-list-page] resource list failed'

export const SUMMARY_LIST_SUBMIT = '[summary-list-page] summary list submit'
export const SUMMARY_LIST_SUCCESS = '[summary-list-page] summary list success'
export const SUMMARY_LIST_FAILED = '[summary-list-page] summary list failed'

export const MARK_FLASHCARD_SUBMIT = '[ mark flash card page ] mark flash card submit'
export const MARK_FLASHCARD_SUCCESS = '[ mark flash card page ] mark flash card success'
export const MARK_FLASHCARD_FAILED = '[ mark flash card page ] mark flash card failed'

export const FLASHCARD_LIST_SUBMIT = '[ flash card list page ] flash card list submit'
export const FLASHCARD_LIST_SUCCESS = '[ flash card list page ] flash card list success'
export const FLASHCARD_LIST_FAILED = '[ flash card list page ] flash card list failed'

export const TEST_RESULT_SUBMIT = '[ test result list page ] test result list submit'
export const TEST_RESULT_SUCCESS = '[ test result list page ] test result list success'
export const TEST_RESULT_FAILED = '[ test result list page ] test result list failed'

export const TEST_RESULT_SUMMERY_SUBMIT = '[ test result summery list page ] test result summery list submit'
export const TEST_RESULT_SUMMERY_SUCCESS = '[ test result summery list page ] test result summery list success'
export const TEST_RESULT_SUMMERY_FAILED = '[ test result summery list page ] test result summery list failed'

export const UPDATE_DURATION_SUBMIT = '[ update duration page ] update duration submit'
export const UPDATE_DURATION_SUCCESS = '[ update duration page ] update duration success'
export const UPDATE_DURATION_FAILED = '[ update duration page ] update duration failed'

export const IS_BOOKMARKED_SUBMIT = '[  isnookmark page ]  isnookmark submit'
export const IS_BOOKMARKED_SUCCESS = '[  isnookmark page ]  isnookmark success'
export const IS_BOOKMARKED_FAILED = '[  isnookmark page ]  isnookmark failed'

export const FORUM_SUBMIT = '[  forum page ]  forum submit'
export const FORUM_SUCCESS = '[  forum page ]  forum success'
export const FORUM_FAILED = '[  forum page ]  forum failed'

export const SET_TOPIC_TEST_NULL = '[ topic test ]  Set topic test null'
export const SET_TOPIC_DETAILS_NULL = '[ topic details ]  Set topic details null'

export const FAQ_COMMENT_LIST_SUBMIT = '[  faq comment list page ]  faq comment list submit'
export const FAQ_COMMENT_LIST_SUCCESS = '[  faq comment list  page ]  faq comment list success'
export const FAQ_COMMENT_LIST_FAILED = '[  faq comment list page ]  faq comment list failed'

export const LIKE_UNLIKE_FAQ_SUBMIT = '[  like unlike faq page ]  like unlike faq submit'
export const LIKE_UNLIKE_FAQ_SUCCESS = '[  like unlike faq page ]  like unlike faq success'
export const LIKE_UNLIKE_FAQ_FAILED = '[  like unlike faq page ]  like unlike faq failed'

export const ADD_FAQ_COMMENT_SUBMIT = '[  add faq comment page ]  add faq comment submit'
export const ADD_FAQ_COMMENT_SUCCESS = '[  add faq comment page ]  add faq comment success'
export const ADD_FAQ_COMMENT_FAILED = '[  add faq comment page ]  add faq comment failed'

export const ADD_FAQ_QUESTION_SUBMIT = '[  add faq question page ]  add faq question submit'
export const ADD_FAQ_QUESTION_SUCCESS = '[  add faq question page ]  add faq question success'
export const ADD_FAQ_QUESTION_FAILED = '[  add faq question page ]  add faq question failed'

export const FORUM_QUESTION_UPDATED_LIST = '[ forum questions update  page]'



export const LIST_PINNED_COMMENTS_SUBMIT = '[  list pinned comments page ]  list pinned comments page  submit'
export const LIST_PINNED_COMMENTS_SUCCESS = '[ list pinned comments page  ]  list pinned comments page  success'
export const LIST_PINNED_COMMENTS_FAILED = '[  list pinned comments page  ]  list pinned comments page  failed'

export const SET_SELECT_TOPIC_INFORMATION = '[  topic selection  ]  topic selection'
export const SKIP_ONBOARDING = '[ flash card ] skip on boarding'
export const TopicListDetailsSubmit = createAction(
    TOPIC_LIST_DETAILS_SUBMIT,
    props<TopicListDetailsRequest>()
);
export const TopicListDetailsSuccess = createAction(
    TOPIC_LIST_DETAILS_SUCCESS,
    props<TopicListDetailsResponse>()
);
export const TopicListDetailsFailed = createAction(
    TOPIC_LIST_DETAILS_FAILED,
    props<ApiResponse<null>>()
);


export const TopicContentListDetailsSubmit = createAction(
    TOPIC_CONTENT_LIST_DETAILS_SUBMIT,
    props<TopicListDetailsRequest>()
);
export const TopicContentListDetailsSuccess = createAction(
    TOPIC_CONTENT_LIST_DETAILS_SUCCESS,
    props<TopicContentListDetailsResponse>()
);
export const TopicContentListDetailsFailed = createAction(
    TOPIC_CONTENT_LIST_DETAILS_FAILED,
    props<ApiResponse<null>>()
);

export const TopicTestSubmit = createAction(
    TOPIC_TEST_SUBMIT,
    props<TopicTestRequset>()
);
export const TopicTestSuccess = createAction(
    TOPIC_TEST_SUCCESS,
    props<TopicTestResponse>()
)
export const TopicTestFailed = createAction(
    TOPIC_TEST_FAILED,
    props<ApiResponse<null>>()
);

export const GetTopicTestQuestionSubmit = createAction(
    GET_TOPIC_TEST_QUESTION_SUBMIT,
    props<GetTopicTestQuestionRequest>()
);
export const GetTopicTestQuestionSuccess = createAction(
    GET_TOPIC_TEST_QUESTION_SUCCESS,
    props<GetTopicTestQuestionResponse>()
);
export const GetTopicTestQuestionFailed = createAction(
    GET_TOPIC_TEST_QUESTION_FAILED,
    props<ApiResponse<null>>()
)

export const TopicTestAnswerSubmit = createAction(
    TOPIC_ANSWER_SUBMIT,
    props<TopicAnswerSubmitRequest>()
)
export const TopicTestAnswerResponseSuccess = createAction(
    TOPIC_ANSWER_SUCCESS,
    props<TopicAnswerSubmitResponse>()
)
export const TopicTestAnswerSubmitFailed = createAction(
    TOPIC_ANSWER_FAILED,
    props<ApiResponse<null>>()
)

export const TopicTestPreviousAnswerSubmit = createAction(
    GET_TOPIC_PREVIOUS_ANSWER_SUBMIT,
    props<TopicAnswerSubmitRequest>()
)
export const TopicTestPreviousAnswerSuccess = createAction(
    GET_TOPIC_PREVIOUS_ANSWER_SUCCESS,
    props<TopicAnswerSubmitResponse>()
)
export const TopicTestPreviousAnswerFailed = createAction(
    GET_TOPIC_PREVIOUS_ANSWER_FAILED,
    props<ApiResponse<null>>()
)

export const SelectTopicListForRevisionSubmit = createAction(
    SELECT_TOPIC_LIST_FOR_REVISION_SUBMIT,
)
export const SelectTopicListForRevisionSuccess = createAction(
    SELECT_TOPIC_LIST_FOR_REVISION_SUCCESS,
    props<SelectTopicListForRevision>()
)
export const SelectTopicListForRevisionFailed = createAction(
    SELECT_TOPIC_LIST_FOR_REVISION_FAILED,
    props<ApiResponse<null>>()
)

export const StartRevisionSubmit = createAction(
    START_REVISION_SUBMIT,
    props<StartRevisionRequest>()
)
export const StartRevisionSuccess = createAction(
    START_REVISION_SUCCESS,
    props<StartRevisionResponse>()
)
export const StartRevisionFailed = createAction(
    START_REVISION_FAILED,
    props<ApiResponse<null>>()
);


export const flashCardSubmit = createAction(
    FLASHCARD_SUBMIT,
    props<getFlashcardRequest>()
);

export const flashCardSuccess = createAction(
    FLASHCARD_SUCCESS,
    props<getFlashcardResponse>()
);

export const flashCardFailed = createAction(
    FLASHCARD_FAILED,
    props<ApiResponse<null>>()
);
export const BookmarkVideoSubmit = createAction(
    BOOKMARK_VIDEO_SUBMIT,
    props<BookmarkVideoRequset>()
)
export const BookmarkVideoSuccess = createAction(
    BOOKMARK_VIDEO_SUCCESS,
    props<BookmarkVideoResponse>()
)
export const BookmarkVideoFailed = createAction(
    BOOKMARK_VIDEO_FAILED,
)
export const TopicAnswerSubmit = createAction(
    TOPIC_ANSWER_SUBMIT,
    props<TopicAnswerSubmitRequest>()
)
export const TopicAnswerSuccess = createAction(
    TOPIC_ANSWER_SUCCESS,
    props<TopicAnswerSubmitResponse>()
)
export const TopicAnswerFailed = createAction(
    TOPIC_ANSWER_FAILED,
    props<ApiResponse<null>>()
);

export const ResourceListSubmit = createAction(
    RESOURCE_LIST_SUBMIT,
    props<getResourceRequest>()
)
export const ResourceListSuccess = createAction(
    RESOURCE_LIST_SUCCESS,
    props<getResourceResponse>()
)
export const ResourceListFailed = createAction(
    RESOURCE_LIST_FAILED,
    props<ApiResponse<null>>()
);


export const SummaryListSubmit = createAction(
    SUMMARY_LIST_SUBMIT,
    props<getSummaryRequest>()
)
export const SummaryListSuccess = createAction(
    SUMMARY_LIST_SUCCESS,
    props<getSummaryResponse>()
)
export const SummaryListFailed = createAction(
    SUMMARY_LIST_FAILED,
    props<ApiResponse<null>>()
);


export const markFlashcardSubmit = createAction(
    MARK_FLASHCARD_SUBMIT,
    props<markFlashcardRequest>()
)

export const markFlashcardSuccess = createAction(
    MARK_FLASHCARD_SUCCESS,
    props<markFlashcardResponse>()
)

export const markFlashcardFailed = createAction(
    MARK_FLASHCARD_FAILED,
    props<ApiResponse<null>>()
)

export const flashcardListSubmit = createAction(
    FLASHCARD_LIST_SUBMIT,
    props<flashcardListRequest>()
)

export const flashcardListSuccess = createAction(
    FLASHCARD_LIST_SUCCESS,
    props<flashcardListResponse>()
)

export const flashcardListFailed = createAction(
    FLASHCARD_LIST_FAILED,
    props<ApiResponse<null>>()
)

export const testResultSubmit = createAction(
    TEST_RESULT_SUBMIT,
    props<TestResultRequest>()
)

export const testResultSuccess = createAction(
    TEST_RESULT_SUCCESS,
    props<TestResultResponse>()
)

export const testResultFailed = createAction(
    TEST_RESULT_FAILED,
    props<ApiResponse<null>>()
)


export const topicResultSummerySubmit = createAction(
    TEST_RESULT_SUMMERY_SUBMIT,
    props<TestResultRequest>()
)

export const topicResultSummerySuccess = createAction(
    TEST_RESULT_SUMMERY_SUCCESS,
    props<TopicResultSummeryResponse>()
)

export const topicResultSummeryFailed = createAction(
    TEST_RESULT_SUMMERY_FAILED,
    props<ApiResponse<null>>()
)

export const updateTopicDurationSubmit = createAction(
    UPDATE_DURATION_SUBMIT,
    props<updateTopicDurationRequest>()
)

export const updateTopicDurationSuccess = createAction(
    UPDATE_DURATION_SUCCESS,
    props<updateTopicDurationResponse>()
)

export const updateTopicDurationFailed = createAction(
    UPDATE_DURATION_FAILED,
    props<ApiResponse<null>>()
)


export const isBookmarkSubmit = createAction(
    IS_BOOKMARKED_SUBMIT,
    props<isBookmarkRequest>()
)

export const isBookmarkSuccess = createAction(
    IS_BOOKMARKED_SUCCESS,
    props<isBookmarkedResponse>()
)

export const isBookmarkFailed = createAction(
    IS_BOOKMARKED_FAILED,
    props<ApiResponse<null>>()
)
export const isBookmarkNull = createAction(
    SET_IS_BROOKMARK_NULL
)

export const forumSubmit = createAction(
    FORUM_SUBMIT,
    props<ForumRequest>()
)

export const forumSuccess = createAction(
    FORUM_SUCCESS,
    props<ForumResponse>()
)

export const forumFailed = createAction(
    FORUM_FAILED,
    props<ApiResponse<null>>()
)

export const setTopicTestNull = createAction(
    SET_TOPIC_TEST_NULL
)
export const setTopicDetailsNull = createAction(
    SET_TOPIC_DETAILS_NULL
)

export const faqCommentSubmit = createAction(
    FAQ_COMMENT_LIST_SUBMIT,
    props<FaqCommentListRequest>()
)

export const faqCommentSuccess = createAction(
    FAQ_COMMENT_LIST_SUCCESS,
    props<FaqCommentListResponse>()
)

export const faqCommentFailed = createAction(
    FAQ_COMMENT_LIST_FAILED,
    props<ApiResponse<null>>()
)

export const likeunliefaqSubmit = createAction(
    LIKE_UNLIKE_FAQ_SUBMIT,
    props<LikeUnlikeFaqRequest>()
)

export const likeunliefaqSuccess = createAction(
    LIKE_UNLIKE_FAQ_SUCCESS,
    props<LikeUnlikeFaqResponse>()
)

export const likeunliefaqFailed = createAction(
    LIKE_UNLIKE_FAQ_FAILED,
    props<ApiResponse<null>>()
)

export const addfaqcommentSubmit = createAction(
    ADD_FAQ_COMMENT_SUBMIT,
    props<addFaqCommentRequest>()
)

export const addfaqcommentSuccess = createAction(
    ADD_FAQ_COMMENT_SUCCESS,
    props<addFaqCommentResponse>()
)

export const addfaqcommentFailed = createAction(
    ADD_FAQ_COMMENT_FAILED,
    props<ApiResponse<null>>()
)

export const addfaqquestionSubmit = createAction(
    ADD_FAQ_QUESTION_SUBMIT,
    props<AddFaqQuestion>()
)

export const addfaqquestionSuccess = createAction(
    ADD_FAQ_QUESTION_SUCCESS,
    props<AddFaqQuestionResponse>()
)

export const addfaqquestionFailed = createAction(
    ADD_FAQ_QUESTION_FAILED,
    props<ApiResponse<null>>()
)


export const forumQuestionsUpdateList = createAction(
    FORUM_QUESTION_UPDATED_LIST,
    props<AddFaqQuestion>()
)




export const listPinnedCommentsSubmit = createAction(
    LIST_PINNED_COMMENTS_SUBMIT,

)

export const listPinnedCommentsSuccess = createAction(
    LIST_PINNED_COMMENTS_SUCCESS,
    props<ListPinnedCommentsResponse>()
)


export const listPinnedCommentsFailed = createAction(
    LIST_PINNED_COMMENTS_FAILED,
    props<ApiResponse<null>>()
)

export const setSelectedTopicInformation = createAction(
    SET_SELECT_TOPIC_INFORMATION,
    props<TopicInformation>()
)

export const skipOnboarding = createAction(
    SKIP_ONBOARDING,
    props<any>()
)