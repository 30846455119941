import { Action, createReducer, on } from "@ngrx/store";
import { aboutFailed, aboutSuccess, contactUsfailed, contactUsSuccess, privacyPolicyfailed, privacyPolicySuccess, termsCondtionFailed, termsCondtionSuccess } from "./setting.action";
import { initialState, SettingState } from "./setting.state";

const _settingReducer = createReducer(initialState,
    on(aboutSuccess, (state, action) => {
        return {
            ...state,
            aboutUs: action
        }
    }),
    on(aboutFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),

    on(termsCondtionSuccess, (state, action) => {
        return {
            ...state,
            termsCondition: action
        }
    }),
    on(termsCondtionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),

    on(privacyPolicySuccess, (state, action) => {
        return {
            ...state,
            privacyPolicy: action
        }
    }),

    on(privacyPolicyfailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),

    on(contactUsSuccess, (state, action) => {
        return {
            ...state,
            contactUs: action
        }
    }),

    on(contactUsfailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
)

export function SettingReducer(state: SettingState | undefined, action: Action) {
    return _settingReducer(state, action)
}

