import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { BookmarkVideoResponse } from "../components/bookmark/models/bookmark-video.response";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable(
    {
        providedIn: "root"
    }
)

export class BookmarkService {
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }

    bookmarkVideoList(): Observable<ApiResponse<BookmarkVideoResponse>> {
        return from(this.apiService.post(
            'user/bookmarked-videos',
            {}
        ))
    }



}