import { Country } from "../../phone-number-verification/models/country-list.response";

export interface SignupState{
    errorMessage: string;
    isAuthorized: boolean;
    authToken: string;
    country:Country | null;
    phone:any |null;
}

export const initialState: SignupState = {
    isAuthorized: false,
    errorMessage: '',
    authToken: '',
    country:null,
    phone:''
};