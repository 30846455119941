import { CommentOnQuestionResponse } from "../models/response/comment-on-question.response";
import { QuestionLikeUnlikeResponse } from "../models/response/question-like-unlike.response";
import { questionListResponse } from "../models/response/question-list.response";
import { QuestionRepliesResponse } from "../models/response/question-replies.response";
import { SubmitQuestionResponse } from "../models/response/submit-question.response";
import { UpdateQuestionViewsResponse } from "../models/response/update-question-views.response";
export interface QuestionListState {
    questionList: questionListResponse | null
    commentOnQuestion: CommentOnQuestionResponse | null
    questionLikeUnlike: QuestionLikeUnlikeResponse | null
    questionReplies: QuestionRepliesResponse | null
    submitQuestion: SubmitQuestionResponse | null
    updateQuestionView: UpdateQuestionViewsResponse | null
}

export const initialState: QuestionListState = {
    questionList: null,
    commentOnQuestion: null,
    questionLikeUnlike: null,
    questionReplies: null,
    submitQuestion: null,
    updateQuestionView: null,
}