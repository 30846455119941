import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ForgetState } from "./forget.state";

export const FORGET_STATE_NAME = 'forget-password';
const getSharedState = createFeatureSelector<ForgetState>(FORGET_STATE_NAME);

export const getForgetErrorMessage = createSelector(getSharedState, (state) => {
    return state.errorMessage
});



