import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SubscriptionState } from "./subscribe.state";

export const SUBSCRIPTION_NAME = 'subscription list'

const getubscriptionState = createFeatureSelector<SubscriptionState>(SUBSCRIPTION_NAME);
export const getCancelSubscriptionList = createSelector(getubscriptionState, (state) => {
    return state.CancelSubscriptionList
})
export const getMasterSubscriptionList = createSelector(getubscriptionState, (state) => {
    return state.MasterSubscriptionList
})

export const getPurchaseSubscriptionList = createSelector(getubscriptionState, (state) => {
    return state.PurchaseSubscription
})

export const getMySubscriptionList = createSelector(getubscriptionState, (state) => {
    return state.MySubscriptionList
})
