import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { AuthorizationService } from "src/app/services/authorization.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { resetPasswordRequest } from "../models/reset-password.request";
import { resetPassFailed, resetPassSuccess, RESET_PASSWORD_SUBMIT } from "./rest-password.action";



@Injectable()
export class ResetPasswordEffet {
    constructor(private actions$: Actions,
        private store: Store<AppState>,
        private router: Router,
        private authService: AuthorizationService,private localStorage:LocalStorageService) { }
    startSurvey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RESET_PASSWORD_SUBMIT),
            exhaustMap((id: resetPasswordRequest) => {
                let req: resetPasswordRequest = {
                    changeBy: id.changeBy,
                    new_password: id.new_password,
                    otp: id.otp
                }
                return this.authService.resetPassword(req).pipe(
                    map((post) => {
                        this.localStorage.showMessage("Mot de passe réinitialisé","Password reset successfully")
                        this.router.navigate(['auth/pre-signup'])
                        return resetPassSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(resetPassFailed(errResp.error));
                    })
                );
            })
        );
    });

}




