import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "src/app/models/api.response.model";
import { DashboardResponse } from "../models/home-dash.response";
export const HOME_DASHBOARD_SUBMIT = '[home-dashboard page] home dashboard submit';
export const HOME_DASHBOARD_SUCCESS = '[home-dashboard page] home dashboard success';
export const HOME_DASHBOARD_FAILED = '[home-dashboard page ] home dashboard failed';
export const HOME_DASHBOARD_UPDATE_FLAG = '[home-dashboard page] home dashboard flag updated';
export const homeDashboardSubmit = createAction(
    HOME_DASHBOARD_SUBMIT
);

export const homeDashboardSuccess = createAction(
    HOME_DASHBOARD_SUCCESS,
    props<DashboardResponse>()
);

export const homeDashboardFlagUpdate = createAction(
    HOME_DASHBOARD_UPDATE_FLAG,
    props<DashboardResponse>()
);

export const homeDashboardFailed = createAction(
    HOME_DASHBOARD_FAILED,
    props<ApiResponse<null>>()
);