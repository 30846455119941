import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { DashboardResponse } from "../components/dashboard/home-dashboard/models/home-dash.response";
import { ApiResponse } from "../models/api.response.model";
import { UserProfileResponse } from "../shared/header/models/user-profile-modal-response";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    timeoutInterval: any;
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }

    homeDashboard(): Observable<ApiResponse<DashboardResponse>> {
        return from(this.apiService.post(
            'user/dashboard',
            {}
        ));
    };
    userProfile(): Observable<ApiResponse<UserProfileResponse>> {
        return from(this.apiService.get(
            'user/my-profile',
        ));
    };
    courseSelect(HomedashReq: DashboardResponse): Observable<ApiResponse<DashboardResponse>> {
        return from(this.apiService.post(
            '',
            HomedashReq
        ));
    }


}