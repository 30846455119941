<div class="app flex-row align-items-center">
    <div class="container align-items-center">
        <div class="row justify-content-center align-items-center" style="min-height: 80vh; ">
            <div class="col-md-6 ">
                <div class="clearfix ">
                    <h1 class="float-left display-3 mr-4 ">404</h1>
                    <h4 class="pt-3 ">Oops! You're lost.</h4>
                    <p class="text-muted ">The page you are looking for was not found.</p>
                </div>
            </div>
        </div>
    </div>
</div>