import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CryptoService } from "./crypto.service";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  encPassword: string = environment.cryptoPass;

  constructor(private EncrDecr: CryptoService) {}

  public async encrypt(id: any) {
    let encrpt:any = await this.EncrDecr.encryptf(id + "", this.encPassword);
    if (encrpt) {
      encrpt = encrpt
        .toString()
        .replaceAll("+", "xMl3Jk")
        .replaceAll("/", "Por21Ld")
        .replaceAll("=", "Ml32");
      return encrpt;
    }
  }
  public async decrypt(id: any) {
    if (id) {
      id = (id?.toString() as any)
        .replaceAll("xMl3Jk", "+")
        .replaceAll("Por21Ld", "/")
        .replaceAll("Ml32", "=");
      let decrypt:any = await this.EncrDecr.decryptf(id, this.encPassword);
      return decrypt;
    }
  }
}
