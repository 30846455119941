import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/services/local.storage.service";

@Injectable()
export class AuthGuardService {
  cookieValue: any;
  constructor(
    private router: Router,
    private localStorage: LocalStorageService
  ) {}
  canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.localStorage.getValue("studeoWeb")) {
        resolve(true);
      } else {
        this.router.navigate(["/auth/pre-signup"]);
        resolve(false);
        setTimeout(() => {}, 1);
      }
    });
  }
}
