import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "src/app/models/api.response.model";
import { changePasswordRequest } from "../models/change-password.request";
import { changePasswordResponse } from "../models/change-password.response";

export const CHANGE_PASSWORD_SUBMIT = '[change-password page] change password submit';
export const CHANGE_PASSWORD_SUCCESS = '[change-password page] change password success';
export const CHANGE_PASSWORD_FAILED = '[change-password page] change password failed';

export const changePassSubmit = createAction(
    CHANGE_PASSWORD_SUBMIT,
    props<changePasswordRequest>()
);

export const changePassSuccess = createAction(
    CHANGE_PASSWORD_SUCCESS,
    props<changePasswordResponse>()
);
export const changePassFailed = createAction(
    CHANGE_PASSWORD_FAILED,
    props<ApiResponse<null>>()
);