import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SegmentService } from 'ngx-segment-analytics';
import { GradeYear } from 'src/app/components/authorization/otp/model/grade-year.model';
import { Grade } from 'src/app/components/authorization/otp/model/grade.model';
import { CourseSelectSubmit } from 'src/app/components/authorization/otp/state/otp.action';
import { getGrades } from 'src/app/components/authorization/otp/state/otp.selecter';
import { LocalStorageService } from 'src/app/services/local.storage.service';
import { getUserProfile } from 'src/app/shared/header/state/header.selector';
import { AppState } from 'src/app/store/app.state';
declare var $: any;
@Component({
  selector: 'app-select-course-modal',
  templateUrl: './select-course.modal.html',
  styleUrls: ['./select-course.modal.scss']
})
export class SelectCourseModalComponent implements OnInit {
  public grade_id: number = 1
  @Input() checkLanguageSelection!:string;
  public grades: Grade[] = [];
  userData:any;
  current_grade_year:any=null;
  firstTimeGradeSelection:any;
  isShowOtherGrade:boolean=false;
  constructor(private store: Store<AppState>,private modal: NgbModal, public activeModal: NgbActiveModal,private segment:SegmentService, public localStorage:LocalStorageService,
    ) { }
  ngOnInit(): void {
    this.store.select(getGrades).subscribe((res) => {
      this.grades = res; 
    })
    this.store.select(getUserProfile).subscribe(async (res) => {
      if (res) {
        this.userData = res;
        this.current_grade_year=this.userData.grade_year
      }
    })
    if (localStorage.getItem("selectedGrade")) {
      this.isShowOtherGrade=true;
      this.firstTimeGradeSelection = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem("selectedGrade"))));
    }
  }
  onGradeSelect(year: GradeYear, grade: Grade) {
        let req = {
          grade_year_id: year.grade_year_id,
          grade_id: grade.grade_id,
          language_id: this.localStorage.checkLanguage() ? "2" : "1",
        };
    if(this.checkLanguageSelection!=(this.localStorage.checkLanguage()?'2':'1')){
      if(this.localStorage.checkLanguage()){
        localStorage.setItem('lang', 'EN');
        req.language_id='1'
      }
      else{
        localStorage.setItem('lang', 'FR');
        req.language_id='2'
      }
    }
    setTimeout(() => {
      localStorage.removeItem("selectedGrade")
      localStorage.setItem("selectedGradeId",grade.grade_id)
      this.store.dispatch(CourseSelectSubmit(req))
      //this.segment.track('Select Course',{course:year.grade_year,grade_id:this.grade_id})
      this.activeModal.close(true)
    }, 500);
  }
  close() {
    if(this.checkLanguageSelection!=(this.localStorage.checkLanguage()?'2':'1')){
      $("#exampleModalCenter").modal('show');
    }
    else{
      this.activeModal.close(true)
    }
  }
  closeModal(){
    this.activeModal.close(true)
    $("#exampleModalCenter").modal('hide');
    location.reload();
  }
  continue(){
    $("#exampleModalCenter").modal('hide');
  }
}