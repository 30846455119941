import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { EmailSendModalService } from "src/app/modals/email-sent-modal/email-send.modal.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { SettingService } from "src/app/services/setting.service";
import { setErrorMessage, setLoadingSpinner, setSuccessMessage } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { ContactusRequest } from "../models/request/contactUs.request";
import { aboutFailed, aboutSuccess, ABOUT_SUBMIT, contactUsfailed, contactUsSuccess, CONTACT_US_SUBMIT, privacyPolicyfailed, privacyPolicySuccess, PRIVACY_POLICY_SUBMIT, termsCondtionFailed, termsCondtionSuccess, TERMS_CONDITION_SUBMIT } from "./setting.action";

@Injectable({
    providedIn: "root"
})

export class SettingEffect {
    constructor(
        private store: Store<AppState>,
        private action$: Actions,
        private settingService: SettingService,
        private submitModal: EmailSendModalService,
        private localStorage:LocalStorageService
    ) { }

    aboutUs$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ABOUT_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.settingService.aboutus().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return aboutSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(aboutFailed(errResp.error));
                    })
                );
            })

        )
    })


    termsAndCondition$ = createEffect(() => {
        return this.action$.pipe(
            ofType(TERMS_CONDITION_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.settingService.termscondtion().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return termsCondtionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(termsCondtionFailed(errResp.error));
                    })
                );
            })
        )
    })


    privacyPolicy$ = createEffect(() => {
        return this.action$.pipe(
            ofType(PRIVACY_POLICY_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.settingService.privacypolicy().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return privacyPolicySuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(privacyPolicyfailed(errResp.error));
                    })
                );
            })

        )
    })

    contactUs$ = createEffect(() => {
        return this.action$.pipe(
            ofType(CONTACT_US_SUBMIT),
            exhaustMap((id: ContactusRequest) => {
                let req = {
                    first_name: id.first_name,
                    email_id: id.email_id,
                    message: id.message
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.settingService.contactus(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        this.localStorage.checkLanguage()?this.submitModal.open("Merci", "Votre message a été envoyé.","Ok"):this.submitModal.open("Thankyou", "Your message has been sent.","Ok");
                        this.store.dispatch(setSuccessMessage({ message: post.message }))
                        return contactUsSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        this.store.dispatch(setErrorMessage({ message: errResp.message }))
                        return of(contactUsfailed(errResp.error));
                    })
                );
            })
        )
    })
}