import { createFeatureSelector, createSelector } from "@ngrx/store";
import { OtpState } from "./otp.state";

export const OTP_STATE_NAME = 'otp';

const getSharedState = createFeatureSelector<OtpState>(OTP_STATE_NAME);

export const getOtpErrorMessage = createSelector(getSharedState, (state) => {
    return state.otpMessage
})
export const getOtpSuccessMessage = createSelector(getSharedState, (state) => {
    return state.otpSuccess
})
export const getOtpParentComponent = createSelector(getSharedState, (state) => {
    return state.parentComponent
})
export const getOtpEmailID = createSelector(getSharedState, (state) => {
    return state.email_id
})
export const getGrades = createSelector(getSharedState, (state) => {
    return state.grades
})
