import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { ContactusRequest } from "../components/setting/models/request/contactUs.request";
import { aboutUsResponse } from "../components/setting/models/response/about-response";
import { contactUs } from "../components/setting/models/response/contact-us.response";
import { privacyPolicyResponse } from "../components/setting/models/response/privacy-policy-response";
import { TermsConditionResponse } from "../components/setting/models/response/terms-condition-response";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable({
    providedIn : "root"
})
export class SettingService{
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }


 aboutus():Observable<ApiResponse<aboutUsResponse>>{
       return from(this.apiService.get(
           'user/about-us',
       ))
 }   

 termscondtion():Observable<ApiResponse<TermsConditionResponse>>{
    return from(this.apiService.get(
        'user/get-terms-conditions',
    ))
 }

privacypolicy():Observable<ApiResponse<privacyPolicyResponse>>{
    return from(this.apiService.get(
        'user/get-privacy-policy',
    ))
 }

 contactus(contactState : ContactusRequest):Observable<ApiResponse<contactUs>>{
    return from(this.apiService.post(
        'user/contact-us',
        contactState
    ))
 }


}