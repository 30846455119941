import { Action, createReducer, on } from "@ngrx/store";
import { bookmarkVideoListFailed, bookmarkVideoListSuccess } from "./bookmark.action";
import { BookmarkState, initialState } from "./bookmark.state";

const _bookmarkReducer = createReducer(
    initialState,
    on(bookmarkVideoListSuccess, (state, action) => {
        return {
            ...state,
            bookmarkList: action
        };
    }),
    on(bookmarkVideoListFailed, (state, action) => {
        return {
            ...state,
            bookmarkList:null
        };
    })
);

export function BookmarkReducer(state: BookmarkState | undefined, action: Action) {
    return _bookmarkReducer(state, action)
}