import { Action, createReducer, on } from "@ngrx/store";
import { initialState } from "../../subscribe/state/subscribe.state";
import { cancelSubscriptionFailed, cancelSubscriptionSuccess, masterSubscriptionListFailed, masterSubscriptionListSuccess, mySubscriptionListFailed, mySubscriptionListSuccess, purchaseSubscriptionListFailed, purchaseSubscriptionListSuccess } from "./subscribe.action";
import { SubscriptionState } from "./subscribe.state";

const _subscriptionReducer = createReducer(
    initialState,
    on(masterSubscriptionListSuccess, (state, action) => {
        return {
            ...state,
            MasterSubscriptionList: action
        }
    }),
    on(masterSubscriptionListFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(cancelSubscriptionSuccess, (state, action) => {
        return {
            ...state,
            MySubscriptionList: null,
            CancelSubscriptionList: action
        }
    }),
    on(cancelSubscriptionFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(purchaseSubscriptionListSuccess, (state, action) => {
        return {
            ...state,
            PurchaseSubscription: action
        }
    }),
    on(purchaseSubscriptionListFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        };
    }),
    on(mySubscriptionListSuccess, (state, action) => {
        return {
            ...state,
            MySubscriptionList: action.response
        }
    }),
    on(mySubscriptionListFailed, (state, action) => {
        return {
            ...state,
            MySubscriptionList: null,
            errorMessage: action.message
        };
    }),
)

export function SubscriptionReducer(state: SubscriptionState | undefined, action: Action) {
    return _subscriptionReducer(state, action)
}
