import { Action, createReducer, on } from "@ngrx/store";
import { forgetPassSuccess } from "./forget.action";
import { ForgetState, initialState } from "./forget.state";

const _forgetReducer = createReducer(initialState,
    on(forgetPassSuccess, (state, action) => {
        return {
            ...state,
            authToken: action.token,
            isAuthorized: true
        };
    })
);

export function ForgetReducer(state: ForgetState | undefined, action: Action) {
    return _forgetReducer(state, action)
}

