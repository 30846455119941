import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { exhaustMap, map } from "rxjs";
import { SelectSubjectRequest } from "src/app/directives/select-subject/modals/select-subject.request";
import { TopicListService } from "src/app/services/topic-list.service";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { UpdateUnlockSectionRequest } from "../modals/request/update-unlock-section.request";
import { TopicListRequest } from "../modals/topic-list.request";
import { SubjectListSuccess, SUBJECT_LIST_SUBMIT, TopicListSuccess, TOPIC_LIST_SUBMIT, UnlockSectionSuccess, UNLOCK_SECTIONS_SUBMIT, UpadateUnlockSectionSuccess, UpdateSubjectListSuccess, UPDATE_SUBJECT_LIST_SUBMIT, UPDATE_UNLOCK_SECTIONS_SUBMIT } from "./topic-list.action";

@Injectable()
export class TopicListEffect {
  constructor(
    private action$: Actions,
    private store: Store<AppState>,
    private topicListService: TopicListService
  ) { }

  topicListEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(TOPIC_LIST_SUBMIT),
      exhaustMap((id:TopicListRequest) => {
        let Req:TopicListRequest={
          subject_id:id.subject_id
        }
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.topicListService.topicList(Req).pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return TopicListSuccess(post.response);
          })
        );
      })
    );
  });
  updateSubjectListEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(UPDATE_SUBJECT_LIST_SUBMIT),
      exhaustMap((id:SelectSubjectRequest) => {
        let Req:SelectSubjectRequest={
          recent_subject_id:id.recent_subject_id
        }
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.topicListService.updateSubjectList(Req).pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return UpdateSubjectListSuccess(post);
          })
        );
      })
    );
  });
  subjectListEffect$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SUBJECT_LIST_SUBMIT),
      exhaustMap(() => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.topicListService.subjectList().pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return SubjectListSuccess(post.response);
          })
        );
      })
    );
  });
  unlockedSection$ = createEffect(() => {
    return this.action$.pipe(
      ofType(UNLOCK_SECTIONS_SUBMIT),
      exhaustMap(() => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.topicListService.unlockedSection().pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return UnlockSectionSuccess(post.response);
          })
        );
      })
    );
  });
  updateUnlockedSection$ = createEffect(() => {
    return this.action$.pipe(
      ofType(UPDATE_UNLOCK_SECTIONS_SUBMIT),
      exhaustMap((id: UpdateUnlockSectionRequest) => {
        let Req: UpdateUnlockSectionRequest = {
          sub_topic_id: id.sub_topic_id,
          topic_test:id.topic_test,
          pen_and_practise: id.pen_and_practise,
          flashcards: id.flashcards,
          resources: id.resources
        }
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.topicListService.updateUnlockedSection(Req).pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return UpadateUnlockSectionSuccess(post.response);
          })
        );
      })
    );
  });
}
