import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "src/app/models/api.response.model";
import { resetPasswordRequest } from "../models/reset-password.request";
import { resetPasswordResponse } from "../models/reset-password.response";

export const RESET_PASSWORD_SUBMIT = '[reset-password page] reset password submit';
export const RESET_PASSWORD_SUCCESS = '[reset-password page] reset password success';
export const RESET_PASSWORD_FAILED = '[reset-password page] reset password failed';
export const SET_OTP_RESET_PASSWORD = '[reset-password page] set otp';

export const resetPassSubmit = createAction(
    RESET_PASSWORD_SUBMIT,
    props<resetPasswordRequest>()
);

export const resetPassSuccess = createAction(
    RESET_PASSWORD_SUCCESS,
    props<resetPasswordResponse>()
);
export const resetPassFailed = createAction(
    RESET_PASSWORD_FAILED,
    props<ApiResponse<null>>()
);
export const setOtpResetPassword = createAction(
    SET_OTP_RESET_PASSWORD,
    props<{ otp: string }>()
);