import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WelcomeModalComponent } from './welcome-modal.component';

@Injectable({
    providedIn: 'root'
})
export class WelcomeModalService {
    modalReference: any;
    constructor(private modal: NgbModal) { }
    async open() {
        let modelRef = this.modal.open(WelcomeModalComponent, { size: 'lg',  centered: true });
        return modelRef
    }
}