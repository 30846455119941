import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of, switchMap } from "rxjs";
import { DeleteModalService } from "src/app/modals/delete-modal/delete-modal.service";
import { EmailSendModalService } from "src/app/modals/email-sent-modal/email-send.modal.service";
import { SelectCourseModalService } from "src/app/modals/select-course-modal/select-course.modal.service";
import { AuthorizationService } from "src/app/services/authorization.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { userProfileSubmit } from "src/app/shared/header/state/header.action";
import {
  setErrorMessage,
  setLoadingSpinner,
  setSuccessMessage,
} from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { setOtpResetPassword } from "../../reset-password/state/rest-password.action";
import { CourseSelectRequest } from "../model/course-select.request";
import { LanguageRequest } from "../model/language-request";
import { OtpRequest } from "../model/otp.request";
import {
  ResendOtpEmailRequest,
  ResendOtpRequest,
} from "../model/resend-request";
import { VerifyOtpForgetPassword } from "../model/verify-otp-forget-password.model";
import {
  CourseSelectFailed,
  CourseSelectSuccess,
  COURSE_SELECT_SUBMIT,
  dummyAction,
  getGradesStart,
  getGradesSuccess,
  GET_GRADES_START,
  OtpFailed,
  OtpSuccess,
  otpSuccessForgetPassword,
  OTP_SUBMIT,
  OTP_SUBMIT_FORGET_PASSWORD,
  OTP_SUCCESS,
  resendOtpFailed,
  resendOtpSuccess,
  RESEND_OTP_PHONE_SUBMIT,
  RESEND_OTP_SUBMIT,
} from "./otp.action";

@Injectable()
export class OtpEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private authService: AuthorizationService,
    private selectCourseModalService: SelectCourseModalService,
    private localStorageService: LocalStorageService,
    private emailSendModal: EmailSendModalService,
    private deleteModal: DeleteModalService
  ) {}
  submitOtpSignup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OTP_SUBMIT),
      exhaustMap((id: OtpRequest) => {
        let req: OtpRequest = {
          otp: id.otp,
        };
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.authService.otp(req).pipe(
          map((post) => {
            this.localStorageService.setValue("studeoWeb", "abc");

            this.store.dispatch(setSuccessMessage({ message: post.message }));
            return OtpSuccess(post.response);
          }),
          catchError((errResp) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(
              OtpFailed(errResp),
              setErrorMessage({
                message: this.localStorageService.checkLanguage()
                  ? "Le code est incorrect"
                  : "Invalid OTP",
              })
            );
          })
        );
      })
    );
  });
  submitOtpForgetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OTP_SUBMIT_FORGET_PASSWORD),
      exhaustMap((id: VerifyOtpForgetPassword) => {
        let req: VerifyOtpForgetPassword = {
          otp: id.otp,
          email: id.email,
        };
        this.store.dispatch(setLoadingSpinner({ status: false }));
        return this.authService.verifyOtpForForgortPassword(req).pipe(
          map((post) => {
            this.store.dispatch(setOtpResetPassword({ otp: id.otp + "" }));
            this.router.navigate(["/auth/reset-password"]);
            return otpSuccessForgetPassword(post.response);
          }),
          catchError((errResp) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(
              OtpFailed(errResp),
              setErrorMessage({ message: errResp.message })
            );
          })
        );
      })
    );
  });
  resendOtp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RESEND_OTP_PHONE_SUBMIT),
      exhaustMap((id: ResendOtpRequest) => {
        let req: any = {
          phone_number: id.phone_number,
        };
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.authService.resendOtp(req).pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.localStorageService.checkLanguage()
              ? this.emailSendModal.open(
                  "Nous avons envoyé un code de vérification à votre téléphone",
                  req.phone_number,
                  "Entrer le code"
                )
              : this.emailSendModal.open(
                  "We have sent a verification code on your phone",
                  req.phone_number,
                  "Enter OTP"
                );
            return resendOtpSuccess(post);
          }),
          catchError((errResp) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(
              setErrorMessage({
                message: errResp?.error?.message
                  ? errResp?.error?.message
                  : errResp,
              })
            );
            return of(
              resendOtpFailed(
                errResp?.error?.message
                  ? errResp?.error?.message
                  : errResp?.error
              )
            );
          })
        );
      })
    );
  });
  resendOtpEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RESEND_OTP_SUBMIT),
      exhaustMap((id: ResendOtpEmailRequest) => {
        let req: any = {
          email: id.email,
        };
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.authService.resendOtpEmail(req).pipe(
          map((post) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(setSuccessMessage({ message: post.message }));
            return resendOtpSuccess(post);
          }),
          catchError((errResp) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(setErrorMessage({ message: errResp.error }));
            return of(resendOtpFailed(errResp.error));
          })
        );
      })
    );
  });
  courseSelect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(COURSE_SELECT_SUBMIT),
      exhaustMap((req: CourseSelectRequest) => {
        let id: CourseSelectRequest = {
          grade_id: req.grade_id,
          grade_year_id: req.grade_year_id,
          language_id: req.language_id,
        };
        let is_redirect_off = req?.is_redirect_off;
        return this.authService.courseSelect(id).pipe(
          map((post) => {
            // this.router.navigate(['/home/dashboard']);
            if (!is_redirect_off) {
              this.router.navigate(["/home/topics/topic-list"]);
            }

            this.store.dispatch(userProfileSubmit());
            if (localStorage.getItem("IsGradeChange")) {
              setTimeout(() => {
                window.location.reload();
              }, 600);
            }
            return CourseSelectSuccess(post.response);
          }),
          catchError((errResp) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.router.navigate(["/home/dashboard"]);
            return of(CourseSelectFailed(errResp.error));
          })
        );
      })
    );
  });
  getGrades$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_GRADES_START),
      exhaustMap((req: LanguageRequest) => {
        let id: LanguageRequest = {
          language_id: req.language_id,
        };
        return this.authService.getGrades(id).pipe(
          map((post) => {
            this.selectCourseModalService.open(id.language_id);
            return getGradesSuccess(post);
          }),
          catchError((errResp) => {
            this.store.dispatch(setErrorMessage({ message: errResp }));
            return of(dummyAction());
          })
        );
      })
    );
  });
  otpSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OTP_SUCCESS),
      switchMap((req: LanguageRequest) => {
        let id: LanguageRequest = {
          language_id: this.localStorageService.checkLanguage() ? 2 : 1,
        };
        return of(getGradesStart(id));
      })
    );
  });
}
