import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { I18nService } from 'src/app/language/service/i18n.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public i18nService:I18nService) { }
  ngOnInit(): void {
  }
  submit() {
    
    this.activeModal.close(true);
  }
}
