import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { ProfileService } from "src/app/services/profile.service";
import { updateProfile } from "src/app/shared/header/state/header.action";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { EditprofileRequest } from "../models/request/edit-profile.request";
import { editProfileFailed, EDIT_PROFILE_SUBMIT, pinnedReplyFailed, pinnedReplySuccess, PINNED_REPLY_SUBMIT, ProfileEarningFailed, ProfileEarningSuccess, ProfileTestProgressFailed, ProfileTestProgressSuccess, ProfileTopicProgressFailed, ProfileTopicProgressSuccess, PROFILE_EARNING_SUBMIT, PROFILE_TEST_PROGRESS_SUBMIT, PROFILE_TOPIC_PROGRESS_SUBMIT } from "./profile.action";

@Injectable()

export class ProfileEffect {
    constructor(private action$: Actions, private store: Store<AppState>, private profileService: ProfileService) { }

    topicProgressEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(PROFILE_TOPIC_PROGRESS_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.profileService.topicProgress().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return ProfileTopicProgressSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(ProfileTopicProgressFailed(errResp.error));
                    })
                )
            })
        )
    })

    testProgressEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(PROFILE_TEST_PROGRESS_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.profileService.testProgress().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return ProfileTestProgressSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(ProfileTestProgressFailed(errResp.error));
                    })
                )
            })
        )
    })

    earningEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(PROFILE_EARNING_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.profileService.earning().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return ProfileEarningSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(ProfileEarningFailed(errResp.error));
                    })
                )
            })
        )
    })

    editProfileEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(EDIT_PROFILE_SUBMIT),
            exhaustMap((id: EditprofileRequest) => {
                let req: EditprofileRequest = {
                    first_name: id.first_name,
                    last_name: id.last_name,
                    email_id: id.email_id,
                    avatar_image_url: id.avatar_image_url,
                    address: id.address,
                    gender: id.gender,
                    date_of_birth: id.date_of_birth,
                    parentsEmail:id.parentsEmail,
                    phone_number: id.phone_number,
                    full_name: id.first_name + " " + id.last_name
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.profileService.editProfile(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return updateProfile(req)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(editProfileFailed(errResp.error));
                    })
                )
            })
        )
    })

    pinnedReplyEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(PINNED_REPLY_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.profileService.pinnedReply().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return pinnedReplySuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(pinnedReplyFailed(errResp.error));
                    })
                )
            })
        )
    })
}