import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GoToOtpScreen } from 'src/app/components/authorization/sign-up/state/signup.action';
import { AppState } from 'src/app/store/app.state';
@Component({
  selector: 'app-email-send-modal',
  templateUrl: './email-send.modal.html',
  styleUrls: ['./email-send.modal.scss']
})
export class EmailSentModalComponent implements OnInit {
  @Input() title!: string
  @Input() subtitle!: string
  @Input() button_text!:string
  email_send:any
  constructor(private store: Store<AppState>, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  submit() {
    this.store.dispatch(GoToOtpScreen(null))
    this.activeModal.close(true)
  }

}
