import { Action, createReducer, on } from "@ngrx/store";
import { ContentQuestionsFailed, ContentQuestionsSuccess, TopicListContenQuestionFilterFailed, TopicListContenQuestionFilterSuccess, TypeOfQuestionsFailed, TypeOfQuestionsSuccess } from "./content-questions.action";
import { ContentQuestionState, initialState } from "./content-questions.state";

const _contentQuestionsReducer = createReducer(
    initialState,
    on(ContentQuestionsSuccess, (state, action) => {
        return {
            ...state,
            contentQuestions: action
        };
    }),
    on(ContentQuestionsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
    on(TypeOfQuestionsSuccess, (state, action) => {
        return {
            ...state,
            typeOfQuestion: action
        };
    }),
    on(TypeOfQuestionsFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),

    on(TopicListContenQuestionFilterSuccess, (state, action) => {
        return {
            ...state,
            topicListContentQuestionFilter: action
        };
    }),
    on(TopicListContenQuestionFilterFailed, (state, action) => {
        return {
            ...state,
            errorMessage: action.message
        }
    }),
)


export function ContentQuestionsReducer(state: ContentQuestionState | undefined, action: Action) {
    return _contentQuestionsReducer(state, action)
}