import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from './delete-modal.component';

@Injectable({
    providedIn: 'root'
})
export class DeleteModalService {

    constructor(private modal: NgbModal) { }

    open(title: string) {
        let modelRef = this.modal.open(DeleteModalComponent, { size: 'md', centered: true, backdrop: 'static' });
        modelRef.componentInstance.title = title
        return modelRef
    }
}