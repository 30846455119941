import { createAction, props } from "@ngrx/store";
import { ApiResponse } from "src/app/models/api.response.model";
import { EditprofileRequest } from "../models/request/edit-profile.request";
import { PinnedReplyResponse } from "../models/response/pinned-reply.response";
import { profileEarningResponse } from "../models/response/profile-earning-progress.response";
import { TestProgressResponse } from "../models/response/profile-test-progress.response";
import { profileTopicProgressResponse } from "../models/response/profile-topic-progress.response";

export const PROFILE_TOPIC_PROGRESS_SUBMIT = '[profile topic progress page] profile topic progress submit';
export const PROFILE_TOPIC_PROGRESS_SUCCESS = '[profile topic progress page] profile topic progress success';
export const PROFILE_TOPIC_PROGRESS_FAILED = '[profile topic progress page] profile topic progress failed';

export const PROFILE_TEST_PROGRESS_SUBMIT = '[profile test progress page] profile test progress submit';
export const PROFILE_TEST_PROGRESS_SUCCESS = '[profile test progress page] profile test progress success';
export const PROFILE_TEST_PROGRESS_FAILED = '[profile test progress page] profile test progress failed';

export const PROFILE_EARNING_SUBMIT = '[profile earning page] profile earning submit';
export const PROFILE_EARNING_SUCCESS = '[profile earning page] profile earning success';
export const PROFILE_EARNING_FAILED = '[profile earning page] profile earning failed';

export const EDIT_PROFILE_SUBMIT = '[ edit profile  page]  edit profile  submit';
export const EDIT_PROFILE_SUCCESS = '[ edit profile  page]  edit profile  success';
export const EDIT_PROFILE_FAILED = '[ edit profile  page]  edit profile  failed';

export const PINNED_REPLY_SUBMIT = '[ pinned reply  page]  pinned reply  submit';
export const PINNED_REPLY_SUCCESS = '[ pinned reply  page]  pinned reply  success';
export const PINNED_REPLY_FAILED = '[ pinned reply  page]  pinned reply  failed';

export const ProfileTopicProgressSubmit = createAction(
    PROFILE_TOPIC_PROGRESS_SUBMIT
);

export const ProfileTopicProgressSuccess = createAction(
    PROFILE_TOPIC_PROGRESS_SUCCESS,
    props<profileTopicProgressResponse>()
);

export const ProfileTopicProgressFailed = createAction(
    PROFILE_TOPIC_PROGRESS_FAILED,
    props<ApiResponse<null>>()
);

export const ProfileTestProgressSubmit = createAction(
    PROFILE_TEST_PROGRESS_SUBMIT
);

export const ProfileTestProgressSuccess = createAction(
    PROFILE_TEST_PROGRESS_SUCCESS,
    props<TestProgressResponse>()
);

export const ProfileTestProgressFailed = createAction(
    PROFILE_TEST_PROGRESS_FAILED,
    props<ApiResponse<null>>()
);

export const ProfileEarningSubmit = createAction(
    PROFILE_EARNING_SUBMIT
);

export const ProfileEarningSuccess = createAction(
    PROFILE_EARNING_SUCCESS,
    props<profileEarningResponse>()
);

export const ProfileEarningFailed = createAction(
    PROFILE_EARNING_FAILED,
    props<ApiResponse<null>>()
);

export const editProfileSubmit = createAction(
    EDIT_PROFILE_SUBMIT,
    props<EditprofileRequest>()
);

export const editProfileSuccess = createAction(
    EDIT_PROFILE_SUCCESS,
    props<EditprofileRequest>()
);

export const editProfileFailed = createAction(
    EDIT_PROFILE_FAILED,
    props<ApiResponse<null>>()
);

export const pinnedReplySubmit = createAction(
    PINNED_REPLY_SUBMIT,
);

export const pinnedReplySuccess = createAction(
    PINNED_REPLY_SUCCESS,
    props<PinnedReplyResponse>()
);

export const pinnedReplyFailed = createAction(
    PINNED_REPLY_FAILED,
    props<ApiResponse<null>>()
);