import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, from } from "rxjs";
import { DashboardResponse } from "../components/dashboard/home-dashboard/models/home-dash.response";
import { ApiResponse } from "../models/api.response.model";
import { NotificationCountResponse } from "../shared/header/models/notification-count-modal-response";
import { NotificationListResponse } from "../shared/header/models/notification-list-modal-response";
import { UserProfileResponse } from "../shared/header/models/user-profile-modal-response";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";
import { NotificationListRequest } from "../shared/header/models/notification-list.request";

@Injectable({
    providedIn: 'root',
})
export class headerService {
    timeoutInterval: any;
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }
    userProfile(): Observable<ApiResponse<UserProfileResponse>> {
        return from(this.apiService.get(
            'user/my-profile',

        ));
    };
    courseSelect(HomedashReq: DashboardResponse): Observable<ApiResponse<DashboardResponse>> {

        return from(this.apiService.post(
            '',
            HomedashReq
        ));
    }

    notificationList(notificationRequest : NotificationListRequest): Observable<ApiResponse<NotificationListResponse>>{
        return from(this.apiService.post(
            'user/notifications-list',
            notificationRequest 
        ))
    }

    notificationCount(): Observable<ApiResponse<NotificationCountResponse>>{
        return from(this.apiService.get(
            'user/notifications-unread-count'
        ))
    }


}