import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { PracticeService } from "src/app/services/practice.service";
import { setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { ContentQuestionsRequest } from "../models/request/content-questions.request";
import { ContentQuestionsFailed, ContentQuestionsSuccess, CONTENT_QUESTIONS_SUBMIT, TopicListContenQuestionFilterFailed, TopicListContenQuestionFilterSuccess, TOPIC_LIST_CONTENT_QUESTION_FILTER_SUBMIT, TypeOfQuestionsFailed, TypeOfQuestionsSuccess, TYPE_OF_QUESTION_SUBMIT } from "./content-questions.action";


@Injectable()

export class ContentQuestionsEffect{
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private contentQuestionService: PracticeService
    ){}

    contentQuestionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CONTENT_QUESTIONS_SUBMIT),
            exhaustMap((id:ContentQuestionsRequest) => {
                let req: ContentQuestionsRequest = {
                    limit: id.limit,
                    offset: id.offset,
                    topic_ids : id.topic_ids,
                    tag_ids : id.tag_ids
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.contentQuestionService.contentQuestions(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return ContentQuestionsSuccess(post.response)
                    }),
                    catchError((errResp)=>{
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of (ContentQuestionsFailed(errResp.error));
                    })
                );
            })
        )
    })

    typeOfQuestionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TYPE_OF_QUESTION_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.contentQuestionService.typeOfQuestion().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TypeOfQuestionsSuccess(post.response)
                    }),
                    catchError((errResp)=>{
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of (TypeOfQuestionsFailed(errResp.error));
                    })
                );
            })
        )
    } )
    topicListFilterEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TOPIC_LIST_CONTENT_QUESTION_FILTER_SUBMIT),
            exhaustMap(() => {
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.contentQuestionService.topicListFilterList().pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return TopicListContenQuestionFilterSuccess(post.response)
                    }),
                    catchError((errResp)=>{
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of (TopicListContenQuestionFilterFailed(errResp.error));
                    })
                );
            })
        )
    } )
}


