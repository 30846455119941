import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { RouterNavigatedAction, ROUTER_NAVIGATION } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, filter, map, of } from "rxjs";
import { CommunityService } from "src/app/services/community.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { setErrorMessage, setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { CommentOnQuestionRequest } from "../models/request/comment-on-question.request";
import { QuestionLikeUnlikeRequest } from "../models/request/question-like-unlike.request";
import { questionListRequest } from "../models/request/question-list.request";
import { QuestionRepliesRequest } from "../models/request/question-replies.request";
import { SubmitQuestionRequest } from "../models/request/submit-question.request";
import { UpdateQuestionViewsRequset } from "../models/request/update-question-views.request";
import {
    CommentOnQuestionFailed, CommentOnQuestionSuccess, COMMENT_ON_QUESTION_SUBMIT, QuestionLikeUnlikeFailed, QuestionLikeUnlikeSuccess, QuestionListFailed, QuestionListSuccess, QuestionRepliesFailed, QuestionRepliesSuccess, QUESTION_LIKE_UNLIKE_SUBMIT, QUESTION_LIST_SUBMIT, QUESTION_REPLIES_SUBMIT, SubmitQuestionFailed,
    SubmitQuestionSuccess, SUBMIT_QUESTION_SUBMIT, UpdateQuestionViewsFailed, UpdateQuestionViewsSuccess, UPDATE_QUESTION_VIEW_SUBMIT
} from "./community.action";

@Injectable()

export class QuestionListEffect {
    constructor(
        private action$: Actions, private store: Store<AppState>,
        private communityService: CommunityService,private localStorage:LocalStorageService
    ) { }
    questionListEffect$ = createEffect(() => {
        return this.action$.pipe(
            ofType(QUESTION_LIST_SUBMIT),
            exhaustMap((id: questionListRequest) => {
                let req: questionListRequest = {
                    limit: id.limit,
                    offset: id.offset,
                    search: id.search,
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.communityService.question(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return QuestionListSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(QuestionListFailed(errResp.error));
                    })
                );
            })
        );
    })

    commentOnQuestion$ = createEffect(() => {
        return this.action$.pipe(
            ofType(COMMENT_ON_QUESTION_SUBMIT),
            exhaustMap((id: CommentOnQuestionRequest) => {
                let req: CommentOnQuestionRequest = {
                    user_question_id: id.user_question_id,
                    comment: id.comment
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.communityService.commentOnQuestion(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return CommentOnQuestionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return this.localStorage.checkLanguage()? of(CommentOnQuestionFailed(errResp.error), setErrorMessage({ message: "Réponse obligatoire" })):of(CommentOnQuestionFailed(errResp.error), setErrorMessage({ message: "Reply can't be Empty" }));
                    })
                );
            })
        );
    })

    questionLikeUnlike$ = createEffect(() => {
        return this.action$.pipe(
            ofType(QUESTION_LIKE_UNLIKE_SUBMIT),
            exhaustMap((id: QuestionLikeUnlikeRequest) => {
                let req: QuestionLikeUnlikeRequest = {
                    user_question_id: id.user_question_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.communityService.likeUnlikequestion(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return QuestionLikeUnlikeSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(QuestionLikeUnlikeFailed(errResp.error));
                    })
                );
            })
        );
    })

    questionReplies$ = createEffect(() => {
        return this.action$.pipe(
            ofType(QUESTION_REPLIES_SUBMIT),
            exhaustMap((id: QuestionRepliesRequest) => {
                let req: QuestionRepliesRequest = {
                    limit: id.limit,
                    offset: id.offset,
                    user_question_id: id.user_question_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.communityService.questionReplies(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return QuestionRepliesSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(QuestionRepliesFailed(errResp.error));
                    })
                );
            })
        );
    })

    submitQuestion$ = createEffect(() => {
        return this.action$.pipe(
            ofType(SUBMIT_QUESTION_SUBMIT),
            exhaustMap((id: SubmitQuestionRequest) => {
                let req: SubmitQuestionRequest = {
                    question: id.question
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.communityService.submitQuestion(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return SubmitQuestionSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return this.localStorage.checkLanguage()? of(SubmitQuestionFailed(errResp.error), setErrorMessage({ message: "Une question ne peut être vide" })):of(SubmitQuestionFailed(errResp.error), setErrorMessage({ message: "Question can't be Empty" }));
                    })
                );
            })
        );
    })

    updateQuestionView$ = createEffect(() => {
        return this.action$.pipe(
            ofType(UPDATE_QUESTION_VIEW_SUBMIT),
            // filter((r: RouterNavigatedAction) => {
            //     return r.payload.routerState.url.startsWith('/home/community/community-details')
            // }),
            // map((r: any) => {
            //     return r.payload.routerState['params']['id']
            // }),
            exhaustMap((id: any) => {
                let req: UpdateQuestionViewsRequset = {
                    // user_question_id:parseInt(id)
                    user_question_id: parseInt(id.user_question_id)
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.communityService.updateQuestionView(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return UpdateQuestionViewsSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(UpdateQuestionViewsFailed(errResp.error));
                    })
                );
            })
        );
    })
}