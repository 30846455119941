import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { SegmentService } from "ngx-segment-analytics";
import { ToastrService } from "ngx-toastr";
import { autoLogin } from "./components/authorization/login/state/login.action";
import { LocalStorageService } from "./services/local.storage.service";
import { UtilService } from "./services/util.service";
import { getUserProfile } from "./shared/header/state/header.selector";
import {
  setErrorMessage,
  setSuccessMessage,
} from "./shared/store/shared.actions";
import {
  getErrorMessage,
  getSuccessMessage,
} from "./shared/store/shared.selector";
import { AppState } from "./store/app.state";
import { extractQueryParameters } from "./utils/params.util";
import { SolverApiService } from "./services/solver-api.service";
import { CookieService } from "ngx-cookie-service";
declare const AppleID: any;
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @Input() clientId: string = "com.studeo";
  @Input() scope!: string;
  @Input() redirectURI: string = "https://web.studeoapp.co.uk";
  @Input() state: string = "true";
  @Input() color = Color.black;
  @Input() type = Type.Apple;
  constructor(
    private store: Store<AppState>,
    private localStorage: LocalStorageService,
    private segment: SegmentService,
    private toastr: ToastrService,
    private util: UtilService,
    private router: ActivatedRoute,
    private solverApiService: SolverApiService,
    private cookie: CookieService
  ) {}

  async ngOnInit() {
    let a: any = this.localStorage.getValue("studeoToken");
    if (!a) {
      a = this.cookie.get("studeoToken");
      if (a !== "") {
        const doubleEncryptedToken = await this.util.encrypt(a);
        this.localStorage.setValue("studeoToken", doubleEncryptedToken);
        this.localStorage.setValue("studeoWeb", doubleEncryptedToken);
      } else a = null;
    }

    if (!localStorage.getItem("uid")) {
      let device_uid = new Date().getTime() + new Date().getTime().toString(36);
      localStorage.setItem("uid", device_uid);
    }

    let token = await this.util.decrypt(a);
    // let token = this.localStorage.getValue("studeoToken")
    if (token) {
      this.store.dispatch(autoLogin({ token }));
    }
    this.store.select(getErrorMessage).subscribe((res) => {
      if (res != "") {
        this.toastr.error(res);
        this.store.dispatch(setErrorMessage({ message: "" }));
      }
    });
    this.store.select(getSuccessMessage).subscribe((res) => {
      if (res != "") {
        this.toastr.success(res);
        this.store.dispatch(setSuccessMessage({ message: "" }));
      }
    });
    this.store.select(getUserProfile).subscribe((res) => {
      if (res) {
        this.segment.identify("" + res.user_id, { ...res });
      }
    });
    this.getMachineId();
    this.handleRefererCode();
  }

  handleRefererCode() {
    try {
      const params = extractQueryParameters(window.location.href, [
        "referal_code",
      ]);
      if (params && params["referal_code"]) {
        const deviceId = localStorage.getItem("uid");
        const referal_code = params["referal_code"];
        this.solverApiService.get(
          "aicredit/handle-ia-credit-share-url?tracker=" +
            referal_code +
            "&deviceId=" +
            deviceId
        );
      }
    } catch (e) {
      console.debug("Can't handle referer code", e);
    }
  }

  singninIn() {
    AppleID.auth.signIn();
  }

  ngAfterViewChecked(): void {
    AppleID.auth.init({
      clientId: this.clientId,
      scope: this.scope,
      redirectURI: this.redirectURI,
      state: this.state,
    });
  }
  async getMachineId() {
    let machineId = localStorage.getItem("MachineId");
    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem("MachineId", machineId);
    }
    return machineId;
  }
}

export enum Color {
  black = "black",
  light = "light",
}
export enum Type {
  SignIn = "sign in",
  SignUp = "sign up",
  Apple = "apple",
  Continue = "continue",
}
