import { OtpParentComponent } from "src/app/enums/otp-parent-component.enum";
import { Grade } from "../model/grade.model";
export interface OtpState {
    errorMessage: string;
    otpSuccess:any;
    otpMessage:any;
    isAuthorized: boolean;
    authToken: string;
    parentComponent: OtpParentComponent;
    email_id: string;
    grades: Grade[];
}

export const initialState: OtpState = {
    isAuthorized: false,
    otpMessage:'',
    otpSuccess:'',
    errorMessage: '',
    authToken: '',
    parentComponent: OtpParentComponent.Null,
    email_id: '',
    grades: []

};
