import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SignupState } from "./signup.state";

export const SIGNUP_STATE_NAME = 'signup';

const getSharedState = createFeatureSelector<SignupState>(SIGNUP_STATE_NAME);
const getContentQuestionsState = createFeatureSelector<SignupState>(SIGNUP_STATE_NAME);

export const getSignupErrorMessage = createSelector(getSharedState, (state) => {
    return state.errorMessage
})
export const getCountryList = createSelector(getContentQuestionsState, (state) => {
    return state.country
})
export const GetPhoneNumber = createSelector(getSharedState, (state) => {
    return state.phone
})