import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ContentQuestionState } from "./content-questions.state";

export const CONTENT_QUESTIONS_NAME = 'content question'

const getContentQuestionsState = createFeatureSelector<ContentQuestionState>(CONTENT_QUESTIONS_NAME);

export const getContentQuestions = createSelector(getContentQuestionsState, (state) => {
    return state.contentQuestions
})

export const getTypeOfQuestions = createSelector(getContentQuestionsState, (state) => {
    return state.typeOfQuestion
})
export const getTopicListFilter = createSelector(getContentQuestionsState, (state) => {
    return state.topicListContentQuestionFilter
})