import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SharedState } from "./shared.state";
export const SHARED_STATE_NAME = "shared";

const getSharedState = createFeatureSelector<SharedState>(SHARED_STATE_NAME);

export const getLoading = createSelector(getSharedState, (state) => {
  return state.showLoading;
});

export const getErrorMessage = createSelector(getSharedState, (state) => {
  return state.errorMessage;
});
export const getSuccessMessage = createSelector(getSharedState, (state) => {
  return state.successMessage;
});
export const isLogin = createSelector(getSharedState, (state) => {
  return state.isAuthorized;
});
export const getVideoHeaders = createSelector(getSharedState, (state) => {
  return state.videoHeaders;
});

export const getNavigationLocation = createSelector(getSharedState, (state) => {
  return state.navigationLocation;
});
