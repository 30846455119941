import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of, tap } from "rxjs";
import { OtpParentComponent } from "src/app/enums/otp-parent-component.enum";
import { AuthorizationService } from "src/app/services/authorization.service";
import { LocalStorageService } from "src/app/services/local.storage.service";
import { setErrorMessage, setLoadingSpinner } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { SetOtpEmailID, SetOtpParentComponent } from "../../otp/state/otp.action";
import { ForgetRequest } from "../models/forget-request.model";
import { forgetPassFailed, forgetPassSuccess, FORGET_SUBMIT, FORGET_SUCCESS } from "./forget.action";


@Injectable()
export class ForgetEffect {
    constructor(private actions$: Actions, private store: Store<AppState>, private authService: AuthorizationService,
        private router: Router,private localStorage:LocalStorageService) { }
    forgetEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FORGET_SUBMIT),
            exhaustMap((id: ForgetRequest) => {
                let req: ForgetRequest = {
                    email_id: id.email_id
                }
                this.store.dispatch(setLoadingSpinner({ status: true }))
                return this.authService.forgot(req).pipe(
                    map((post) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        this.store.dispatch(SetOtpParentComponent({ component: OtpParentComponent.ForgotPasswordComponent }))
                        this.store.dispatch(SetOtpEmailID({ phone_number: id.email_id }))
                        return forgetPassSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        return of(forgetPassFailed(errResp.error), setErrorMessage({ message: this.localStorage.checkLanguage() ? 'Identifiant email invalide' : errResp.message }));
                    })
                );
            })
        );
    });
    @Effect({ dispatch: false })
    SignupSuccess$ = this.actions$.pipe(
        ofType(FORGET_SUCCESS),
        tap(() => {
            this.localStorage.showMessage("Mot de passe envoyé","OTP send successfully")
            this.router.navigate(['/auth/otp'])
        })
    );
}


