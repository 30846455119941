<div class="">
    <div class="modal-content">
        <div class="modal-header">
            <i class="fa fa-times btn-close" data-bs-dismiss="modal" aria-label="Close" aria-hidden="true" (click)="submit()"></i>
        </div>
        <div class="modal-body text-center">
            <div class="mb-3 text-center">
                <img src="assets/gif/submit-email.gif" height="100px" width="100px" alt="">
            </div>
            <p class="text-muted">{{title}}</p>
            <h5 class="mt-3 mb-3">{{subtitle}}</h5>
            <button class="btn primary-btn mb-3" (click)="submit()">{{button_text}}</button>
            <!-- <button class="btn primary-btn mb-3" (click)="submit()">{{'enter_OTP' | i18n}}</button> -->
        </div>
    </div>
</div>