import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { from, Observable } from "rxjs";
import { CommentOnQuestionRequest } from "../components/community/models/request/comment-on-question.request";
import { QuestionLikeUnlikeRequest } from "../components/community/models/request/question-like-unlike.request";
import { questionListRequest } from "../components/community/models/request/question-list.request";
import { QuestionRepliesRequest } from "../components/community/models/request/question-replies.request";
import { SubmitQuestionRequest } from "../components/community/models/request/submit-question.request";
import { UpdateQuestionViewsRequset } from "../components/community/models/request/update-question-views.request";
import { CommentOnQuestionResponse } from "../components/community/models/response/comment-on-question.response";
import { QuestionLikeUnlikeResponse } from "../components/community/models/response/question-like-unlike.response";
import { questionListResponse } from "../components/community/models/response/question-list.response";
import { QuestionRepliesResponse } from "../components/community/models/response/question-replies.response";
import { SubmitQuestionResponse } from "../components/community/models/response/submit-question.response";
import { UpdateQuestionViewsResponse } from "../components/community/models/response/update-question-views.response";
import { ApiResponse } from "../models/api.response.model";
import { AppState } from "../store/app.state";
import { ApiService } from "./api";

@Injectable({
    providedIn: 'root',
})

export class CommunityService {
    timeoutInterval: any;
    constructor(private http: HttpClient, private store: Store<AppState>, private apiService: ApiService) { }


    question(QuestionListState: questionListRequest): Observable<ApiResponse<questionListResponse>> {
        return from(this.apiService.post(
            `user/questions`,
            QuestionListState
        ));
    }
    commentOnQuestion(QuestionListState: CommentOnQuestionRequest): Observable<ApiResponse<CommentOnQuestionResponse>> {
        return from(this.apiService.post(
            `user/comment-on-question`,
            QuestionListState
        ));
    }
    likeUnlikequestion(QuestionListState: QuestionLikeUnlikeRequest): Observable<ApiResponse<QuestionLikeUnlikeResponse>> {
        return from(this.apiService.post(
            `user/like-unlike-question`,
            QuestionListState
        ));
    }
    questionReplies(QuestionListState: QuestionRepliesRequest): Observable<ApiResponse<QuestionRepliesResponse>> {
        return from(this.apiService.post(
            `user/question-replies`,
            QuestionListState
        ));
    }
    submitQuestion(QuestionListState: SubmitQuestionRequest): Observable<ApiResponse<SubmitQuestionResponse>> {
        return from(this.apiService.post(
            `user/submit-user-question`,
            QuestionListState
        ));
    }
    updateQuestionView(QuestionListState: UpdateQuestionViewsRequset): Observable<ApiResponse<UpdateQuestionViewsResponse>> {
        return from(this.apiService.post(
            `user/update-question-views`,
            QuestionListState
        ));
    }
}