import { createAction, props } from "@ngrx/store";
import { ApiGatewayResponse } from "src/app/models/api.gateway.response.model";
export const SET_LOADING_ACTION = "[shared state] set loading spinner";
export const SET_ERROR_MESSAGE = "[shared state] set error message";
export const SET_SUCCESS_MESSAGE = "[shared state] set success message";
export const FETCH_VIDEO_HEADER_START =
  "[shared state] fetch video header start";
export const FETCH_VIDEO_HEADER_SUCCESS =
  "[shared state] fetch video header success";
export const SET_NAVIGATION_LOCATION = "[shared state] set navigation location";

export const setLoadingSpinner = createAction(
  SET_LOADING_ACTION,
  props<{ status: boolean }>()
);

export const setNavigationLocation = createAction(
  SET_NAVIGATION_LOCATION,
  props<{ navigationLocation: string }>()
);

export const setSuccessMessage = createAction(
  SET_SUCCESS_MESSAGE,
  props<{ message: string }>()
);

export const setErrorMessage = createAction(
  SET_ERROR_MESSAGE,
  props<{ message: string }>()
);
export const fetchVideoHeader = createAction(
  FETCH_VIDEO_HEADER_START,
  props<{ playback_url: string }>()
);

export const fetchVideoHeaderSuccess = createAction(
  FETCH_VIDEO_HEADER_SUCCESS,
  props<ApiGatewayResponse>()
);
