import { createAction, props } from "@ngrx/store"
import { ApiResponse } from "src/app/models/api.response.model"
import { ContactusRequest } from "../models/request/contactUs.request"
import { aboutUsResponse } from "../models/response/about-response"
import { contactUs } from "../models/response/contact-us.response"
import { privacyPolicyResponse } from "../models/response/privacy-policy-response"
import { TermsConditionResponse } from "../models/response/terms-condition-response"

export const ABOUT_SUBMIT = '[about page] about submit'
export const ABOUT_SUCCESS = '[about page] about success'
export const ABOUT_FAILED = '[about page] about failed'

export const TERMS_CONDITION_SUBMIT = '[terms condition page] terms condition submit'
export const TERMS_CONDITION_SUCCESS = '[terms condition page] terms condition success'
export const TERMS_CONDITION_FAILED = '[terms condition page] terms condition  failed'

export const PRIVACY_POLICY_SUBMIT = '[privacy policy page] privacy policy submit'
export const PRIVACY_POLICY_SUCCESS = '[privacy policy page] privacy policy success'
export const PRIVACY_POLICY_FAILED = '[privacy policy page] privacy policy failed'

export const CONTACT_US_SUBMIT = '[contact us page] contact us submit'
export const CONTACT_US_SUCCESS = '[contact us page] contact us success'
export const CONTACT_US_FAILED = '[contact page] contact us failed'


export const aboutSubmit = createAction(
    ABOUT_SUBMIT
)

export const aboutSuccess = createAction(
    ABOUT_SUCCESS,
    props<aboutUsResponse>()
);

export const aboutFailed = createAction(
    ABOUT_FAILED,
    props<ApiResponse<null>>()
)

export const termsCondtionSubmit = createAction(
    TERMS_CONDITION_SUBMIT
)

export const termsCondtionSuccess = createAction(
    TERMS_CONDITION_SUCCESS,
    props<TermsConditionResponse>()
)

export const termsCondtionFailed = createAction(
    TERMS_CONDITION_FAILED,
    props<ApiResponse<null>>()
)

export const privacyPolicySubmit = createAction(
    PRIVACY_POLICY_SUBMIT
)

export const privacyPolicySuccess = createAction(
    PRIVACY_POLICY_SUCCESS,
    props<privacyPolicyResponse>()
)

export const privacyPolicyfailed = createAction(
    PRIVACY_POLICY_FAILED,
    props<ApiResponse<null>>()
)

export const contactUsSubmit = createAction(
    CONTACT_US_SUBMIT,
    props<ContactusRequest>()
)

export const contactUsSuccess = createAction(
    CONTACT_US_SUCCESS,
    props<contactUs>()
)

export const contactUsfailed = createAction(
    CONTACT_US_FAILED,
    props<ApiResponse<null>>()
)