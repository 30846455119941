import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, of } from "rxjs";
import { AuthorizationService } from "src/app/services/authorization.service";
import { setErrorMessage, setLoadingSpinner, setSuccessMessage } from "src/app/shared/store/shared.actions";
import { AppState } from "src/app/store/app.state";
import { changePasswordRequest } from "../models/change-password.request";
import { changePassFailed, changePassSuccess, CHANGE_PASSWORD_SUBMIT } from "./change-pass.action";

@Injectable()
export class ChangePasswordEffet {
    constructor(private actions$: Actions, private store: Store<AppState>, private authService: AuthorizationService) { }
    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CHANGE_PASSWORD_SUBMIT),
            exhaustMap((id: changePasswordRequest) => {
                let req: changePasswordRequest = {
                    changeBy: id.changeBy,
                    user_id : id.user_id,
                    new_password: id.new_password,
                    old_password: id.old_password,
                }

                return this.authService.changePassword(req).pipe(
                    map((post) => {
                        this.store.dispatch(setSuccessMessage({ message: post.message }))
                        return changePassSuccess(post.response)
                    }),
                    catchError((errResp) => {
                        this.store.dispatch(setLoadingSpinner({ status: false }))
                        this.store.dispatch(setErrorMessage({ message: errResp.message }))
                        return of(changePassFailed(errResp.error));
                    })
                );
            })
        );
    });

}
