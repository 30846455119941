export interface ChangePasswordState {

    errorMessage: string;
    isAuthorized: boolean;
    authToken: string
}
export const initialState: ChangePasswordState = {
    
    isAuthorized: false,
    errorMessage: '',
    authToken: ''
};