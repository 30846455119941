import { createAction, props } from "@ngrx/store"
import { ApiResponse } from "src/app/models/api.response.model"
import { cancelSubscriptionListRequest } from "../models/request/cancel-subscription-list.request"
import { purchaseSubscriptionRequest } from "../models/request/purchase-subscription.request"
import { cancelSubscriptionResponse } from "../models/response/cancel-subscription-list.response"
import { masterSubscriptionListResponse } from "../models/response/master-subscription-list.response"
import { mySubscriptionListResponse } from "../models/response/my-subscription-list.response"
import { purchaseSubscriptionResponse } from "../models/response/purchase-subscription.response"
import { MasterSubscriptionListRequest } from "../models/request/master-subscription-list.request"

export const MASTER_SUBSCRIPTION_LIST_SUBMIT = '[ master subscription list page] master subscription list submit '
export const MASTER_SUBSCRIPTION_LIST_SUCCESS = '[ master subscription list page] master subscription list success '
export const MASTER_SUBSCRIPTION_LIST_FAILED = '[ master subscription list page] master subscription list failed  '

export const CANCEL_SUBSCRIPTION_SUBMIT = '[ cancel subscription page]  cancel subscription submit '
export const CANCEL_SUBSCRIPTION_SUCCESS = '[cancel  subscription page] cancel  subscription success '
export const CANCEL_SUBSCRIPTION_FAILED = '[ cancel subscription page]  cancel subscription failed  '

export const MY_SUBSCRIPTION_LIST_SUBMIT = '[ my subscription list page]  my subscription list submit '
export const MY_SUBSCRIPTION_LIST_SUCCESS = '[ my  subscription list page] my  subscription list success '
export const MY_SUBSCRIPTION_LIST_FAILED = '[ my subscription list page]  my subscription list failed  '

export const PURCHASE_SUBSCRIPTION_LIST_SUBMIT = '[ prchease subscription page]  purchese subscription submit '
export const PURCHASE_SUBSCRIPTION_LIST_SUCCESS = '[prchease  subscription page] purchese  subscription success '
export const PURCHASE_SUBSCRIPTION_LIST_FAILED = '[ prchease subscription page]  purchese subscription failed  '

export const masterSubscriptionListSubmit = createAction(
    MASTER_SUBSCRIPTION_LIST_SUBMIT,
    props<MasterSubscriptionListRequest>()
)

export const masterSubscriptionListSuccess = createAction(
    MASTER_SUBSCRIPTION_LIST_SUCCESS,
    props<masterSubscriptionListResponse>()
)
export const masterSubscriptionListFailed = createAction(
    MASTER_SUBSCRIPTION_LIST_FAILED,
    props<ApiResponse<null>>()
)
export const cancelSubscriptionSubmit = createAction(
    CANCEL_SUBSCRIPTION_SUBMIT,
    props<cancelSubscriptionListRequest>()
)

export const cancelSubscriptionSuccess = createAction(
    CANCEL_SUBSCRIPTION_SUCCESS,
    props<cancelSubscriptionResponse>()
)
export const cancelSubscriptionFailed = createAction(
    CANCEL_SUBSCRIPTION_FAILED,
    props<ApiResponse<null>>()
)
export const mySubscriptionListSubmit = createAction(
    MY_SUBSCRIPTION_LIST_SUBMIT,
)

export const mySubscriptionListSuccess = createAction(
    MY_SUBSCRIPTION_LIST_SUCCESS,
    props<ApiResponse<mySubscriptionListResponse>>()
)
export const mySubscriptionListFailed = createAction(
    MY_SUBSCRIPTION_LIST_FAILED,
    props<ApiResponse<null>>()
)
export const purchaseSubscriptionListSubmit = createAction(
    PURCHASE_SUBSCRIPTION_LIST_SUBMIT,
    props<purchaseSubscriptionRequest>()
)

export const purchaseSubscriptionListSuccess = createAction(
    PURCHASE_SUBSCRIPTION_LIST_SUCCESS,
    props<purchaseSubscriptionResponse>()
)
export const purchaseSubscriptionListFailed = createAction(
    PURCHASE_SUBSCRIPTION_LIST_FAILED,
    props<ApiResponse<null>>()
)
