export interface ForgetState {

    errorMessage: string;
    isAuthorized: boolean;
    authToken: string
}
export const initialState: ForgetState = {
    isAuthorized: false,
    errorMessage: '',
    authToken: ''
};